import React, { useCallback, useMemo } from 'react';
import { useModal } from 'hooks';
import { Masonry, List } from 'masonic';
import { Alert } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import CardEquipoN from './card-equipo-n';
import CardEquipoSAP from './card-equipo-sap';
import CardEquipoCITH from './card-equipo-cith';
import CardEquipoAlfa from './card-equipo-alfa';
import DialogComponent from 'components/DialogComponent';
import { ContentInfo } from 'components/reusable';

function Equipos() {
  const [
    isOpenVerMas,
    onOpenVerMas,
    onCloseVerMas,
    equipoSelectedVerMas,
  ] = useModal();
  const { dataEquipos: data } = useSelector((state) => state.medycon);
  const equiposArray = useMemo(
    () =>
      data && data !== null ? Object.keys(data).map((key) => data[key]) : [],
    [data],
  );

  const { dataEquiposNCITH, dataEquiposSAP } = equiposArray.reduce(
    function (accumulated, current, index) {
      const { equipo } = current || {};
      const { equipo: tipoEquipo } = equipo || {};
      return tipoEquipo === 'SAP01'
        ? {
            ...accumulated,
            dataEquiposSAP: accumulated.dataEquiposSAP.concat([current]),
          }
        : {
            ...accumulated,
            dataEquiposNCITH: accumulated.dataEquiposNCITH.concat([current]),
          };
    },
    { dataEquiposN: [], dataEquiposSAP: [], dataEquiposNCITH: [] },
  );

  const MasonryComponentEquiposNCITH = useCallback(
    () =>
      dataEquiposNCITH.length > 0 && (
        <Masonry
          items={dataEquiposNCITH}
          render={({ data }) => {
            const { equipo } = data || {};
            const { equipo: tipoEquipo } = equipo || {};
            return tipoEquipo === 'CITH' ? (
              <CardEquipoCITH
                equipoBundle={data}
                onVerMas={() => onOpenVerMas(data)}
              />
            ) : tipoEquipo === 'Alfa01' ? (
              <CardEquipoAlfa
                equipoBundle={data}
                onVerMas={() => onOpenVerMas(data)}
              />
            ) : (
              <CardEquipoN
                equipoBundle={data}
                onVerMas={() => onOpenVerMas(data)}
              />
            );
          }}
          columnWidth={250}
          columnGutter={8}
          style={{
            outlineStyle: 'none',
            height: '100%',
            maxHeight: '100%',
            paddingBottom: '60px',
          }}
        />
      ),
    [dataEquiposNCITH, onOpenVerMas],
  );

  const ListComponentEquiposSap = useCallback(
    () =>
      dataEquiposSAP.length > 0 && (
        <List
          items={dataEquiposSAP}
          rowGutter={32}
          render={({ data }) => (
            <CardEquipoSAP
              equipoBundle={data}
              onVerMas={() => onOpenVerMas(data)}
            />
          )}
          style={{
            outlineStyle: 'none',
            height: '100%',
            maxHeight: '100%',
            paddingBottom: '60px',
          }}
        />
      ),
    [dataEquiposSAP, onOpenVerMas],
  );

  return equiposArray.length > 0 ? (
    <>
      <DialogComponent
        open={isOpenVerMas}
        onClose={onCloseVerMas}
        titulo={'Información del equipo'}
      >
        <ContenidoVerMas equipo={equipoSelectedVerMas} />
      </DialogComponent>
      <MasonryComponentEquiposNCITH />
      <ListComponentEquiposSap />
    </>
  ) : (
    <Alert severity="info">No se encontraron datos para este equipo.</Alert>
  );
}
export default Equipos;

function ContenidoVerMas({ equipo }) {
  const { equipo: infoEquipo, datos = [] } = equipo || {};
  const { ver, NSerie, equipo: equipoTipo } = infoEquipo || {};
  let cardContentInfoItems = [
    ` Tipo de equipo: ${equipoTipo}`,
    ` Nro. serie: ${NSerie}`,
    `Versión: ${ver}`,
  ];
  if (
    equipoTipo !== 'CITH' &&
    equipoTipo !== 'SAP01' &&
    equipoTipo !== 'Alfa01' &&
    datos.length > 0
  ) {
    const cadena = datos[0];
    const {
      ModTrab = '',
      MaxAbs = 0,
      MinAbs = 0,
      MaxR1 = 0,
      MaxR2 = 0,
      MinR1 = 0,
      MinR2 = 0,
    } = cadena || {};
    const alturaTanque =
      equipoTipo === 'NSUM'
        ? Number(MaxAbs) - Number(MinAbs)
        : equipoTipo === 'NUS' || equipoTipo === 'N-RAD'
        ? Number(MinAbs) - Number(MaxAbs)
        : 0;

    cardContentInfoItems = cardContentInfoItems.concat([
      `Modo de trabajo: ${ModTrab}`,
      `Altura: ${alturaTanque + 'cm'}`,
      `Mínimo absoluto: ${Number(MinAbs) + 'cm'}`,
      `Máximo absoluto: ${Number(MaxAbs) + 'cm'}`,
      `Mínimo relé 1: ${Number(MinR1) + 'cm'}`,
      `Máximo relé 1: ${Number(MaxR1) + 'cm'}`,
      `Mínimo relé 2: ${Number(MinR2) + 'cm'}`,
      `Máximo relé 2: ${Number(MaxR2) + 'cm'}`,
    ]);
  }
  return <ContentInfo contentInfoItems={cardContentInfoItems} />;
}
