import React, { Fragment, useState, useEffect } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';
import { CircularProgress } from '@material-ui/core';
// import CustomLoader from './CustomLoader';
import { markerConoStyles } from 'styles/Styles';
import moment from 'moment';
import { compose, withProps } from 'recompose';

const {
  MarkerWithLabel,
} = require('react-google-maps/lib/components/addons/MarkerWithLabel');

const MarkerEvento = ({ onClick, position, fecha, isInicio, isSelected }) => {
  const classes = markerConoStyles();
  const borderColor = isInicio ? '#4caf50' : '#f44336'; // rojo->verde
  return (
    <MarkerWithLabel
      opacity={0}
      position={position}
      labelAnchor={new window.google.maps.Point(60, 100)}
      labelStyle={{ width: '120px', height: '120px' }}
      onClick={onClick}
    >
      <div className={classes.markerContainer}>
        <div
          className={classes.markerCono}
          style={{ borderTopColor: borderColor }}
        />
        <div className={classes.markerTopContainer}>
          <span className={classes.markerTopText}>{fecha}</span>
        </div>
        <div
          className={classes.marker}
          style={{
            backgroundColor: 'rgba(255,255,255,1)',
            overflow: 'hidden',
            borderColor: borderColor,
          }}
        >
          {/* <TimeToLeaveIcon fontSize='large' style={{ color: '#fff', }} /> */}
          <img
            src={require('../assets/medycon/favicon-hook.png')}
            className={classes.markerImg}
          ></img>
        </div>
        <div className={classes.markerShadowContainer}>
          <img src={require('../assets/medycon/marker-shadow.png')}></img>
        </div>
      </div>
    </MarkerWithLabel>
  );
};

const Map = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyCMFpi3reTxyZtrQXSu6atHlale2gyDEjM&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: (
      <div
        style={{
          height: '100%',
          width: '100%',
          backgroundColor: 'rgba(255,255,255,0.5)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {' '}
        <CircularProgress />{' '}
      </div>
    ),
    containerElement: <div style={{ height: '100%', width: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(({ data, onMarkerSelect }) => {
  const [bounds, setBounds] = useState(new window.google.maps.LatLngBounds());

  const onSelectOut = () => {
    onMarkerSelect('');
  };

  const onSelectMarker = (id) => {
    onMarkerSelect(id);
  };

  useEffect(() => {
    if (data) {
      const newBounds = new window.google.maps.LatLngBounds();
      data.map((evento) => {
        const { lat, lng } = evento.coordenadasInicioMezclado;
        const latLng = new window.google.maps.LatLng(lat, lng);
        newBounds.extend(latLng);
      });
      data.map((evento) => {
        const { lat, lng } = evento.coordenadasFinMezclado;
        const latLng = new window.google.maps.LatLng(lat, lng);
        newBounds.extend(latLng);
      });
      setBounds(newBounds);
    }
  }, [data]);

  return (
    <GoogleMap
      defaultZoom={14}
      // center={{ lat: -34.5580305, lng: -58.4170088 }}
      defaultCenter={{ lat: -31.667488, lng: -63.091393 }}
      onClick={onSelectOut}
      ref={(map) =>
        map &&
        map.fitBounds(bounds, { top: 50, right: 50, bottom: 50, left: 50 })
      }
    >
      {data &&
        data.map((evento, indexEvento) => {
          const fecha = evento.fecha
            ? moment(evento.fecha, 'DD-MM-YYYY HH:mm:ss').format('HH:mm:ss')
            : '';
          return (
            <Fragment key={indexEvento}>
              <MarkerEvento
                position={evento.coordenadasInicioMezclado}
                fecha={fecha}
                onClick={() => onSelectMarker(evento.id)}
                isInicio={true}
              />
              <MarkerEvento
                position={evento.coordenadasFinMezclado}
                fecha={fecha}
                onClick={() => onSelectMarker(evento.id)}
                isInicio={false}
              />
            </Fragment>
          );
        })}
    </GoogleMap>
  );
});

function MapaMezclado({ data }) {
  return (
    <div style={{ height: '100%', width: '100%', position: 'relative' }}>
      <Map data={data} onMarkerSelect={() => null} />
    </div>
  );
}

export default MapaMezclado;
