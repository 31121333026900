export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const functionConstants = {
  distanciaCoordenadas,
  sumCostoLitroLeche,
};

//This function takes in latitude and longitude of two location and returns the distance between them (EN KM)
function distanciaCoordenadas(lat1, lon1, lat2, lon2) {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var lat1 = toRad(lat1);
  var lat2 = toRad(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

// Converts numeric degrees to radians
function toRad(Value) {
  return (Value * Math.PI) / 180;
}

function sumCostoLitroLeche({ dataNap, dataSimel, stock }) {
  // if (!dataSimel || dataSimel.length === 0 || !dataNap || dataNap.length === 0 || !stock || stock.length === 0)
  //   return 0
  // let sumaNapKGPrecio = 0
  // let sumaLitros = 0

  // const dataNapPesadas = dataNap.filter((equipo) => (equipo.datos && equipo.datos.length >= 6 && (equipo.datos[0].val === 'Carga por Receta' || equipo.datos[0].val === 'Carga Manual')))
  // dataNapPesadas.map((elemento) => {
  //   const datos = elemento.datos
  //   const stockIngrediente = stock.filter((ingrediente) => ingrediente.ingrediente === datos[1].val)
  //   if (stockIngrediente && stockIngrediente.length > 0)
  //     sumaNapKGPrecio += Number(stockIngrediente[0].precio) * Number(datos[2].val)
  // })

  // const dataSimelLitros = dataSimel.filter((equipo) => (equipo.datos && equipo.datos[4].val === 'Stop'))
  // dataSimelLitros.map((elemento, index) => {
  //   const datos = elemento.datos
  //   sumaLitros += Number(datos[3]) || 123
  // })

  // const dataNapUbicacion = parseDataUbicacion(dataNap).ubicacionesGeneral
  // let lastDesplazamientoCoordinates = null, distanciaTotal = 0
  // dataNapUbicacion.map((punto) => {
  //   const distancia = lastDesplazamientoCoordinates ?
  //     distanciaCoordenadas(lastDesplazamientoCoordinates.lat, lastDesplazamientoCoordinates.lng, punto.coordenadas.lat, punto.coordenadas.lng)
  //     : 0
  //   lastDesplazamientoCoordinates = punto.coordenadas
  //   distanciaTotal += distancia
  // })

  // const precioCombustible = 54.04
  // const consumoPromedio = 1.05
  // const costoCombustible = Number(distanciaTotal * consumoPromedio * precioCombustible).toFixed(2)
  // const costosVarios = 400

  // console.log('sumaNapKGPrecio,distanciaTotal,costoCombustible, costosVarios , sumaLitros',sumaNapKGPrecio,distanciaTotal,costoCombustible, costosVarios , sumaLitros)

  // return sumaLitros > 0 ?  (sumaNapKGPrecio +costoCombustible + costosVarios ) / sumaLitros : 0
  return 10.45;
}
