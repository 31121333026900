import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { errorMessage } from '.';
import { hookService } from '../../services';
import { sanitizeResponseArray } from '../../services/helpers';
import requestSlice, { dispatchRequest } from './request.slice';

const initialState: {
  equipos: any[];
  equipoSelected: any;
  fechaSelected: string;
  dataEquipo: any[];
  dataEquipoHomas: any[];
  homasHistorico: any[];
  stock: any[];
  stockHistorico: any[];
  simel: any[];
  tareas: any;
} = {
  equipos: [],
  equipoSelected: null,
  fechaSelected: moment().format('YYYY-MM-DD'),
  dataEquipo: [],
  dataEquipoHomas: [],
  homasHistorico: [],
  stock: [],
  stockHistorico: [],
  simel: [],
  tareas: [],
};

const hookSlice = createSlice({
  name: 'hook',
  initialState,
  reducers: {
    getEquiposAsignadosSuccess(state, action: PayloadAction<any[]>) {
      state.equipos = action.payload.filter(
        (equipo) => equipo && equipo !== null,
      );
      state.equipoSelected =
        action.payload.length > 0
          ? action.payload.find(
              (equipo) =>
                equipo &&
                equipo !== null &&
                (equipo.equipo === 'NAP' ||
                  equipo.equipo === 'NUS' ||
                  equipo.equipo === 'NSUM' ||
                  equipo.equipo === 'HoMaS'),
            ) || null
          : null;
    },
    getDataEquipoSuccess(state, action: PayloadAction<any[]>) {
      state.dataEquipo = action.payload;
    },
    getDataEquipoHomasSuccess(state, action: PayloadAction<any[]>) {
      state.dataEquipoHomas = action.payload;
    },
    getHomasHistoricoSuccess(state, action: PayloadAction<any[]>) {
      state.homasHistorico = action.payload;
    },
    getStockSuccess(state, action: PayloadAction<any[]>) {
      state.stock = action.payload;
    },
    getSimelSuccess(state, action: PayloadAction<any[]>) {
      state.simel = action.payload;
    },
    getStockHistoricoSuccess(state, action: PayloadAction<any[]>) {
      state.stockHistorico = action.payload;
    },
    getTareasSuccess(state, action: PayloadAction<any[]>) {
      state.tareas = action.payload;
    },
    resetData(state) {
      state.dataEquipo = [];
      state.stock = [];
      state.stockHistorico = [];
      state.simel = [];
    },
    setEquipoSelected(state, action: PayloadAction<any>) {
      state.equipoSelected = action.payload;
    },
    setFechaSelected(state, action: PayloadAction<string>) {
      state.fechaSelected = action.payload;
    },
  },
});

export default hookSlice;

export const resetData = hookSlice.actions.resetData;
export const setEquipoSelected = hookSlice.actions.setEquipoSelected;
export const setFechaSelected = hookSlice.actions.setFechaSelected;

const {
  getEquiposAsignadosSuccess,
  getDataEquipoSuccess,
  getDataEquipoHomasSuccess,
  getStockSuccess,
  getStockHistoricoSuccess,
  getSimelSuccess,
  getHomasHistoricoSuccess,
  getTareasSuccess,
} = hookSlice.actions;

const { request, success, failure } = requestSlice.actions;

export const getEquiposAsignados = () => async (dispatch) => {
  dispatchRequest(dispatch, hookService.getEquiposAsignados, undefined).then(
    (response) => {
      if (response)
        dispatch(getEquiposAsignadosSuccess(sanitizeResponseArray(response)));
    },
  );
};

export const getDataEquipo = ({
  NSerie,
  fecha,
}: {
  NSerie: string;
  fecha: string;
}) => async (dispatch) => {
  dispatch(request());
  return hookService.getDataEquipo({ NSerie, fecha }).then(
    (response) => {
      dispatch(success());
      dispatch(getDataEquipoSuccess(sanitizeResponseArray(response)));
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const getDataEquipoHomas = ({
  NSerie,
  fecha,
}: {
  NSerie: string;
  fecha: string;
}) => async (dispatch) => {
  dispatch(request());
  return hookService.getDataEquipoHomas({ NSerie, fecha }).then(
    (response) => {
      dispatch(success());
      dispatch(getDataEquipoHomasSuccess(sanitizeResponseArray(response)));
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const getHomasHistorico = ({
  NSerie,
  fecha,
}: {
  NSerie: string;
  fecha: string;
}) => async (dispatch) => {
  dispatch(request());
  return hookService.getDataEquipoHomasHistorico({ NSerie, fecha }).then(
    (response) => {
      dispatch(success());
      dispatch(getHomasHistoricoSuccess(sanitizeResponseArray(response)));
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const getStock = ({ stock }: { stock: string }) => async (dispatch) => {
  dispatch(request());
  return hookService.getStock({ stock }).then(
    (response) => {
      dispatch(success());
      dispatch(getStockSuccess(sanitizeResponseArray(response.stock)));
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const saveStock = ({
  NSerie,
  stock,
}: {
  NSerie: string;
  stock: any;
}) => async (dispatch) => {
  dispatch(request());
  return hookService.saveStock({ NSerie, stock }).then(
    (response) => {
      dispatch(success());
      dispatch(getStock({ stock: NSerie }));
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const getStockHistorico = ({
  stock,
  fechaInicial,
  fechaFinal,
}: {
  stock: string;
  fechaInicial: string;
  fechaFinal: string;
}) => async (dispatch) => {
  dispatch(request());
  return hookService
    .getStockHistorico({ stock, fechaInicial, fechaFinal })
    .then(
      (response) => {
        dispatch(success());
        dispatch(getStockHistoricoSuccess(sanitizeResponseArray(response)));
      },
      (error) => {
        dispatch(failure(errorMessage));
      },
    );
};

export const toggleRowPesadas = ({
  NSerie,
  fecha,
}: {
  NSerie: string;
  fecha: string;
}) => async (dispatch) => {
  dispatch(request());
  return hookService.onToggleRowPesadas({ NSerie, fecha }).then(
    (response) => {
      dispatch(success());
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const getSimel = ({
  NSerie,
  fecha,
}: {
  NSerie: string;
  fecha: string;
}) => async (dispatch) => {
  dispatch(request());
  return hookService.getSimel({ NSerie, fecha }).then(
    (response) => {
      dispatch(success());
      dispatch(getSimelSuccess(sanitizeResponseArray(response)));
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const saveConsumos = ({
  NSerie,
  precioCombustible,
  consumoPromedio,
}: {
  NSerie: string;
  precioCombustible: string;
  consumoPromedio: string;
}) => async (dispatch) => {
  dispatch(request());
  return hookService
    .saveConsumos({ NSerie, precioCombustible, consumoPromedio })
    .then(
      (response) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(errorMessage));
      },
    );
};

export const saveReportesConfigChanges = ({
  isEmailEnabled,
  email,
  periodoDias,
}: {
  isEmailEnabled: boolean;
  email: string;
  periodoDias: number;
}) => async (dispatch) => {
  dispatch(request());
  return hookService.userWantToReceiveReports({ state: isEmailEnabled }).then(
    (response) => {
      if (isEmailEnabled)
        return hookService.setEmailToUser({ email }).then(
          (response) => {
            return hookService
              .setReportsPeriodNAP({ numberOfDays: periodoDias })
              .then(
                (response) => {
                  dispatch(success());
                },
                (error) => {
                  dispatch(failure(errorMessage));
                },
              );
          },
          (error) => {
            dispatch(failure(errorMessage));
          },
        );
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const getTareas = ({ NSerie }: { NSerie: string }) => async (
  dispatch,
) => {
  dispatch(request());
  return hookService.getTareas({ NSerie }).then(
    (response) => {
      dispatch(success());
      dispatch(getTareasSuccess(sanitizeResponseArray(response)));
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};
