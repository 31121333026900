import { postToServer, getFromServer } from "./helpers";

export const configService = {
  getUserColumns,
  setUserColumns
};

async function getUserColumns({username}) {
  return await getFromServer("/api/v1/getUserColumns?username=" + username);
}

async function setUserColumns({ username, columns }) {
  return await postToServer("/api/v1/setUserColumns", { username, columns });
}
