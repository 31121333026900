import React, { useState, useEffect, useMemo } from 'react';
import 'styles/Hook.css';
import { parserFunctions } from '../../../helpers';
import Table from '../../Table';
import MapaMezclado from '../../MapaMezclado';
import { useColumns } from 'hooks';
import { useSelector } from 'react-redux';
import { ViewContainer, ViewTitle } from 'components/reusable';

function Mezclado() {
  const { dataEquipo: data } = useSelector((state) => state.hook);
  const { columns, onColumnsViewChange } = useColumns('columnsMezclado');
  const [idsSelected, setIdsSelected] = useState([]);
  const dataMezclado = useMemo(() => parserFunctions.parseDataMezclado(data), [
    data,
  ]);
  const dataMezcladoMapa = useMemo(
    () =>
      dataMezclado.length > 0 && idsSelected.length > 0
        ? dataMezclado.filter((mezclado, indexMezclado) =>
            idsSelected.includes(indexMezclado),
          )
        : [],
    [dataMezclado, idsSelected],
  );

  useEffect(() => {
    setIdsSelected([]);
    if (dataMezclado.length > 0) {
      setIdsSelected([dataMezclado.length - 1]);
    }
  }, [dataMezclado]);

  const onRowsSelect = (currentRowsSelected, allRowsSelected) => {
    setIdsSelected(allRowsSelected.map((row) => row.dataIndex));
  };

  return (
    <ViewContainer>
      <div className="mezclado-tabla">
        <Table
          title="Mezclado"
          columns={columns}
          data={dataMezclado}
          options={{
            pagination: false,
            onRowsSelect,
            disableToolbarSelect: true,
            selectableRows: 'multiple',
            filter: false,
            rowsSelected: idsSelected,
          }}
          onColumnViewChange={onColumnsViewChange}
          rowsSelected={idsSelected}
        />
      </div>
      <div className="mezclado-mapa-container">
        <ViewTitle title="Coordenadas de mezclado" />
        <div className="mezclado-mapa">
          <MapaMezclado data={dataMezcladoMapa} />
        </div>
      </div>
    </ViewContainer>
  );
}
export default Mezclado;
