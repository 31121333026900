import React, { Fragment, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { MedyconContext } from '../helpers';
import { makeStyles } from '@material-ui/core';

const TOPNAV_IMG_HOOK = require('../assets/medycon/favicon-hook.png');
const TOPNAV_IMG_MEDYCON = require('../assets/backgrounds/LOGO MEDYCON-NEGRO.png');

function ResponsiveDrawer({ menuItems = [], selectorItems = [], onLogout }) {
  const { isHook } = useContext(MedyconContext);
  const classes = responsiveDrawerStyles();
  const { username } = useSelector((state) => state.login);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const topnavImg = React.useMemo(
    () =>
      isHook ? (
        <img
          src={TOPNAV_IMG_HOOK}
          className="topnav-img"
          alt="topnav-img-hook"
        ></img>
      ) : (
        <img
          src={TOPNAV_IMG_MEDYCON}
          className="topnav-img"
          alt="topnav-img-medycon"
        ></img>
      ),
    [isHook],
  );

  const drawer = (
    <div>
      <div
        className={classes.toolbar}
        style={{
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {topnavImg}
      </div>
      <List className={classes.drawerUsernameListItem}>
        <ListItem disableGutters>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '8px',
              paddingRight: '8px',
            }}
          >
            <PersonPinIcon style={{ color: '#fff', marginRight: '5px' }} />
            <Typography>{username} </Typography>
          </div>
        </ListItem>
        {selectorItems.map((selector, index) => (
          <ListItem key={'selectos' + index}>{selector}</ListItem>
        ))}
      </List>
      {menuItems.map((item, index) => (
        <Fragment key={index}>
          <List>
            {item.map((subitem, indexSubitem) => (
              <ListItem
                button
                key={index + 'sub' + indexSubitem}
                component={NavLink}
                to={subitem.link}
                activeClassName={classes.activeDrawerLink}
                onClick={() => setMobileOpen(false)}
              >
                {(subitem.icon && (
                  <ListItemIcon>{subitem.icon}</ListItemIcon>
                )) ||
                  (location && location.pathname === subitem.link && (
                    <KeyboardArrowRightIcon
                      className={classes.drawerListItemText}
                    />
                  ))}
                <ListItemText
                  primary={subitem.label}
                  className={classes.drawerListItemText}
                />
              </ListItem>
            ))}
          </List>
          <Divider />
        </Fragment>
      ))}
      <List>
        <ListItem button onClick={onLogout}>
          <ListItemIcon>
            <ExitToAppIcon style={{ color: '#f44336' }} />
          </ListItemIcon>
          <ListItemText
            primary={'Cerrar sesión'}
            style={{ color: '#f44336' }}
          />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <Hidden mdUp>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <div style={{ flexGrow: 1 }}>{topnavImg}</div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Hidden>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp>
          <Drawer
            // container={container}
            variant="temporary"
            anchor={'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
              root: 'scrollable',
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

export default ResponsiveDrawer;

const drawerWidth = 240;
const responsiveDrawerStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    zIndex: 101,
    color: '#fff',
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: '#fff !important',
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#2b2b2b !important',
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#1e3462 !important',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  drawerListItemText: {
    color: '#fff',
  },
  drawerLink: {
    textDecoration: 'none',
  },
  activeDrawerLink: {
    backgroundColor: 'rgba(0,0,0,0.2) !important',
    color: '#fff !important',
    transition: 'padding-left 0.3s ease-in-out !important',
  },
  drawerUsernameListItem: {
    backgroundColor: '#121c36 !important',
    color: '#fff',
  },
}));
