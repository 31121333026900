import React, { Fragment, useState } from 'react';
import { Button, Typography, MenuItem, FormControl, OutlinedInput, InputLabel, Select, Input, Checkbox, ListItemText,  IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import 'styles/Admin.css'
import CustomMenu from '../CustomMenu'
import AlertDialog from '../AlertDialog'

function CardEquipo({ equipo, usuarios, stocks, onSaveAsociaciones, onCambiarAliasEquipo, onAsociarSimel, onRemoveSimel, onRemoveEquipo, onClearDatosEquipo, onCreateStock, onAddStockToNap, onRemoveStockFromNap }) {
    const { ver, NSerie, equipo: equipoTipo, simel, alias, stock } = equipo || {}
    const asignados = usuarios ? usuarios.filter(usuario => usuario.equipos.filter(equipoAsignado => equipoAsignado === NSerie).length > 0).map(usuario => usuario.username) : []
    const names = usuarios ? usuarios.map(usuario => usuario.username) : []

    return (
        equipo ?
            <div className="equipo-card-container"  >
                {/* <div className="equipo-card-avatar">
                    {equipo.thumbnail ? <img src={equipo.thumbnail} alt="" className="equipo-avatar-img" />
                        : <img src={require('../assets/equipo-logo.png')} alt="Brastlewark" className="equipo-avatar-img no-thumbnail" />
                    }
                </div> */}
                <div className="equipo-card-content-container">
                    <div className="equipo-card-name" >
                        <span>{alias ? alias + " (" : ""}{equipoTipo}{alias ? ")" : ""}</span>
                        <CardEquipoMenu stocks={stocks}
                            onCambiarAliasEquipo={(newAlias) => onCambiarAliasEquipo({ NSerie, alias: newAlias })}
                            onAsociarSimel={(NSerieSimel) => onAsociarSimel({ NSerieSimel, NSerieNap: NSerie })}
                            onEliminarEquipo={() => onRemoveEquipo({ NSerie })}
                            onLimpiarDatos={() => onClearDatosEquipo({ NSerie })}
                            onCreateStock={(name) => onCreateStock({ name })}
                            onAddStockToNap={(stock) => onAddStockToNap({ NSerie, stock })} />
                    </div>
                    <div className="equipo-card-divider" />
                    <div className={"equipo-characteristics-container"}>
                        <div className={"equipo-card-characteristic"}>Nro. serie: {NSerie}</div>
                        <div className={"equipo-card-characteristic"}>Versión: {ver}</div>
                        <div className="equipo-user-selector">
                            <UserSelector asignados={asignados} names={names} onSaveAsociaciones={(nuevosUsuariosAsociados) => onSaveAsociaciones({ usuariosAsociados: nuevosUsuariosAsociados, NSerie })} />
                        </div>
                    </div>
                    {
                        simel && <div className="equipo-simel-list-item" >
                            <Typography variant='body1' className="texto" >SIMEL: {simel}</Typography>
                            <IconButton aria-controls="simple-menu" aria-haspopup="true"
                                onClick={() => onRemoveSimel({ NSerieNap: NSerie })}
                                size="small" className="icon-button">
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    }
                    {
                        stock && <div className="equipo-simel-list-item" >
                            <Typography variant='body1' className="texto" >Stock: {stock}</Typography>
                            <IconButton aria-controls="simple-menu" aria-haspopup="true"
                                onClick={() => onRemoveStockFromNap({ NSerie: NSerie })}
                                size="small" className="icon-button">
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    }
                </div>
            </div>
            : null
    );
}


export default CardEquipo;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function UserSelector({ asignados, names, onSaveAsociaciones }) {
    const [personName, setPersonName] = useState(asignados || []);
    const [shouldSave, setShouldSave] = useState(false)

    const handleChange = event => {
        setPersonName(event.target.value);
        setShouldSave(true)
    };

    const handleDescartarCambios = () => {
        setPersonName(asignados || []);
        setShouldSave(false)
    }

    const handleGuardarCambios = () => {
        onSaveAsociaciones(personName)
        setShouldSave(false)
    }

    return (
        <div className="user-selector">
            <FormControl className='formcontrol-selector'>
                <InputLabel id="demo-mutiple-checkbox-label">Usuarios asignados</InputLabel>
                <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={personName}
                    onChange={handleChange}
                    input={<Input />}
                    renderValue={selected => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {names.map(name => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {shouldSave && <div className="form-control-buttons">
                <Button onClick={handleDescartarCambios} className='button' color="secondary" size="small" >
                    Descartar cambios
                </Button>
                <Button onClick={handleGuardarCambios} className='button' color='primary' variant='contained' size="small" >
                    Guardar cambios
                </Button>
            </div>
            }
        </div>
    )
}

function CardEquipoMenu({ stocks, onCambiarAliasEquipo, onAsociarSimel, onEliminarEquipo, onLimpiarDatos, onCreateStock, onAddStockToNap }) {
    const [openAlertDialogAlias, setOpenAlertDialogAlias] = useState(false)
    const [openAlertDialogSIMEL, setOpenAlertDialogSIMEL] = useState(false)
    const [openAlertDialogLimpiar, setOpenAlertDialogLimpiar] = useState(false)
    const [openAlertDialogRemove, setOpenAlertDialogRemove] = useState(false)
    const [openAlertDialogStock, setOpenAlertDialogStock] = useState(false)
    const [aliasEquipo, setAliasEquipo] = useState("")
    const [nroSIMEL, setNroSIMEL] = useState("")
    const [stock, setStock] = useState("")
    const [isNewStock, setIsNewStock] = useState(false)

    const isStockNameValid = stock !== ""
    const isStockNameNew = isStockNameValid && (stocks.length === 0 || stocks.filter(stockDB => stockDB === stock).length === 0)

    const customMenuItems = [
        <Typography variant='body1' onClick={() => setOpenAlertDialogAlias(true)}>Cambiar alias</Typography>,
        <Typography variant='body1' onClick={() => setOpenAlertDialogSIMEL(true)}>Asociar SIMEL al equipo</Typography>,
        <Typography variant='body1' onClick={() => setOpenAlertDialogStock(true)}>Asociar stock al equipo</Typography>,
        <Typography variant='body1' onClick={() => setOpenAlertDialogLimpiar(true)}>Limpiar datos del equipo</Typography>,
        <Typography variant='body1' style={{ color: "#f44336" }} onClick={() => setOpenAlertDialogRemove(true)}>Eliminar equipo</Typography>
    ]

    const handleCambiarAliasEquipo = () => {
        setOpenAlertDialogAlias(false)
        onCambiarAliasEquipo(aliasEquipo)
        setAliasEquipo("")
    }

    const handleAsociarSIMEL = () => {
        setOpenAlertDialogSIMEL(false)
        onAsociarSimel(nroSIMEL)
        setNroSIMEL("")
    }

    const handleAsociarStock = () => {
        setOpenAlertDialogStock(false)
        if (isStockNameNew) onCreateStock(stock)
        onAddStockToNap(stock)
        setStock("")
    }

    const handleLimpiarDatos = () => {
        setOpenAlertDialogLimpiar(false)
        onLimpiarDatos()
    }

    const handleEliminarEquipo = () => {
        setOpenAlertDialogRemove(false)
        onEliminarEquipo()
    }

    return (
        <Fragment>
            <AlertDialog open={openAlertDialogSIMEL} onClose={() => setOpenAlertDialogSIMEL(false)}
                titulo="Asociar SIMEL"
                contenido={
                    <FormControl className='formcontrol-selector' variant="outlined">
                        <InputLabel htmlFor="input-nap">Número de serie SIMEL</InputLabel>
                        <OutlinedInput
                            id="input-nap"
                            required
                            type="text"
                            value={nroSIMEL}
                            onChange={(event) => setNroSIMEL(event.target.value)}
                            labelWidth={170}
                        />
                    </FormControl>
                }
                onPositiveAction={handleAsociarSIMEL} positiveActionText="Guardar" />
            <AlertDialog open={openAlertDialogStock} onClose={() => setOpenAlertDialogStock(false)}
                titulo="Asociar stock"
                contenido={
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='body1' style={{ marginBottom: 10 }}>Elegir un stock:</Typography>
                        {!isNewStock ? <Select
                            value={stock}
                            onChange={(event) => setStock(event.target.value)}
                            disabled={isNewStock}
                        >
                            {stocks.map(stockElement => (
                                <MenuItem key={stockElement} value={stockElement}>
                                    <ListItemText primary={stockElement} />
                                </MenuItem>
                            ))}
                        </Select>
                            : <FormControl className='formcontrol-selector' variant="outlined">
                                <InputLabel htmlFor="input-nap">Nombre del stock</InputLabel>
                                <OutlinedInput
                                    id="input-nap"
                                    required
                                    type="text"
                                    value={stock}
                                    onChange={(event) => setStock(event.target.value.trim())}
                                    labelWidth={170}
                                />
                            </FormControl>}
                        <Button onClick={() => setIsNewStock(!isNewStock)} className='button' variant="outlined" color="primary" size="small" style={{ margin: '10px 0px' }}>
                            {!isNewStock ? "El stock no está en la lista" : "Volver a la lista de stocks"}
                        </Button>
                    </div>
                }
                onPositiveAction={handleAsociarStock} positiveActionText="Guardar" disabledAction={!isStockNameValid} />
            <AlertDialog open={openAlertDialogAlias} onClose={() => setOpenAlertDialogAlias(false)}
                titulo="Cambiar alias"
                contenido={
                    <FormControl className='formcontrol-selector' variant="outlined">
                        <InputLabel htmlFor="input-alias">Alias del equipo</InputLabel>
                        <OutlinedInput
                            id="input-alias"
                            required
                            type="text"
                            value={aliasEquipo}
                            onChange={(event) => setAliasEquipo(event.target.value)}
                            labelWidth={120}
                        />
                    </FormControl>
                }
                onPositiveAction={handleCambiarAliasEquipo} positiveActionText="Guardar" />
            <AlertDialog open={openAlertDialogLimpiar} onClose={() => setOpenAlertDialogLimpiar(false)} titulo="Limpiar datos del equipo" contenido="¿Está seguro que desea limpiar los datos del equipo? Esta acción no se puede deshacer." onPositiveAction={handleLimpiarDatos} />
            <AlertDialog open={openAlertDialogRemove} onClose={() => setOpenAlertDialogRemove(false)} titulo="Eliminar equipo" contenido="¿Está seguro que desea eliminar el equipo? Esta acción no se puede deshacer." onPositiveAction={handleEliminarEquipo} />
            <CustomMenu customMenuItems={customMenuItems} />
        </Fragment>
    )
}