/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { parserFunctions } from 'helpers';
import Table from 'components/Table';
import { SelectBetweenFechas } from 'components/Selectors';
import { Alert } from '@material-ui/lab';
import { useColumns } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getStockHistorico } from 'redux/slices/hook.slice';
import { ViewContainerVertical } from 'components/reusable';
import ConfigReporte from './config-reporte';

const nowMenosUnDia = moment()
  .subtract(1, 'day')
  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  .format('YYYY-MM-DD');

function StockHistorico() {
  const dispatch = useDispatch();
  const { stockHistorico: data, equipoSelected } = useSelector(
    (state) => state.hook,
  );
  const { stock: stockName } = equipoSelected || {};
  const { columns, onColumnsViewChange } = useColumns('columnsStockHistorico');
  const dataStock = useMemo(
    () => parserFunctions.parseDataStockHistorico(data),
    [data],
  );
  const [fechaInicial, setFechaInicial] = useState(nowMenosUnDia);
  const [fechaFinal, setFechaFinal] = useState(nowMenosUnDia);

  const filenameFechas = useMemo(
    () =>
      fechaInicial !== fechaFinal
        ? `${moment(fechaInicial, 'YYYY-MM-DD').format('DD/MM/YY')} - ${moment(
            fechaFinal,
            'YYYY-MM-DD',
          ).format('DD/MM/YY')}`
        : `${moment(fechaInicial, 'YYYY-MM-DD').format('DD/MM/YY')}`,
    [fechaInicial, fechaFinal],
  );

  const msgPreciosLabel = useMemo(
    () =>
      dataStock
        .filter((ingrediente) => ingrediente.tieneMultiplesPrecios)
        .map((ingrediente) => ingrediente.ingrediente)
        .join(', '),
    [dataStock],
  );

  const onTableDownload = (buildHead, buildBody, columns, data) => {
    return (
      `Reporte stock equipo ${stockName} (${filenameFechas})\n` +
      buildHead(columns) +
      buildBody(data)
    );
  };

  useEffect(() => {
    if (stockName && fechaInicial && fechaFinal)
      dispatch(
        getStockHistorico({
          stock: stockName,
          fechaInicial,
          fechaFinal,
        }),
      );
  }, [fechaFinal, fechaInicial, stockName]);

  return (
    <ViewContainerVertical>
      <SelectBetweenFechas
        fechaInicio={fechaInicial}
        fechaFin={fechaFinal}
        onSelectFechaInicio={setFechaInicial}
        onSelectFechaFin={setFechaFinal}
        color="#2b2b2b"
        maxFechaFin={nowMenosUnDia}
      />
      <div style={{ flexGrow: 1, marginBottom: 30 }}>
        <Table
          title="Stock histórico"
          columns={columns}
          data={dataStock}
          options={{
            pagination: false,
            filter: true,
            downloadOptions: {
              filename: `stock-historico-${stockName}.csv`,
              separator: ',',
            },
            onDownload: onTableDownload,
            customFooter: () =>
              msgPreciosLabel !== '' && (
                <Alert severity="info">
                  Entre las fechas seleccionadas se actualizó el precio para{' '}
                  {msgPreciosLabel}.
                </Alert>
              ),
          }}
          onColumnViewChange={onColumnsViewChange}
        />
      </div>
      <ConfigReporte />
    </ViewContainerVertical>
  );
}
export default StockHistorico;
