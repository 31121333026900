import { getFromServer } from "./helpers";
export const loginService = {
  login,
  logout,
  getLoggedUser,
};

export async function login({ username, password }) {
  let response = null;
  try {
    response = await fetch(
      //process.env.REACT_APP_URL_BASE +
      "/api/v1/login",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      }
    );
    if (!response.ok) return Promise.reject(response);
  } catch (error) {
    return Promise.reject(error);
  }
  return response;
}

export async function logout() {
  let response = null;
  try {
    response = await fetch(
      //process.env.REACT_APP_URL_BASE +
      "/api/v1/logout",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) return Promise.reject(response);
  } catch (error) {
    return Promise.reject(error);
  }
  return response;
}

async function getLoggedUser() {
  return await getFromServer("/api/v1/getLoggedUser");
}
