import React, { useMemo } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import 'styles/Medycon.css';
import {
  ViewBody,
  CardWithHeader,
  ViewContainerVertical,
  ContentInfo,
  CardDivider,
  ViewContainer,
} from 'components/reusable';

moment.locale('es');

const BOMBA_ENGINE = require('assets/medycon/engine.svg');

function CardEquipoN({ equipoBundle, onVerMas }) {
  const { equipo: infoEquipo, datos = [] } = equipoBundle || {};
  const {  equipo: equipoTipo, alias = '' } = infoEquipo || {};
  const ultimaCadena = datos.length > 0 ? datos[0] : undefined;
  const {
    fecha: ultimaCadenaFecha,
    Nivel = 0,
    MaxAbs = 0,
    MinAbs = 0,
    EstR1 = false,
    EstR2 = false,
  } = ultimaCadena || {};
  const alturaTanque =
    equipoTipo === 'NSUM'
      ? Number(MaxAbs) - Number(MinAbs)
      : equipoTipo === 'NUS' || equipoTipo === 'N-RAD'
      ? Number(MinAbs) - Number(MaxAbs)
      : 0;
  const nivelBomba =
    alturaTanque > -1 ? Math.round((Number(Nivel) / alturaTanque) * 100) : 0;

  const ultimoDatoRecibido = ultimaCadena
    ? `Último dato recibido ${moment(ultimaCadenaFecha).fromNow()}.`
    : `No se encontraron datos recientes del equipo.`;

  const alarmas = useMemo(
    () => datos.filter((dato) => dato.Alarmas && dato.Alarmas.trim() !== ''),
    [datos],
  );
  const alarmasRecientes = [];
  alarmas
    .map((alarma) => alarma.Alarmas)
    .forEach((value, index, self) => {
      if (self.indexOf(value) === index) alarmasRecientes.push(alarmas[index]);
    });

  return (
    <CardWithHeader
      title={`${alias ? alias + ' (' : ''}${equipoTipo}${alias ? ')' : ''}`}
      menuItems={[
        <span variant="body1" onClick={onVerMas}>
          Ver más
        </span>,
      ]}
    >
      <ViewContainerVertical>
        {ultimaCadena && (
          <>
            <NivelBombaRepresentativo
              nivel={Number(Nivel)}
              nivelPorcentual={nivelBomba || 0}
            />
            <CardDivider withMargin />
            <ViewBody title={'Estado de bombas'} />
            <ViewContainer style={{ justifyContent: 'space-evenly' }}>
              <EstadoBomba isON={EstR1} id={1} />
              <EstadoBomba isON={EstR2} id={2} />
            </ViewContainer>
            <CardDivider withMargin />
            <ViewBody title={'Alarmas recientes'} />
            <ContentInfo
              contentInfoItems={
                alarmasRecientes.length > 0
                  ? alarmasRecientes.map(
                      (alarma) =>
                        `${alarma.Alarmas.trim()} (${moment(
                          alarma.fecha,
                        ).fromNow()})`,
                    )
                  : ['No se encontraron alarmas recientes para el equipo.']
              }
            />
          </>
        )}
        <CardDivider withMargin />
        <ViewBody title={ultimoDatoRecibido} />
      </ViewContainerVertical>
    </CardWithHeader>
  );
}

export default CardEquipoN;

const NivelBombaRepresentativo = React.memo(({ nivel, nivelPorcentual }) => {
  const nivelThreshold = 20;
  const backgroundColor =
    nivelPorcentual < nivelThreshold / 2
      ? '#f44336'
      : nivelPorcentual < nivelThreshold
      ? '#ffc107'
      : '#1e3462';
  return (
    <>
      <ViewBody title={`Nivel: ${nivel}cm (${nivelPorcentual}%)`} />
      <ViewContainerVertical style={{ alignItems: 'center' }}>
        <div
          style={{
            width: '100px',
            height: '150px',
            borderRadius: 5,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-end',
            boxShadow: '0 0 5px 0 rgba(0,0,0,0.2)',
            marginRight: 5,
          }}
        >
          <div
            style={{
              width: '100%',
              height: nivelPorcentual + '%',
              backgroundColor,
              opacity: 0.9,
            }}
          />
        </div>
      </ViewContainerVertical>
    </>
  );
});

const EstadoBomba = React.memo(({ id, isON }) => {
  return (
    <div className="estado-bomba">
      <img src={BOMBA_ENGINE} className="estado-bomba-engine-img" alt="logo" />
      <ViewBody
        title={id}
        style={{ position: 'absolute', top: '10px', right: '-12px' }}
      />
      <div
        className={
          'estado-bomba-indicator' + (isON ? ' bomba-on' : ' bomba-off')
        }
      />
    </div>
  );
});
