import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loginService } from '../../services';

const initialState = {
  role: '',
  username: '',
  email: '',
  napReportPeriod: 1,
  wantToReceiveReports: false,
  message: '',
  isLoading: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    updateCookies(
      state,
      action: PayloadAction<{ role?: string; username?: string }>,
    ) {
      state.role = action.payload.role || '';
      state.username = action.payload.username || '';
    },
    loginRequest(state) {
      state.isLoading = true;
    },
    loginSuccess(state, action: PayloadAction<{ username: string }>) {
      state.username = action.payload.username;
      state.isLoading = false;
    },
    loginFailure(state, action: PayloadAction<{ message: string }>) {
      state.username = '';
      state.isLoading = false;
      state.message = action.payload.message;
    },
    logoutSuccess(state) {
      state.username = '';
      state.role = '';
      state.isLoading = false;
    },
    logoutFailure(state, action: PayloadAction<{ message: string }>) {
      state.isLoading = false;
      state.message = action.payload.message;
    },
    updateLoggedUserSuccess(
      state,
      action: PayloadAction<{ email; napReportPeriod; wantToReceiveReports }>,
    ) {
      state.isLoading = false;
      state.email = action.payload.email;
      state.napReportPeriod = action.payload.napReportPeriod;
      state.wantToReceiveReports = action.payload.wantToReceiveReports;
    },
    updateLoggedUserFailure(state, action: PayloadAction<{ message: string }>) {
      state.isLoading = false;
      state.message = action.payload.message;
    },
  },
});

export default loginSlice;

export const updateCookies = loginSlice.actions.updateCookies;

const {
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  updateLoggedUserSuccess,
  updateLoggedUserFailure,
} = loginSlice.actions;

export const tryLogin = ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => async (dispatch) => {
  dispatch(loginRequest());
  return loginService.login({ username, password }).then(
    (response) => {
      dispatch(loginSuccess({ username }));
    },
    (error) => {
      dispatch(loginFailure({ message: 'Usuario o contraseña incorrectos.' }));
    },
  );
};

export const logout = () => async (dispatch) => {
  dispatch(loginRequest());
  return loginService.logout().then(
    (response) => {
      dispatch(logoutSuccess());
    },
    (error) => {
      dispatch(
        logoutFailure({ message: 'Hubo un problema al cerrar sesión.' }),
      );
    },
  );
};

export const updateLoggedUser = () => async (dispatch) => {
  dispatch(loginRequest());
  return loginService.getLoggedUser().then(
    (response) => {
      const { email, napReportPeriod, wantToReceiveReports } = response || {};
      dispatch(
        updateLoggedUserSuccess({
          email,
          napReportPeriod,
          wantToReceiveReports,
        }),
      );
    },
    (error) => {
      dispatch(
        updateLoggedUserFailure({
          message: 'Hubo un problema al obtener datos del servidor.',
        }),
      );
    },
  );
};
