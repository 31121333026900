import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import Table from 'components/Table';
import { ViewContainerVertical, ViewSeparator } from 'components/reusable';
import { useColumns } from 'hooks';
import { parserMedyconFunctions } from 'helpers';
import LineChartAlfa01 from './line-chart-alfa01';
import LineChartN from './line-chart-n';

const Historico = () => {
  const { equipoSelected: equipo, dataEquipoSelected: data } = useSelector(
    (state) => state.medycon,
  );
  const { equipo: equipoTipo } = equipo || {};
  const { columns } = useColumns(
    equipoTipo === 'CITH'
      ? 'columnsMedyconHistoricoCITH'
      : equipoTipo === 'Alfa01'
      ? 'columnsMedyconHistoricoAlfa01'
      : 'columnsMedyconHistorico',
  );
  const datosHistoricos = useMemo(
    () =>
      data && data.length
        ? equipoTipo === 'CITH'
          ? parserMedyconFunctions.parseDataCITHHistorico(data)
          : equipoTipo === 'Alfa01'
          ? parserMedyconFunctions.parseDataAlfa01Historico(data)
          : parserMedyconFunctions.parseDataNUSNSUMHistorico(equipo, data)
        : [],
    [data, equipo, equipoTipo],
  );

  return equipoTipo && equipoTipo !== 'SAP01' ? (
    <ViewContainerVertical>
      {equipoTipo === 'Alfa01' ? (
        <>
          <LineChartAlfa01 datosHistoricos={datosHistoricos} />
          <ViewSeparator />
        </>
      ) : equipoTipo !== 'CITH' ? (
        <>
          <LineChartN datosHistoricos={datosHistoricos} />
          <ViewSeparator />
        </>
      ) : null}
      <Table
        title="Datos históricos"
        data={datosHistoricos}
        columns={columns}
        options={{ pagination: false, filter: false }}
      />
    </ViewContainerVertical>
  ) : (
    <Alert severity="info">
      No se encontraron datos recientes para este equipo.
    </Alert>
  );
};

export default Historico;
