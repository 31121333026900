import moment from 'moment';

const ACCION_CARGA_POR_RECETA = 'Carga por Receta';
const ACCION_CARGA_MANUAL = 'Carga Manual';
const ACCION_DESCARGA_POR_GUIA = 'Descarga por Guia';
const ACCION_DESCARGA_MANUAL = 'Descarga Manual';
const ACCION_UBICACION_GENERAL = 'Ubicacion General';

export const parserFunctions = {
  parseDataDescargas,
  parseDataMezclado,
  parseDataPesadas,
  parseDataSimelDescarga,
  parseDataSimelOrdenie,
  parseDataUbicacion,
  parseDataSimelHistorico,
  parseDataHomas,
  parseDataHomasHistorico,
  parseDataStockHistorico,
  parseDataReceta,
  parseDataHistoricoDietas,
};
export const parserMedyconFunctions = {
  parseDataNUSNSUMHistorico,
  parseDataCITHHistorico,
  parseDataAlfa01Historico,
};

function parseDataPesadas(data = [], stock = []) {
  const newDataPesadas = data
    .filter(
      (equipo) =>
        equipo.accion === ACCION_CARGA_POR_RECETA ||
        equipo.accion === ACCION_CARGA_MANUAL,
    )
    .sort((element1, element2) => (element1.fecha < element2.fecha && -1) || 1);
  return newDataPesadas.map((elemento, index) => {
    const {
      nombreReceta,
      accion,
      aCargar,
      finReceta,
      fecha,
      cargado,
      ingrediente,
      sumaStock,
      precioStock = 0,
      porcentajeMateriaSeca = 100,
    } = elemento || {};
    let nextReceta =
      index + 1 < newDataPesadas.length
        ? newDataPesadas[index + 1].nombreReceta
        : '';
    const kgTeoricoMS = aCargar * (porcentajeMateriaSeca / 100);
    const kgCargadosMS = cargado * (porcentajeMateriaSeca / 100);
    const fila = {
      finReceta:
        finReceta === 'SI' ||
        finReceta === 'SI-ANTICIPADA' ||
        (nombreReceta && nombreReceta !== nextReceta),
      fecha: moment.utc(fecha).local().format('DD-MM-YYYY HH:mm:ss'),
      fechaOriginal: fecha,
      ingredientes: ingrediente,
      kgTeorico: aCargar,
      kgCargados: cargado,
      dieta: nombreReceta,
      diferenciaKg: parseInt(cargado, 10) - parseInt(aCargar, 10),
      precio: precioStock,
      porcentajePrecio: (
        precioStock *
        (parseInt(cargado, 10) - parseInt(aCargar, 10))
      ).toFixed(2),
      porcentajeKg: (
        (parseInt(cargado, 10) * 100) / parseInt(aCargar, 10) -
        100
      ).toFixed(2),
      isAccionManual: accion === ACCION_CARGA_MANUAL,
      sumaStock,
      porcentajeMateriaSeca,
      kgTeoricoMS,
      kgCargadosMS,
    };
    return fila;
  });
}

function parseDataDescargas(data = []) {
  const newDataDescargas = data
    .filter(
      (equipo) =>
        equipo.accion === ACCION_DESCARGA_POR_GUIA ||
        equipo.accion === ACCION_DESCARGA_MANUAL,
    )
    .sort((element1, element2) => (element1.fecha < element2.fecha && -1) || 1);
  return newDataDescargas.map((elemento, index) => {
    const {
      finGuia,
      fecha,
      lote,
      aDescargar,
      descargado,
      nombreGuia,
      accion,
    } = elemento;
    let nextGuia =
      index + 1 < newDataDescargas.length
        ? newDataDescargas[index + 1].nombreGuia
        : '';
    return {
      finReceta:
        finGuia === 'SI' ||
        finGuia === 'SI-ANTICIPADA' ||
        (nombreGuia && nombreGuia !== nextGuia),
      fecha: moment.utc(fecha).local().format('DD-MM-YYYY HH:mm:ss'),
      lote,
      kgTeorico: aDescargar,
      kgCargados: descargado,
      dieta: nombreGuia,
      diferenciaKg: parseInt(descargado, 10) - parseInt(aDescargar, 10),
      porcentajeKg: (
        (parseInt(descargado, 10) * 100) / parseInt(aDescargar, 10) -
        100
      ).toFixed(2),
      isAccionManual: accion === ACCION_DESCARGA_MANUAL,
    };
  });
}

function parseDataMezclado(data = []) {
  const newDataMezclado = data
    .filter((equipo) => equipo.accion === 'Mezclado')
    .sort((element1, element2) => (element1.fecha < element2.fecha && -1) || 1);
  return newDataMezclado.map((elemento) => {
    const {
      faltoMezclar,
      tiempoQueFaltoMezclar,
      coordFinMezclado,
      coordInicioMezclado,
      desplazamientoMezclado,
      fecha,
      tiempoMezclado,
    } = elemento;

    const latLngInicio = coordInicioMezclado
      ? coordInicioMezclado.split(',')
      : null;
    const latLngFin = coordFinMezclado ? coordFinMezclado.split(',') : null;
    return {
      fecha: moment.utc(fecha).local().format('DD-MM-YYYY HH:mm:ss'),
      tiempoMezclado,
      faltoMezclar: faltoMezclar ? 'Sí' : 'No',
      tiempoNoMezclado: tiempoQueFaltoMezclar,
      desplazamientoMezclado: desplazamientoMezclado ? 'Sí' : 'No',
      coordenadasInicioMezclado: latLngInicio
        ? {
            lat: parseFloat(latLngInicio[0], 10),
            lng: parseFloat(latLngInicio[1], 10),
          }
        : null,
      coordenadasFinMezclado: latLngFin
        ? {
            lat: parseFloat(latLngFin[0], 10),
            lng: parseFloat(latLngFin[1], 10),
          }
        : null,
    };
  });
}

function parseDataUbicacion(data = []) {
  const newDataUbicacion = data
    .filter(
      (equipo) =>
        equipo.accion === ACCION_CARGA_POR_RECETA ||
        equipo.accion === ACCION_DESCARGA_POR_GUIA ||
        equipo.accion === ACCION_CARGA_MANUAL ||
        equipo.accion === ACCION_DESCARGA_MANUAL ||
        equipo.accion === ACCION_UBICACION_GENERAL,
    )
    .sort((element1, element2) => (element1.fecha < element2.fecha && -1) || 1);
  const ubicacionesIngrediente = [];
  const ubicacionesLote = [];
  const ubicacionesGeneral = [];
  let velocidadPromedio = 0;
  let consumoPromedioKM = 0;
  let costoCombustibleKM = 0;
  newDataUbicacion.forEach((elemento) => {
    const {
      fecha,
      accion,
      coordenada,
      ingrediente,
      lote,
      velocidad = 0,
      consumoPromedioKilometroLitro = 0,
      costoCombustibleLitro = 0,
    } = elemento || {};
    let latLng = coordenada ? coordenada.split(',') : [0, 0];
    if (parseFloat(latLng[0]) === 0 || parseFloat(latLng[1]) === 0)
      latLng = null;
    const fechaUbicacion = moment
      .utc(fecha)
      .local()
      .format('DD-MM-YYYY HH:mm:ss');
    const name =
      accion === ACCION_CARGA_POR_RECETA || accion === ACCION_CARGA_MANUAL
        ? ingrediente
        : accion === ACCION_DESCARGA_POR_GUIA ||
          accion === ACCION_DESCARGA_MANUAL
        ? lote
        : 'Desplazamiento';
    const ubicacion = {
      fecha: fechaUbicacion,
      name,
      coordenadas: {
        lat: latLng !== null ? parseFloat(latLng[0], 10) : null,
        lng: latLng !== null ? parseFloat(latLng[1], 10) : null,
      },
    };

    if (latLng !== null)
      switch (accion) {
        case ACCION_CARGA_POR_RECETA:
        case ACCION_CARGA_MANUAL:
          ubicacionesIngrediente.push(ubicacion);
          break;
        case ACCION_DESCARGA_POR_GUIA:
        case ACCION_DESCARGA_MANUAL:
          ubicacionesLote.push(ubicacion);
          break;
        case ACCION_UBICACION_GENERAL:
          ubicacionesGeneral.push(ubicacion);
          break;
        default:
          break;
      }

    if (consumoPromedioKilometroLitro > 0 && consumoPromedioKM === 0)
      consumoPromedioKM = consumoPromedioKilometroLitro;
    if (costoCombustibleLitro > 0 && costoCombustibleKM === 0)
      costoCombustibleKM = costoCombustibleLitro;
    velocidadPromedio += velocidad;
  });
  if (newDataUbicacion.length > 0)
    velocidadPromedio = (velocidadPromedio / newDataUbicacion.length) * 1.609;
  return {
    ubicacionesIngrediente,
    ubicacionesLote,
    ubicacionesGeneral,
    consumoPromedioKM,
    costoCombustibleKM,
    velocidadPromedio,
  };
}

function parseDataSimelOrdenie(data = []) {
  const newDataSimelOrdenie = data
    .filter(
      (equipo) =>
        equipo.estado === 'Stop' &&
        Number(equipo.litrosRodeo) > 0 &&
        !(
          equipo.numRodeo === 'Descarga' ||
          equipo.numRodeo === 'DescInd' ||
          equipo.numRodeo === 'Indef' ||
          equipo.numRodeo === 'descAuto'
        ) &&
        equipo.keepAlive === false,
    )
    .sort((element1, element2) => (element1.fecha < element2.fecha && -1) || 1);
  return newDataSimelOrdenie.map((elemento, index) => {
    const { numRodeo, litrosRodeo, estado, fecha } = elemento;
    return {
      fecha: moment.utc(fecha).local().format('DD-MM-YYYY HH:mm:ss'),
      rodeo: numRodeo,
      litros: Number(litrosRodeo) || 0,
      finReceta: index === newDataSimelOrdenie.length - 1,
      estado,
    };
  });
}

function parseDataSimelDescarga(data = []) {
  const newDataSimelDescarga = data
    .filter(
      (equipo) =>
        equipo.estado === 'Stop' &&
        Number(equipo.litrosRodeo) > 0 &&
        (equipo.numRodeo === 'Descarga' ||
          equipo.numRodeo === 'DescInd' ||
          equipo.numRodeo === 'descAuto') &&
        equipo.keepAlive === false,
    )
    .sort((element1, element2) => (element1.fecha < element2.fecha && -1) || 1);
  return newDataSimelDescarga.map((elemento, index) => {
    const { numRodeo, litrosRodeo, estado, fecha } = elemento;
    return {
      fecha: moment.utc(fecha).local().format('DD-MM-YYYY HH:mm:ss'),
      rodeo: numRodeo,
      litros: Number(litrosRodeo) || 0,
      finReceta: index === newDataSimelDescarga.length - 1,
      estado,
    };
  });
}

function parseDataSimelHistorico(data = []) {
  return data.map((dato) => {
    return {
      ...dato,
      fecha: moment.utc(dato.fecha).local().format('DD-MM-YYYY HH:mm:ss'),
      keepAlive: dato.keepAlive ? 'Activo' : 'Inactivo',
      litrosRodeo: Number(dato.litrosRodeo) || 0,
      litrosTotal: Number(dato.litrosTotal) || 0,
    };
  });
}

// Paso el arreglo de objetos alimento a un objeto {alimento: materiaseca, alimento2: materiaseca2, ...}
// obtengo los totales de kgms/100 y kgtc/100 la receta
// recorro el array de alimentos de la receta para devolver los valores de las columnas
function parseDataReceta(data = [], stock = []) {
  const alimentosObject = stock.reduce(
    (obj, item) => ((obj[item.ingrediente] = item.porcentajeMateriaSeca), obj),
    {},
  );

  // Filtrar alimentos nuevos que no se hayan guardado y se hayan eliminado,
  // o sea que en el medio de la edicion de la receta se saco el alimento nuevo
  const dataFiltrada = data.filter(
    (alimento) => !(alimento.nuevo && alimento.deleted),
  );

  const { totalKgTC, totalKgMS } = dataFiltrada
    .filter((recetaAlimento) => !recetaAlimento.deleted)
    .reduce(
      function (accumulated, current, index) {
        const materiaSecaIngrediente = alimentosObject[current.nombre] || 0;
        const currentKgMS = (current.valor * materiaSecaIngrediente) / 10000;
        return {
          totalKgTC: accumulated.totalKgTC + current.valor / 100,
          totalKgMS: accumulated.totalKgMS + currentKgMS,
        };
      },
      { totalKgTC: 0, totalKgMS: 0 },
    );

  let totalPorcentajeMateriaSeca = 0,
    totalPorcentajeTC = 0,
    totalKgTC100 = 0;
  const tableData = dataFiltrada.map((elemento, index) => {
    const materiaSecaIngrediente = alimentosObject[elemento.nombre] || 0;
    const kgTC = redondearDosDecimales(elemento.valor / 100);
    const kgMS = redondearDosDecimales(
      (elemento.valor * materiaSecaIngrediente) / 10000,
    );
    const porcentajeTC =
      totalKgTC > 0 ? redondearDosDecimales(elemento.valor / totalKgTC) : 0;
    const porcentajeMateriaSeca =
      totalKgMS > 0 ? redondearDosDecimales((kgMS * 100) / totalKgMS) : 0;
    totalKgTC100 += elemento.valor;

    if (!elemento.deleted) {
      totalPorcentajeTC += porcentajeTC;
      totalPorcentajeMateriaSeca += porcentajeMateriaSeca;
    }

    return {
      ...elemento,
      kgTC,
      kgMS,
      porcentajeMateriaSeca,
      porcentajeTC,
      kgTC100: elemento.valor,
      materiaSecaIngrediente,
    };
  });

  return tableData.concat([
    {
      isTotal: true,
      nombre: 'Total',
      kgTC: redondearDosDecimales(totalKgTC),
      kgMS: redondearDosDecimales(totalKgMS),
      porcentajeMateriaSeca: redondearDosDecimales(totalPorcentajeMateriaSeca),
      porcentajeTC: redondearDosDecimales(totalPorcentajeTC),
      kgTC100: totalKgTC100,
      materiaSecaIngrediente: '',
    },
  ]);
}

function parseDataHistoricoDietas(data) {
  const {
    cantidadDeCabezas,
    aCargar,
    cargado,
    precioACargar,
    precioCargado,
  } = data.reduce(
    function (accumulated, current, index) {
      return {
        cantidadDeCabezas:
          accumulated.cantidadDeCabezas + current.cantidadDeCabezas,
        aCargar: accumulated.aCargar + current.aCargar,
        cargado: accumulated.cargado + current.cargado,
        precioACargar: accumulated.precioACargar + current.precioACargar,
        precioCargado: accumulated.precioCargado + current.precioCargado,
      };
    },
    {
      cantidadDeCabezas: 0,
      aCargar: 0,
      cargado: 0,
      precioACargar: 0,
      precioCargado: 0,
    },
  );

  return data
    .map((element) => ({
      ...element,
      fecha: moment.utc(element.fecha).local().format('DD-MM-YYYY HH:mm:ss'),
      eficiencia: redondearDosDecimales(element.eficiencia),
    }))
    .concat([
      {
        isTotal: true,
        fecha: 'Total',
        cantidadDeCabezas,
        aCargar,
        cargado,
        precioACargar,
        precioCargado,
      },
    ]);
}

function parseDataNUSNSUMHistorico(equipo, datos) {
  const { equipo: equipoTipo } = equipo || {};
  return datos.map((dato) => {
    const { Nivel = 0, MaxAbs = 100, MinAbs = 0 } = dato || {};
    const alturaTanque =
      equipoTipo === 'NSUM'
        ? Number(MaxAbs) - Number(MinAbs)
        : equipoTipo === 'NUS' || equipoTipo === 'N-RAD'
        ? Number(MinAbs) - Number(MaxAbs)
        : 0;
    return {
      ...dato,
      fecha: moment(dato.fecha).format('HH:mm DD-MM-YYYY'),
      nivelBomba:
        alturaTanque > -1
          ? Math.round((Number(Nivel) / alturaTanque) * 100)
          : 0,
    };
  });
}

function parseDataCITHHistorico(datos) {
  return datos.map((dato) => ({
    ...dato,
    fecha: moment(dato.fecha).format('HH:mm DD-MM-YYYY'),
    keepAlive: dato.keepAlive ? 'Activo' : 'Inactivo',
  }));
}

function parseDataAlfa01Historico(datos) {
  return datos
    .filter(
      (dato) =>
        dato.T1umr0 || dato.T2umr0 || dato.T3umr0 || dato.T4umr0 || dato.TAumr0,
    )
    .map((dato) => ({
      ...dato,
      fecha: moment(dato.fecha).format('HH:mm DD-MM-YYYY'),
      T1umr0: Number(dato.T1umr0),
      T2umr0: Number(dato.T2umr0),
      T3umr0: Number(dato.T3umr0),
      T4umr0: Number(dato.T4umr0),
      TAumr0: Number(dato.TAumr0),
    }));
}

function parseDataHomas(data = []) {
  const { ultimaData } = data
    .flat(1)
    .sort((element1, element2) => (element1.fecha > element2.fecha && -1) || 1)
    .reduce(
      function (accumulated, current, index) {
        const { ultimaData, ingredientes } = accumulated;
        if (!ingredientes.includes(current.ingrediente)) {
          ultimaData.push(current);
          ingredientes.push(current.ingrediente);
        }
        return {
          ultimaData,
          ingredientes,
        };
      },
      { ultimaData: [], ingredientes: [] },
    );
  return ultimaData;
}

function parseDataHomasHistorico(data = []) {
  return data
    .flat(1)
    .sort((element1, element2) => (element1.fecha > element2.fecha && -1) || 1)
    .map((element) => ({
      ...element,
      fecha: moment.utc(element.fecha).local().format('DD-MM-YYYY HH:mm:ss'),
    }));
}

function parseDataStockHistorico(data = []) {
  const dataFlattened = data
    .map((elemento) => {
      const { fecha, inventario = [] } = elemento || {};
      return inventario.map((stockRow) => {
        return {
          ...stockRow,
          fecha: moment.utc(fecha).local().format('DD-MM-YYYY HH:mm:ss'),
        };
      });
    })
    .flat()
    .filter((ingrediente) => Number(ingrediente.aCargar) > 0)
    .sort((element1, element2) => (element1.fecha > element2.fecha && -1) || 1);
  var helper = {};
  var result = dataFlattened.reduce(function (
    dataFlattenedFormatted,
    currentStock,
  ) {
    const { aCargar, precio, cargado, ingrediente } = currentStock || {};
    const totalPrecioTeorico = redondearDosDecimales(precio * aCargar);
    const totalPrecioCargados = redondearDosDecimales(precio * cargado);
    const diferenciaKg = redondearDosDecimales(cargado - aCargar);
    const diferenciaPrecio = redondearDosDecimales(
      totalPrecioCargados - totalPrecioTeorico,
    );
    const porcentajeError =
      aCargar > 0 ? redondearDosDecimales((diferenciaKg * 100) / aCargar) : 0;

    if (!helper[ingrediente]) {
      helper[ingrediente] = {
        ...currentStock,
        totalPrecioTeorico,
        totalPrecioCargados,
        diferenciaKg,
        diferenciaPrecio,
        porcentajeError,
      };
      dataFlattenedFormatted.push(helper[ingrediente]);
    } else {
      helper[ingrediente].aCargar += aCargar;
      helper[ingrediente].cargado += cargado;
      helper[ingrediente].totalPrecioTeorico += totalPrecioTeorico;
      helper[ingrediente].totalPrecioCargados += totalPrecioCargados;
      helper[ingrediente].diferenciaKg += diferenciaKg;
      helper[ingrediente].diferenciaPrecio += diferenciaPrecio;
      helper[ingrediente].porcentajeError += porcentajeError;

      if (precio !== helper[ingrediente].precio)
        helper[ingrediente].tieneMultiplesPrecios = true;
    }
    return dataFlattenedFormatted;
  },
  []);

  return result;
}

function redondearDosDecimales(numero) {
  return Math.round(Number(numero) * 100) / 100;
}
