import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useColumns } from 'hooks';
import Table from 'components/Table';
import { toggleAlimento } from 'redux/slices/recetas.slice';

function TablaAlimentos() {
  const dispatch = useDispatch();
  const { columns: columnsAlimentos } = useColumns('columnsTablaAlimentos');
  const { stock } = useSelector((state) => state.hook);
  const { recetaEditing } = useSelector((state) => state.recetas);
  const { ingredientes = [] } = recetaEditing || {};

  const stockOnlyInBalanza = stock.filter((ingrediente) => ingrediente.id);

  const recetaAlimentosNombres = useMemo(() => {
    return ingredientes
      .filter((alimento) => !alimento.deleted)
      .map((recetaAlimento) => recetaAlimento.nombre);
  }, [ingredientes]);

  const alimentosSelected = useMemo(
    () =>
      stockOnlyInBalanza.reduce(function (accumulated, current, index) {
        if (recetaAlimentosNombres.includes(current.ingrediente))
          accumulated.push(index);
        return accumulated;
      }, []),
    [stockOnlyInBalanza, recetaAlimentosNombres],
  );

  const onRowsSelect = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    if (currentRowsSelected.length === 0)
      alimentosSelected.forEach((currentRow) =>
        dispatch(toggleAlimento(stockOnlyInBalanza[currentRow])),
      );
    else
      currentRowsSelected.forEach((currentRow) =>
        dispatch(toggleAlimento(stockOnlyInBalanza[currentRow.dataIndex])),
      );
  };
  return (
    <Table
      title="Alimentos"
      columns={columnsAlimentos}
      data={stockOnlyInBalanza}
      options={{
        pagination: false,
        onRowsSelect,
        disableToolbarSelect: true,
        selectableRows: 'multiple',
        filter: false,
      }}
      rowsSelected={alimentosSelected}
    />
  );
}
export default TablaAlimentos;
