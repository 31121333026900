import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import 'styles/Home.css';
import 'styles/HomePage.css';
import 'styles/EquiposTabla.css';
import 'styles/UsuariosCard.css';
import 'styles/Helpers.css';
import LoaderComponent from '../components/LoaderComponent';
import { Equipos, Usuarios } from '../components/admin';
import ResponsiveDrawer from '../components/ResponsiveDrawer';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEquiposAsignados,
  getUsuariosAsociados,
  getAllStocksNames,
  addEquipoToUser,
  removeEquipoFromUser,
  updateAliasOfEquipo,
  addEquipo,
  removeEquipo,
  removeDatosEquipo,
  newUsuarioAsociado,
  deleteUser,
  createStockNap,
  addStockToNap,
  removeStockFromNap,
} from 'redux/slices/admin.slice';

function HomePageAdmin({ username, onLogout }) {
  const dispatch = useDispatch();
  const {
    isLoading,
    error,
    equipos,
    usuarios,
    stocks,
    shouldReload,
    setSimelToNap,
    removeSimelOfNap,
  } = useSelector((state) => state.admin);

  const refreshEquipos = () => {
    dispatch(getEquiposAsignados());
  };

  const refreshUsuarioAsociados = () => {
    dispatch(getUsuariosAsociados());
  };

  const refreshStocks = () => {
    dispatch(getAllStocksNames());
  };

  const onSaveAsociaciones = ({ usuariosAsociados, NSerie }) => {
    const oldUsuariosAsociados = usuarios
      ? usuarios
          .filter(
            (usuario) =>
              usuario.equipos.filter(
                (equipoAsignado) => equipoAsignado === NSerie,
              ).length > 0,
          )
          .map((usuario) => usuario.username)
      : [];

    const nuevosUsuariosAsociados = usuariosAsociados.filter(
      (usuario) => !oldUsuariosAsociados.includes(usuario),
    );
    nuevosUsuariosAsociados.map((username) =>
      dispatch(addEquipoToUser({ NSerie, username })),
    );

    const usuariosAsociadosABorrar = oldUsuariosAsociados.filter(
      (usuario) => !usuariosAsociados.includes(usuario),
    );
    usuariosAsociadosABorrar.map((username) =>
      dispatch(removeEquipoFromUser({ NSerie, username })),
    );
  };

  const onAsociarSimel = ({ NSerieSimel, NSerieNap }) => {
    dispatch(setSimelToNap({ NSerieSimel, NSerieNap }));
  };

  const onRemoveSimel = ({ NSerieNap }) => {
    dispatch(removeSimelOfNap({ NSerieNap }));
  };

  const onCambiarAliasEquipo = ({ alias, NSerie }) => {
    dispatch(updateAliasOfEquipo({ alias, NSerie }));
  };

  const onAddEquipo = ({ NSerie, equipo, ver }) => {
    dispatch(addEquipo({ NSerie, equipo, ver }));
  };

  const onRemoveEquipo = ({ NSerie }) => {
    dispatch(removeEquipo({ NSerie }));
  };

  const onClearDatosEquipo = ({ NSerie }) => {
    dispatch(removeDatosEquipo({ NSerie }));
  };

  const handleSignUp = ({ username, password }) => {
    dispatch(newUsuarioAsociado({ username, password }));
  };

  const handleDeleteUser = (username) => {
    dispatch(deleteUser({ username }));
  };

  const onCreateStock = ({ name }) => {
    dispatch(createStockNap({ name }));
  };

  const onAddStockToNap = ({ NSerie, stock }) => {
    dispatch(addStockToNap({ NSerie, stock }));
  };

  const onRemoveStockFromNap = ({ NSerie }) => {
    dispatch(removeStockFromNap({ NSerie }));
  };

  const reload = () => {
    refreshEquipos();
    refreshUsuarioAsociados();
    refreshStocks();
  };

  useEffect(() => {
    reload();
  }, []);

  useEffect(() => {
    if (shouldReload) {
      reload();
    }
  }, [shouldReload]);

  const menuItems = [
    [
      { label: 'Equipos', link: '/equipos' },
      { label: 'Usuarios', link: '/usuarios' },
    ],
  ];

  return (
    <Router>
      <Redirect exact from="/" to="equipos" />
      <div className="home-container homepage-container">
        <ResponsiveDrawer
          onLogout={onLogout}
          username={username}
          menuItems={menuItems}
        />
        <div className="main grid scrollable">
          <Switch>
            <Route path="/equipos">
              <Equipos
                equipos={equipos}
                usuarios={usuarios}
                stocks={stocks}
                onAddEquipo={onAddEquipo}
                onCambiarAliasEquipo={onCambiarAliasEquipo}
                onSaveAsociaciones={onSaveAsociaciones}
                onAsociarSimel={onAsociarSimel}
                onRemoveSimel={onRemoveSimel}
                onRemoveEquipo={onRemoveEquipo}
                onClearDatosEquipo={onClearDatosEquipo}
                onCreateStock={onCreateStock}
                onAddStockToNap={onAddStockToNap}
                onRemoveStockFromNap={onRemoveStockFromNap}
              />
            </Route>
            <Route path="/usuarios">
              <Usuarios
                usuarios={usuarios}
                handleSignUp={handleSignUp}
                handleDeleteUser={handleDeleteUser}
              />
            </Route>
          </Switch>
        </div>
        {isLoading && <LoaderComponent />}
      </div>
    </Router>
  );
}

export default HomePageAdmin;
