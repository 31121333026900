import React, { useMemo } from 'react';
import { parserFunctions } from 'helpers';
import Table from 'components/Table';
import { useColumns } from 'hooks';
import { useSelector } from 'react-redux';
import { ViewContainerVertical } from 'components/reusable';

function HomasHistorico() {
  const { homasHistorico: data } = useSelector((state) => state.hook);
  const { columns, onColumnsViewChange } = useColumns('columnsHomasHistorico');
  const dataHomasHistorico = useMemo(
    () => parserFunctions.parseDataHomasHistorico(data),
    [data],
  );

  return (
    <ViewContainerVertical>
      <Table
        title="HoMaS histórico"
        columns={columns}
        data={dataHomasHistorico}
        options={{ pagination: false, filter: true }}
        onColumnViewChange={onColumnsViewChange}
      />
    </ViewContainerVertical>
  );
}
export default HomasHistorico;
