import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { InputAdornment } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import DialogComponent from 'components/DialogComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
  editIngrediente,
  addTareaUpdateIngredienteNap,
  addHistoricChange,
} from 'redux/slices/stock.slice';
import {
  FormContainer,
  FormSelect,
  FormTextfield,
  ViewContainerVertical,
  FormEditingEnablingTextfield,
} from 'components/reusable';
import { useModal } from 'hooks';
import AlertDialog from 'components/AlertDialog';

function ModalEditarIngrediente({ isOpen, onClose = () => {} }) {
  const dispatch = useDispatch();
  const { stock } = useSelector((state) => state.hook);
  const { ingredienteEditing } = useSelector((state) => state.stock);
  const { equipoSelected } = useSelector((state) => state.hook);
  const {
    id,
    ingrediente,
    precio,
    existencia,
    ingreso,
    porcentajeMateriaSeca,
    nuevoNombre,
  } = ingredienteEditing || {};
  const [canEditNombre, setCanEditNombre] = useState(false);
  const [
    isOpenEliminarIngrediente,
    onOpenEliminarIngrediente,
    onCloseEliminarIngrediente,
  ] = useModal();

  useEffect(() => {
    if (isOpen) setCanEditNombre(false);
  }, [isOpen]);

  const ingredientes = React.useMemo(
    () =>
      stock
        ? stock
            .filter(
              (stockElement) =>
                stockElement.ingrediente &&
                stockElement.ingrediente !== '' &&
                stockElement.existencia !== '',
            )
            .map((stockElement) => stockElement.ingrediente)
        : [],
    [stock],
  );

  const ingredienteToSave = useMemo(
    () => ({
      ...ingredienteEditing,
      ingrediente:
        canEditNombre && nuevoNombre !== ingrediente
          ? nuevoNombre
          : ingrediente,
    }),
    [canEditNombre, ingrediente, ingredienteEditing, nuevoNombre],
  );

  const onSave = () => {
    const { stock: stockName } = equipoSelected || {};
    if (canEditNombre && nuevoNombre !== ingrediente)
      dispatch(
        addTareaUpdateIngredienteNap({
          NSerie: stockName,
          ingredienteToSave,
        }),
      );
    else
      dispatch(
        addHistoricChange({
          NSerie: stockName,
          ...ingredienteToSave,
        }),
      );
    handleClose();
  };

  const canGuardar = ingrediente !== '';
  const SELECT_OPTIONS_INGREDIENTE = useMemo(
    () =>
      ingredientes.map((ingrediente) => ({
        name: ingrediente,
        value: ingrediente,
      })),
    [ingredientes],
  );

  const onEliminarIngrediente = () => onClose();

  const handleClose = () => {
    onClose();
  };

  const selectIngrediente = useCallback(
    (ingredienteSelected) =>
      dispatch(
        editIngrediente({
          ...ingredienteSelected,
          nuevoNombre: ingredienteSelected.ingrediente,
        }),
      ),
    [dispatch],
  );

  useEffect(() => {
    if (stock.length > 0) {
      selectIngrediente(stock[0]);
    }
  }, [isOpen, selectIngrediente, stock]);

  const onSelectIngrediente = (event) => {
    const ingredienteSelected = stock.find(
      (ingrediente) => ingrediente.ingrediente === event.target.value,
    );
    if (ingredienteSelected) selectIngrediente(ingredienteSelected);
  };

  const onNuevoIngreso = (event) =>
    dispatch(
      editIngrediente({
        ...ingredienteEditing,
        ingreso: Number(event.target.value),
      }),
    );

  const onNuevoPrecio = (event) => {
    dispatch(
      editIngrediente({
        ...ingredienteEditing,
        precio: Number(event.target.value),
      }),
    );
  };
  const onNuevoPorcentajeMS = (event) =>
    dispatch(
      editIngrediente({
        ...ingredienteEditing,
        porcentajeMateriaSeca: Number(event.target.value),
      }),
    );

  const onNuevoNombre = (event) =>
    dispatch(
      editIngrediente({
        ...ingredienteEditing,
        nuevoNombre: event.target.value,
      }),
    );

  const toggleEditNombre = () => {
    if (canEditNombre)
      dispatch(
        editIngrediente({
          ...ingredienteEditing,
          nuevoNombre: ingredienteEditing.ingrediente,
        }),
      );
    setCanEditNombre(!canEditNombre);
  };

  return (
    <>
      <AlertDialog
        open={isOpenEliminarIngrediente}
        onClose={onCloseEliminarIngrediente}
        titulo="Eliminar Receta"
        contenido="¿Está seguro que desea eliminar el ingrediente? Esta acción no se puede deshacer."
        onPositiveAction={onEliminarIngrediente}
      />
      <DialogComponent
        open={isOpen}
        onClose={handleClose}
        titulo={'Editar ingrediente'}
        actions={[
          {
            autoFocus: false,
            onClick: onOpenEliminarIngrediente,
            text: 'Eliminar',
            variant: 'text',
            color: 'secondary',
          },
          {
            autoFocus: false,
            onClick: onSave,
            text: 'Guardar',
            disabled: !canGuardar,
            variant: 'contained',
          },
        ]}
        fullWidth={true}
      >
        <ViewContainerVertical>
          <FormSelect
            title="Ingrediente"
            selectOptions={SELECT_OPTIONS_INGREDIENTE}
            selectProps={{
              value: ingrediente,
              onChange: onSelectIngrediente,
            }}
          />
          {id && (
            <Alert severity="info">
              Ingrediente guardado en balanza, con ID {id ? id : '-'}.
            </Alert>
          )}
          <FormContainer>
            <FormTextfield
              texfieldProps={{
                label: 'Ingreso/ajuste existencia',
                value: ingreso || '',
                type: 'number',
                onChange: onNuevoIngreso,
                InputLabelProps: { shrink: true },
              }}
              helperText={`Existencia actual: ${existencia}`}
            />
            <FormTextfield
              texfieldProps={{
                label: 'Precio',
                value: precio || '',
                type: 'number',
                onChange: onNuevoPrecio,
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                },
                InputLabelProps: { shrink: true },
              }}
            />
            <FormTextfield
              texfieldProps={{
                label: '%MS',
                value: porcentajeMateriaSeca || '',
                type: 'number',
                onChange: onNuevoPorcentajeMS,
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                  inputProps: {
                    max: 100,
                    min: 0,
                  },
                },
                InputLabelProps: { shrink: true },
              }}
            />
            <FormEditingEnablingTextfield
              texfieldProps={{
                label: 'Nuevo nombre',
                value: nuevoNombre || '',
                onChange: onNuevoNombre,
                InputLabelProps: { shrink: true },
                disabled: !id || !canEditNombre,
                inputProps: { maxLength: 14 },
              }}
              editButtonProps={{
                onClick: toggleEditNombre,
                disabled: !id,
              }}
            />
          </FormContainer>
        </ViewContainerVertical>
      </DialogComponent>
    </>
  );
}

export default ModalEditarIngrediente;
