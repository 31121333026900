import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { medyconService } from 'services';
import { sanitizeResponseArray } from 'services/helpers';
import { errorMessage } from 'redux/slices';
import requestSlice, { dispatchRequest } from './request.slice';

export interface MedyconReducerState {
  equipos: any[];
  equipoSelected: any;
  fechaSelected: string;
  dataEquipoSelected: any[] | null;
  dataEquipos: any;
}

const initialState: MedyconReducerState = {
  equipos: [],
  equipoSelected: null,
  fechaSelected: moment().format('YYYY-MM-DD'),
  dataEquipoSelected: null,
  dataEquipos: null,
};

const medyconSlice = createSlice({
  name: 'medycon',
  initialState,
  reducers: {
    resetData(state) {
      state.dataEquipos = null;
    },
    setEquipoSelected(state, action: PayloadAction<any>) {
      state.equipoSelected = action.payload;
    },
    setFechaSelected(state, action: PayloadAction<string>) {
      state.fechaSelected = action.payload;
    },
    getEquiposAsignadosSuccess(state, action: PayloadAction<any[]>) {
      let newDataEquipos = {};
      action.payload.forEach((equipo) => {
        newDataEquipos[equipo.NSerie] = {
          equipo: equipo,
          datos: [],
        };
      });
      state.equipos = action.payload || [];
      state.dataEquipos = newDataEquipos;
      state.equipoSelected =
        action.payload && action.payload.length > 0 ? action.payload[0] : [];
    },
    getDataEquipoSuccess(state, action: PayloadAction<any[]>) {
      state.dataEquipoSelected = action.payload;
    },
    getDatosEquiposBeforeFechaLimitedSuccess(
      state,
      action: PayloadAction<any[]>,
    ) {
      let newDataEquipos = { ...state.dataEquipos };
      if (action.payload && action.payload.length > 0) {
        action.payload.forEach((dataEquipo: any) => {
          if (dataEquipo.length > 0 && dataEquipo[0].NSerie in newDataEquipos) {
            newDataEquipos[dataEquipo[0].NSerie] = {
              ...newDataEquipos[dataEquipo[0].NSerie],
              datos: dataEquipo,
            };
          }
        });
      }
      state.dataEquipos = newDataEquipos;
    },
  },
});

export default medyconSlice;

export const resetData = medyconSlice.actions.resetData;
export const setEquipoSelected = medyconSlice.actions.setEquipoSelected;
export const setFechaSelected = medyconSlice.actions.setFechaSelected;

const {
  getEquiposAsignadosSuccess,
  getDataEquipoSuccess,
  getDatosEquiposBeforeFechaLimitedSuccess,
} = medyconSlice.actions;

const { request, success, failure } = requestSlice.actions;

export const getEquiposAsignados = () => async (dispatch) => {
  dispatchRequest(dispatch, medyconService.getEquiposAsignados, undefined).then(
    (response) => {
      if (response)
        dispatch(getEquiposAsignadosSuccess(sanitizeResponseArray(response)));
    },
  );
};

export const getDataEquipo = ({
  NSerie,
  fecha,
}: {
  NSerie: string;
  fecha: string;
}) => async (dispatch) => {
  dispatch(request());
  return medyconService.getDataEquipo({ NSerie, fecha }).then(
    (response) => {
      dispatch(success());
      dispatch(getDataEquipoSuccess(sanitizeResponseArray(response)));
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const getDatosEquiposBeforeFechaLimited = ({
  NSeries,
  fecha,
}: {
  NSeries: string[];
  fecha: string;
}) => async (dispatch) => {
  dispatch(request());
  return medyconService
    .getDatosEquiposBeforeFechaLimited({ NSeries, fecha })
    .then(
      (response) => {
        dispatch(success());
        dispatch(
          getDatosEquiposBeforeFechaLimitedSuccess(
            sanitizeResponseArray(response),
          ),
        );
      },
      (error) => {
        dispatch(failure(errorMessage));
      },
    );
};
