import React, { useState } from 'react';
import 'styles/HomePage.css';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

function CustomMenu({ customMenuItems }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon className="hamburger-icon" />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {customMenuItems &&
          customMenuItems.length > 0 &&
          customMenuItems.map((customMenuItem, index) => (
            <MenuItem key={'menuitem' + index}>{customMenuItem}</MenuItem>
          ))}
      </Menu>
    </div>
  );
}

export default CustomMenu;
