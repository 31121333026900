import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ViewContainerVertical,
  ViewSubtitle,
  ViewSeparator,
} from 'components/reusable';
import DialogComponent from 'components/DialogComponent';
import TablaAlimentos from './tabla-alimentos';
import RecetaForm from './receta-form';
import TablaReceta from '../tabla-receta';
import { resetReceta, saveReceta } from 'redux/slices/recetas.slice';

function ModalAgregarReceta({ isOpen, onClose = () => {}, isNew }) {
  const dispatch = useDispatch();
  const { equipoSelected } = useSelector((state) => state.hook);
  const { NSerie = '' } = equipoSelected || {};
  const { recetaEditing } = useSelector((state) => state.recetas);
  const { id = '', nombre = '' } = recetaEditing || {};

  const canGuardar = id !== '' && nombre !== '';

  const handleClose = () => {
    dispatch(resetReceta());
    onClose();
  };

  const onSave = () => {
    dispatch(saveReceta({ NSerie, ...recetaEditing }));
    handleClose();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recetaNombre = useMemo(() => recetaEditing.nombre, [isOpen]); // deshabilitar warning porque es para que no se cambie el titulo del modal con cada cambio del nombre de la receta

  return (
    <DialogComponent
      open={isOpen}
      onClose={handleClose}
      titulo={isNew ? 'Nueva receta' : `Editar "${recetaNombre}"`}
      actions={[
        {
          autoFocus: false,
          onClick: handleClose,
          text: 'Cancelar',
          variant: 'text',
        },
        {
          autoFocus: false,
          onClick: onSave,
          text: 'Guardar',
          variant: 'contained',
          disabled: !canGuardar,
        },
      ]}
      fullWidth={true}
    >
      <ViewContainerVertical>
        <RecetaForm editingEnabled />
        <ViewSubtitle title={'Modificar ingredientes'} />
        <TablaAlimentos />
        <ViewSeparator />
        <ViewSubtitle title={'Modificar kg/cab'} />
        <TablaReceta receta={recetaEditing} editingEnabled />
      </ViewContainerVertical>
    </DialogComponent>
  );
}
export default ModalAgregarReceta;
