import React from 'react';
import { useDispatch } from 'react-redux';
import {
  CustomButton,
  Toolbar,
  ViewContainerVertical,
  ViewTitle,
} from 'components/reusable';
import { useModal } from 'hooks';
import ModalAgregarReceta from './modal-agregar-receta';
import CollapsableReceta from './collapsable-receta';
import { useSelector } from 'react-redux';
import TareasPendientesBanner from 'components/hook/tareas-pendientes-banner';
import { resetReceta } from 'redux/slices/recetas.slice';
import { Alert } from '@material-ui/lab';

function CreadorRecetas() {
  const dispatch = useDispatch();
  const { equipoSelected } = useSelector((state) => state.hook);
  const { recetas = [] } = equipoSelected || {};
  const [
    isOpenAgregarReceta,
    onOpenAgregarReceta,
    onCloseAgregarReceta,
  ] = useModal();

  const handleOpenAgregarReceta = () => {
    dispatch(resetReceta());
    onOpenAgregarReceta();
  };

  return (
    <>
      <ViewContainerVertical>
        <TareasPendientesBanner />
        <Toolbar spacedBetween>
          <ViewTitle title="Creador de Recetas" />
          <CustomButton
            title="Agregar"
            buttonProps={{ onClick: handleOpenAgregarReceta }}
          />
        </Toolbar>
        <ViewContainerVertical>
          {recetas.map((receta, index) => (
            <CollapsableReceta receta={receta} key={`receta${index}`} />
          ))}
          {recetas.length < 1 && (
            <Alert severity="info">
              No se encontraron recetas para este equipo.
            </Alert>
          )}
        </ViewContainerVertical>
      </ViewContainerVertical>
      <ModalAgregarReceta
        isOpen={isOpenAgregarReceta}
        onClose={onCloseAgregarReceta}
        isNew
      />
    </>
  );
}
export default CreadorRecetas;
