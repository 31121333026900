import React, { useState, useMemo } from 'react';
import { functionConstants } from 'helpers';
import { useSelector } from 'react-redux';
import {
  FormContainer,
  FormTextfield,
  ViewContainerVertical,
  ViewTitle,
} from 'components/reusable';

function SimelStats() {
  const { dataEquipo: data, simel, stock } = useSelector((state) => state.hook);
  const [costosVarios, setCostosVarios] = useState(400);
  const [isEditingCostosVarios, setIsEditingCostosVarios] = useState(true);
  const costoLitroLeche = useMemo(
    () =>
      data && simel && stock
        ? functionConstants.sumCostoLitroLeche({ data, simel, stock })
        : 0,
    [data, simel, stock],
  );

  const onCostosVariosChange = (event) => setCostosVarios(event.target.value);

  return (
    <ViewContainerVertical>
      <FormContainer>
        <FormTextfield
          texfieldProps={{
            id: 'input-costos-varios',
            label: 'Costos varios',
            value: costosVarios,
            onChange: onCostosVariosChange,
            type: 'number',
            disabled: !isEditingCostosVarios,
            required: true,
          }}
        />
      </FormContainer>
      <ViewTitle
        title={`Costo Producción x Litro de leche: ${costoLitroLeche}`}
      />
    </ViewContainerVertical>
  );
}

export default SimelStats;
