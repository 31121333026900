import React, { useMemo } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import 'styles/Medycon.css';
import {
  ViewBody,
  CardWithHeader,
  ViewContainerVertical,
  ContentInfo,
  CardDivider,
  ViewSubtitle,
} from 'components/reusable';

moment.locale('es');

function CardEquipoCITH({ equipoBundle, onVerMas }) {
  const { equipo: infoEquipo, datos = [] } = equipoBundle || {};
  const { equipo: equipoTipo, alias = '' } = infoEquipo || {};
  const ultimaCadena = datos.length > 0 ? datos[0] : undefined;
  const {
    fecha: ultimaCadenaFecha,
    ITH1 = 0,
    ITH2 = 0,
    Vent1 = false,
    Vent2 = false,
    humH1 = 0,
    humH2 = 0,
    tempT1 = 0,
    tempT2 = 0,
  } = ultimaCadena || {};

  const ultimoDatoRecibido = ultimaCadena
    ? `Último dato recibido ${moment(ultimaCadenaFecha).fromNow()}.`
    : `No se encontraron datos recientes del equipo.`;

  const datosWithAlarmas = useMemo(
    () => datos.filter((dato) => dato.alarmas && dato.alarmas.trim() !== ''),
    [datos],
  );
  const alarmasRecientes = [];
  datosWithAlarmas
    .map((dato) => dato.alarmas)
    .forEach((value, index, self) => {
      if (self.indexOf(value) === index)
        alarmasRecientes.push(datosWithAlarmas[index]);
    });

  return (
    <CardWithHeader
      title={`${alias ? alias + ' (' : ''}${equipoTipo}${alias ? ')' : ''}`}
      menuItems={[
        <span variant="body1" onClick={onVerMas}>
          Ver más
        </span>,
      ]}
    >
      <ViewContainerVertical>
        {ultimaCadena && (
          <>
            <ViewSubtitle title={'Sector 1'} style={{ margin: 0 }} />
            <ContentInfo
              contentInfoItems={[
                `Temperatura: ${tempT1}˚C`,
                `Humedad: ${humH1}%`,
                `ITH: ${ITH1}`,
                `Ventilador: ${Vent1 === 'ON' ? 'Encendido' : 'Apagado'}`,
              ]}
            />
            <CardDivider withMargin />
            <ViewSubtitle title={'Sector 2'} style={{ margin: 0 }} />
            <ContentInfo
              contentInfoItems={[
                `Temperatura: ${tempT2}˚C`,
                `Humedad: ${humH2}%`,
                `ITH: ${ITH2}`,
                `Ventilador: ${Vent2 === 'ON' ? 'Encendido' : 'Apagado'}`,
              ]}
            />
            <CardDivider withMargin />
            <ViewBody title={'Alarmas recientes'} />
            <ContentInfo
              contentInfoItems={
                alarmasRecientes.length > 0
                  ? alarmasRecientes.map(
                      (dato) =>
                        `${dato.alarmas.trim()} (${moment(
                          dato.fecha,
                        ).fromNow()})`,
                    )
                  : ['No se encontraron alarmas recientes para el equipo.']
              }
            />
          </>
        )}
        <CardDivider withMargin />
        <ViewBody title={ultimoDatoRecibido} />
      </ViewContainerVertical>
    </CardWithHeader>
  );
}

export default CardEquipoCITH;
