import React, { useState, useEffect, Fragment } from 'react';
import 'styles/Home.css';
import 'styles/HomePage.css';
import 'styles/EquiposTabla.css';
import 'styles/Historico.css';
import 'styles/Hook.css';
import 'styles/Helpers.css';
import { Typography, Checkbox, Chip } from '@material-ui/core';
import Table from './Table';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

function TablaSubsuma({
  title,
  data,
  columnas,
  attributesToSum,
  addExtraAttributesSubTotal = () => {
    return [];
  },
  newRow = () => {
    return {};
  },
  onToggleRow = () => {},
  customColumnCellRender,
  hideSubsuma,
  onColumnsViewChange = () => {},
  tableOptions,
}) {
  const [dataFormateada, setDataFormateada] = useState([]); // data con nuevas filas placeholders de subsumas
  const [dataFormateadaSumada, setDataFormateadaSumada] = useState([]); //data con las subsumas hechas
  const [columnasVisibles, setColumnasVisibles] = useState(columnas);

  useEffect(() => {
    setDataFormateadaSumada(
      refreshSuma(
        dataFormateada,
        newRow,
        attributesToSum,
        addExtraAttributesSubTotal,
      ),
    );
  }, [dataFormateada]);

  useEffect(() => {
    setDataFormateada([]);
    if (data && data.length > 0) {
      const dataConIndices = reformatConIndices(data);
      setDataFormateada(dataConIndices);
    }
  }, [data]);

  const onRowClick = (rowIndex) => {
    let newDataFormateada = [...dataFormateada];
    newDataFormateada[rowIndex].isSelected = !newDataFormateada[rowIndex]
      .isSelected;
    setDataFormateada(newDataFormateada);

    onToggleRow(newDataFormateada[rowIndex]);
  };

  const customRowRenderSubsumas = (tableRow, dataIndex, rowIndex) => {
    const tableRowFiltered = tableRow.slice(1).filter((value, indexValue) => {
      const columnDescription = columnasVisibles[indexValue + 1];
      const { options } = columnDescription || {};
      const { display = true } = options || {};
      return display == true;
    });
    const originalRow = dataFormateada[rowIndex];
    const originalFormateadaSumada = dataFormateadaSumada[rowIndex];
    const handleRowClick = () => onRowClick(rowIndex);
    const resultadoCell = (values, index) => {
      const column =
        Array.isArray(columnasVisibles) && columnasVisibles.length > index + 2
          ? columnasVisibles[index + 2]
          : undefined;
      const { label = '', options } = column || {};
      const { display = true } = options || {};
      return values && display ? (
        <td
          colSpan={1}
          key={index}
          className="custom-row-cell custom-row-cell-two-values custom-row-cell-centered-text"
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body2" noWrap>
              {label}
            </Typography>
            <Typography variant="body1" noWrap>
              <span className="custom-row-cell-two-values-text custom-row-cell-two-values-text-positive">
                <ArrowDropUpIcon />
              </span>
              <span className="custom-row-cell-two-values-text">
                {Math.round(Number(values[0]) * 100) / 100}
              </span>
              {values[1] !== 0 && (
                <Fragment>
                  <span className="custom-row-cell-two-values-text"> / </span>
                  <span className="custom-row-cell-two-values-text custom-row-cell-two-values-text-negative">
                    <ArrowDropDownIcon />
                  </span>
                  <span className="custom-row-cell-two-values-text">
                    {Math.abs(Math.round(Number(values[1]) * 100) / 100)}
                  </span>
                </Fragment>
              )}
            </Typography>
          </div>
        </td>
      ) : display ? (
        <td colSpan={1} key={index} className="custom-row-cell"></td>
      ) : null;
    };
    const {
      isResultado = false,
      isResultadoFinal = false,
      isSelected = false,
      isAccionManual = false,
      extra = [],
    } = originalFormateadaSumada || {};

    return isResultado ? (
      !hideSubsuma ? (
        <tr
          key={'rowIndex' + rowIndex}
          className={'custom-row custom-row-subsuma'}
        >
          <td colSpan={2} className="custom-row-cell">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1">Subtotal</Typography>
              {extra.map((extraData, indexExtraData) => {
                const { label = '', valor = '' } = extraData || {};
                return (
                  <Typography
                    variant="body2"
                    noWrap
                    key={'etra' + indexExtraData}
                  >
                    {' '}
                    {label}: {valor}
                  </Typography>
                );
              })}
            </div>
          </td>
          {tableRow
            .slice(2)
            .map((value, indexValue) => resultadoCell(value, indexValue))}
        </tr>
      ) : null
    ) : isResultadoFinal ? (
      <tr
        key={'rowIndex' + rowIndex}
        className={'custom-row custom-row-subsuma-final'}
      >
        <td colSpan={2} className="custom-row-cell">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1">Total</Typography>
            {extra.map((extraData, indexExtraData) => {
              const { label = '', valor = '' } = extraData || {};
              return (
                <Typography
                  variant="body2"
                  noWrap
                  key={'etra' + indexExtraData}
                >
                  {' '}
                  {label}: {valor}
                </Typography>
              );
            })}
          </div>
        </td>
        {tableRow
          .slice(2)
          .map((value, indexValue) => resultadoCell(value, indexValue))}
      </tr>
    ) : (
      <tr
        key={'rowIndex' + rowIndex}
        className={'custom-row'}
        onClick={handleRowClick}
      >
        <td colSpan={1} className="custom-row-cell">
          {isAccionManual ? (
            <Chip label="Manual" variant="outlined" color="primary" />
          ) : (
            <Checkbox
              value={dataFormateada.length}
              onChange={null}
              checked={isSelected}
              color="primary"
              inputProps={{
                'aria-label': 'primary checkbox',
              }}
            />
          )}
        </td>
        {tableRowFiltered.map((value, indexValue) => (
          <td
            colSpan={1}
            key={indexValue}
            className={
              'custom-row-cell' +
              (attributesToSum.includes(columnas[indexValue + 1].name)
                ? ' custom-row-cell-centered-text'
                : '')
            }
          >
            {customColumnCellRender ? (
              customColumnCellRender(indexValue + 1, value)
            ) : (
              <Typography variant="body1">{value}</Typography>
            )}
          </td>
        ))}
      </tr>
    );
  };

  function onColumnViewChange(newColumns) {
    setColumnasVisibles(newColumns);
    onColumnsViewChange(newColumns);
  }

  useEffect(() => {
    setColumnasVisibles(columnas);
  }, [columnas]);

  return (
    <Table
      title={title}
      columns={columnasVisibles}
      data={dataFormateadaSumada}
      onColumnViewChange={onColumnViewChange}
      options={{
        pagination: false,
        customRowRender: customRowRenderSubsumas,
        filter: false,
        ...tableOptions,
      }}
    />
  );
}

export default TablaSubsuma;

function reformatConIndices(data) {
  let dataFormateada = [];
  if (data.length > 0) {
    data.map((row, indexRow) => {
      const { isAccionManual = false, sumaStock = true } = row || {};
      let newRow = {
        ...row,
        isSelected: !isAccionManual && sumaStock,
        isResultado: false,
      };
      dataFormateada.push(newRow);
      if (row.finReceta === true) {
        // INDICE DE FIN DE SUBSUMA
        let newRowResultado = {
          isResultado: true,
        };
        dataFormateada.push(newRowResultado);
      }
    });
    let newRowResultadoFinal = {
      isResultadoFinal: true,
    };
    dataFormateada.push(newRowResultadoFinal);
  }
  return dataFormateada;
}

function applySubsuma(rows, attributes, getNewRow) {
  let newRowResultado = {
    ...getNewRow(),
  };
  attributes.map((attribute) => {
    let newAttributePositiveValue = 0;
    let newAttributeNegativeValue = 0;
    rows.map((row) => {
      if (row.isSelected) {
        if (Number(row[attribute]) >= 0)
          newAttributePositiveValue += Number(row[attribute]);
        else newAttributeNegativeValue += Number(row[attribute]);
      } else if (row.isResultado) {
        newAttributePositiveValue += Number(row[attribute][0]);
        newAttributeNegativeValue += Number(row[attribute][1]);
      }
    });
    newRowResultado[attribute] = [
      newAttributePositiveValue,
      newAttributeNegativeValue,
    ];
  });
  return newRowResultado;
}

function refreshSuma(data, getNewRow, attributes, addExtraAttributesSubTotal) {
  let dataSumada = [];
  let lastFinRecetaIndex = -1;
  let allRowsToSum = [];
  let rowsResultadosIntermedios = [];
  data.map((row, indexRow) => {
    let newRow = {
      ...row,
    };
    if (newRow.isResultado) {
      const rowsToSum = data
        .slice(lastFinRecetaIndex + 1, indexRow)
        .filter((row) => row.isSelected === true);
      lastFinRecetaIndex = indexRow;
      newRow = {
        ...newRow,
        ...applySubsuma(rowsToSum, attributes, getNewRow),
        extra: [...addExtraAttributesSubTotal(rowsToSum)],
      };
      rowsResultadosIntermedios.push(newRow);
      allRowsToSum = allRowsToSum.concat(rowsToSum);
    }
    if (newRow.isResultadoFinal) {
      const subsuma = applySubsuma(
        rowsResultadosIntermedios,
        attributes,
        getNewRow,
      );
      newRow = {
        ...newRow,
        ...subsuma,
        extra: [...addExtraAttributesSubTotal(allRowsToSum)],
      };
    }
    dataSumada.push(newRow);
  });
  return dataSumada;
}
