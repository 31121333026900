import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorMessage } from '.';
import { configService } from '../../services';

const initialState: {
  isLoading: boolean;
  error: null | string;
  columns: any;
} = {
  isLoading: false,
  error: null,
  columns: null,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    request(state) {
      state.isLoading = true;
      state.error = null;
    },
    success(state) {
      state.isLoading = false;
      state.error = null;
    },
    failure(state, action: PayloadAction<string | null>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getUserColumnsSuccess(state, action: PayloadAction<any>) {
      state.columns = action.payload;
    },
  },
});

export default configSlice;

const {
  request,
  success,
  failure,
  getUserColumnsSuccess,
} = configSlice.actions;

const dispatchRequest = async (
  dispatch: any,
  service: any,
  serviceParams: any,
) => {
  dispatch(request());
  return service(serviceParams).then(
    (response) => {
      dispatch(success());
      return response;
    },
    (error) => {
      dispatch(failure(errorMessage));
      return false;
    },
  );
};

export const getUserColumns = ({ username }: { username: string }) => async (
  dispatch,
) => {
  dispatchRequest(dispatch, configService.getUserColumns, { username }).then(
    (response) => {
      if (response) dispatch(getUserColumnsSuccess(response));
    },
  );
};

export const setUserColumns = ({
  username,
  columns,
}: {
  username: string;
  columns: string;
}) => async (dispatch) => {
  dispatchRequest(dispatch, configService.setUserColumns, {
    username,
    columns,
  }).then((response) => {
    dispatch(getUserColumnsSuccess(columns));
  });
};
