import React, { useMemo } from 'react';
import 'styles/Helpers.css';
import { Typography } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { parserFunctions, tableConstants } from '../../../helpers';
import TablaSubsuma from '../../TablaSubsuma';
import { useColumns } from '../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { toggleRowPesadas } from 'redux/slices/hook.slice';
import { ViewContainer } from 'components/reusable';

function Pesadas() {
  const dispatch = useDispatch();
  const { dataEquipo: data, equipoSelected, stock } = useSelector(
    (state) => state.hook,
  );
  const { columns, onColumnsViewChange } = useColumns('columnsPesadas');
  const dataPesadas = useMemo(
    () => parserFunctions.parseDataPesadas(data, stock),
    [data, stock],
  );

  const customColumnCellRender = (columnIndex, value) => {
    if (columns[columnIndex].name === 'diferenciaKg')
      return (
        <Typography variant="body1">
          {value < 0 && (
            <span className="custom-row-cell-two-values-text custom-row-cell-two-values-text-negative">
              <ArrowDropDownIcon />
            </span>
          )}
          {value > 0 && (
            <span className="custom-row-cell-two-values-text custom-row-cell-two-values-text-positive">
              <ArrowDropUpIcon />
            </span>
          )}
          {Math.abs(value)}
        </Typography>
      );
    else return <Typography variant="body1">{value}</Typography>;
  };

  function onToggleRowPesadas(row) {
    const { fechaOriginal } = row || {};
    const { NSerie } = equipoSelected || {};
    if (NSerie && fechaOriginal)
      dispatch(toggleRowPesadas({ NSerie, fecha: fechaOriginal }));
  }

  return (
    <ViewContainer>
      <TablaSubsuma
        title={'Pesadas'}
        data={dataPesadas}
        columnas={columns}
        attributesToSum={tableConstants.attributesPesadas}
        newRow={tableConstants.newRowPesadas}
        customColumnCellRender={customColumnCellRender}
        addExtraAttributesSubTotal={
          tableConstants.addExtraAttributesSubTotalDescarga
        }
        onToggleRow={onToggleRowPesadas}
        onColumnsViewChange={onColumnsViewChange}
      />
    </ViewContainer>
  );
}

export default Pesadas;
