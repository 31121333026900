import { useState, useEffect } from 'react';
import moment from 'moment'

export const useFilter = (cadenasInicial = []) => {
    const [cadenas, setCadenas] = useState(cadenasInicial)
    const [cadenasFiltradas, setCadenasFiltradas] = useState(cadenasInicial)
    const [rangoHorario, setRangoHorario] = useState([0, 24])

    function filterRangoHorario(newRangoHorario = [0, 24]) { setRangoHorario(newRangoHorario) }

    function filter() {
        const horaInicio = rangoHorario[0]
        const horaFin = rangoHorario[1]
        const newCadenasFiltradas = cadenas.filter(cadena => {
            const { fecha } = cadena || {}
            const fechaFormat = Number(moment.utc(fecha).local().format('HH'))
            return (fechaFormat >= horaInicio &&  fechaFormat < horaFin)
        })

        setCadenasFiltradas(newCadenasFiltradas)
    }

    useEffect(() => {
        filter()
    }, [cadenas, rangoHorario])

    return {
        cadenasFiltradas,
        setCadenas,
        filterRangoHorario
    }
};