import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { parserFunctions } from 'helpers';
import { Masonry } from 'masonic';
import { Alert } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { CardWithHeader, ContentInfo } from 'components/reusable';

moment.locale('es');

function Homas() {
  const { dataEquipoHomas: data } = useSelector((state) => state.hook);
  const dataHomas = useMemo(() => parserFunctions.parseDataHomas(data), [data]);

  const MasonryComponent = useCallback(
    () => (
      <Masonry
        items={dataHomas}
        render={MasonryCard}
        columnWidth={250}
        columnGutter={8}
        style={{
          outlineStyle: 'none',
          height: '100%',
          maxHeight: '100%',
          paddingBottom: '60px',
        }}
      />
    ),
    [dataHomas],
  );

  function MasonryCard({ data }) {
    return <HomasCardIngrediente ingrediente={data} />;
  }

  return dataHomas.length > 0 ? (
    <MasonryComponent />
  ) : (
    <Alert severity="info">No se encontraron datos para este equipo.</Alert>
  );
}
export default Homas;

function HomasCardIngrediente({ ingrediente }) {
  const {
    ingrediente: nombre,
    fecha,
    MS,
    tempSecado,
    tiempoSecado,
    porcentajeVal,
    numCiclos,
  } = ingrediente || {};

  const cardContentInfoItems = useMemo(
    () => [
      `Fecha: ${moment(fecha).format('DD/MM/YYYY')}`,
      `Hora: ${moment(fecha).format('HH:mm')}`,
      `%MS: ${MS}%`,
      `Temp. Secado: ${tempSecado}°`,
      `Tiempo Secado: ${tiempoSecado}min`,
      `%Val: ${porcentajeVal}%`,
      `N° Ciclos: ${numCiclos}`,
    ],
    [MS, fecha, numCiclos, porcentajeVal, tempSecado, tiempoSecado],
  );

  return (
    <CardWithHeader title={nombre}>
      <ContentInfo contentInfoItems={cardContentInfoItems} />
    </CardWithHeader>
  );
}
