import React, { useMemo, useCallback } from 'react';
import Table from '../../Table';
import { useColumns, useModal } from '../../../hooks';
import { useSelector } from 'react-redux';
import { CustomButton, ViewContainerVertical } from 'components/reusable';
import ModalEditarIngrediente from './modal-editar-ingrediente';
import TareasPendientesBanner from 'components/hook/tareas-pendientes-banner';

function Stock() {
  const { columns, onColumnsViewChange } = useColumns('columnsStock');
  const { stock } = useSelector((state) => state.hook);
  const [
    isOpenEditarIngrediente,
    onOpenEditarIngrediente,
    onCloseEditarIngrediente,
  ] = useModal();

  const ingredientes = useMemo(
    () =>
      stock
        ? stock
            .filter(
              (stockElement) =>
                stockElement.ingrediente &&
                stockElement.ingrediente !== '' &&
                stockElement.existencia !== '',
            )
            .map((stockElement) => stockElement.ingrediente)
        : [],
    [stock],
  );

  const customToolbar = useCallback(
    () => (
      <CustomButton
        title="Editar ingrediente"
        buttonProps={{
          onClick: onOpenEditarIngrediente,
          variant: 'outlined',
          disabled: !ingredientes || ingredientes.length === 0,
        }}
      />
    ),
    [ingredientes, onOpenEditarIngrediente],
  );

  return (
    <>
      <ModalEditarIngrediente
        isOpen={isOpenEditarIngrediente}
        onClose={onCloseEditarIngrediente}
      />
      <ViewContainerVertical>
        <TareasPendientesBanner />
        <Table
          title="Ingredientes"
          data={stock}
          columns={columns}
          onColumnViewChange={onColumnsViewChange}
          options={{
            print: false,
            download: false,
            filter: false,
            viewColumns: false,
            pagination: false,
            customToolbar,
          }}
        />
      </ViewContainerVertical>
    </>
  );
}

export default Stock;
