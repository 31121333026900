import { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MedyconContext, tableColumns } from '../helpers';
import { setUserColumns } from 'redux/slices/config.slice';

export const useColumns = (name = '') => {
  const { isHook } = useContext(MedyconContext);
  const dispatch = useDispatch();
  const { columns } = useSelector((state) => state.config);
  const { username } = useSelector((state) => state.login);
  const columnsToUse = useMemo(
    () =>
      columns != null && columns.hasOwnProperty(name)
        ? columns[name]
        : tableColumns[isHook ? 'hook' : 'medycon'][name],
    [columns, name, isHook],
  );

  const onColumnsViewChange = (columnsUpdated) => {
    let newColumns = {
      ...columns,
      [name]: columnsUpdated,
    };
    dispatch(
      setUserColumns({
        username,
        columns: newColumns,
      }),
    );
  };

  return {
    columns: columnsToUse,
    onColumnsViewChange,
  };
};
