import React, { useCallback, useEffect, useMemo } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom';
import 'styles/Home.css';
import 'styles/HomePage.css';
import 'styles/EquiposTabla.css';
import 'styles/Historico.css';
import 'styles/Hook.css';
import 'styles/Helpers.css';
import moment from 'moment';
import LoaderComponent from 'components/LoaderComponent';
import {
  Pesadas,
  Descargas,
  Mezclado,
  Stock,
  Ubicacion,
  Homas,
  HomasHistorico,
  Simel,
  CreadorRecetas,
  HistoricoDietas,
} from '../components/hook';
import { SelectEquipo, SelectFecha } from 'components/Selectors';
import { useDispatch, useSelector } from 'react-redux';
import ResponsiveDrawer from 'components/ResponsiveDrawer';
import StockHistorico from 'components/hook/stock-historico';
import {
  getEquiposAsignados,
  getDataEquipo,
  getStock,
  getSimel,
  setEquipoSelected,
  setFechaSelected,
  getTareas,
  getDataEquipoHomas,
  getHomasHistorico,
} from 'redux/slices/hook.slice';

function HomePageHook({ onLogout }) {
  const dispatch = useDispatch();
  let history = useHistory();
  const { isLoading } = useSelector((state) => state.request);
  const { equipos, equipoSelected, fechaSelected } = useSelector(
    (state) => state.hook,
  );
  const { equipo, simel: equipoSelectedSimel } = equipoSelected || {};
  const { username } = useSelector((state) => state.login);
  const menuItems = useMemo(() => {
    let items = [];
    if (equipo === 'NAP' || equipo === 'NUS' || equipo === 'NSUM') {
      items.push([
        { label: 'Pesadas', link: '/pesadas' },
        { label: 'Mezclado', link: '/mezclado' },
        { label: 'Descargas', link: '/descargas' },
        { label: 'Ingredientes', link: '/ingredientes' },
        { label: 'Stock histórico', link: '/stock-historico' },
        { label: 'Ubicacion', link: '/ubicacion' },
        { label: 'Creador de Recetas', link: '/creador-recetas' },
        { label: 'Histórico Dietas', link: '/historico-dietas' },
      ]);
      if (equipoSelectedSimel) items.push([{ label: 'SIMEL', link: '/simel' }]);
    } else if (equipo === 'HoMaS')
      items.push([
        { label: 'Homas', link: '/homas' },
        { label: 'Homas Histórico', link: '/homas-historico' },
      ]);
    return items;
  }, [equipo, equipoSelectedSimel]);

  const refreshData = useCallback(() => {
    if (equipoSelected) {
      const { NSerie = '', simel: simelName, stock, equipo } =
        equipoSelected || {};
      if (equipo === 'HoMaS') {
        dispatch(
          getHomasHistorico({
            NSerie,
            fecha: moment(fechaSelected).toISOString(),
          }),
        );
      } else
        dispatch(
          getDataEquipo({
            NSerie,
            fecha: moment(fechaSelected).toISOString(),
          }),
        );
      if (stock) dispatch(getStock({ stock }));
      if (simelName)
        dispatch(
          getSimel({
            NSerie: simelName,
            fecha: moment(fechaSelected).toISOString(),
          }),
        );
      if (NSerie) dispatch(getTareas({ NSerie }));
    }
  }, [dispatch, equipoSelected, fechaSelected]);

  useEffect(() => {
    if (equipoSelected && equipoSelected.equipo === 'HoMaS')
      dispatch(
        getDataEquipoHomas({
          NSerie: equipoSelected.NSerie,
          fecha: moment().toISOString(),
        }),
      );
  }, [dispatch, equipoSelected]);

  useEffect(() => {
    refreshData();
  }, [equipoSelected, fechaSelected, refreshData]);

  useEffect(() => {
    dispatch(getEquiposAsignados());
  }, [dispatch, username]);

  const onSelectEquipo = useCallback(
    (equipo) => dispatch(setEquipoSelected(equipo)),
    [dispatch],
  );
  const onSelectFecha = useCallback(
    (fecha) => dispatch(setFechaSelected(fecha)),
    [dispatch],
  );

  const customMenuItems = useMemo(() => {
    const items = [
      <SelectFecha onSelect={onSelectFecha} fechaSelected={fechaSelected} />,
    ];
    if (equipos.length > 0)
      items.push(
        <SelectEquipo
          onSelect={onSelectEquipo}
          equipos={equipos}
          equipoSelected={equipoSelected}
        />,
      );
    return items;
  }, [equipoSelected, equipos, fechaSelected, onSelectEquipo, onSelectFecha]);

  useEffect(() => {
    if (
      history &&
      equipo === 'HoMaS' &&
      history.location.pathname !== '/homas' &&
      history.location.pathname !== '/homas-historico'
    )
      history.push('/homas');
  }, [equipo, history]);

  return (
    <Router>
      <div className="home-container homepage-container">
        <ResponsiveDrawer
          onLogout={onLogout}
          username={username}
          menuItems={menuItems}
          selectorItems={customMenuItems}
        />
        <div className="main grid scrollable">
          <Switch>
            <Route path="/pesadas">
              <Pesadas />
            </Route>
            <Route path="/mezclado">
              <Mezclado />
            </Route>
            <Route path="/descargas">
              <Descargas />
            </Route>
            <Route path="/ingredientes">
              <Stock />
            </Route>
            <Route path="/stock-historico">
              <StockHistorico />
            </Route>
            <Route path="/ubicacion">
              <Ubicacion />
            </Route>
            <Route path="/creador-recetas">
              <CreadorRecetas />
            </Route>
            <Route path="/historico-dietas">
              <HistoricoDietas />
            </Route>
            <Route path="/simel">
              <Simel />
            </Route>
            <Route path="/homas">
              <Homas />
            </Route>
            <Route path="/homas-historico">
              <HomasHistorico />
            </Route>
            <Route path="/">
              <Redirect exact from="/" to="/pesadas" />
            </Route>
          </Switch>
        </div>
        {isLoading && <LoaderComponent />}
      </div>
    </Router>
  );
}

export default HomePageHook;
