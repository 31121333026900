import React, { Fragment, useMemo } from 'react';
import { parserFunctions, tableConstants } from '../../../../helpers';
import TablaSubsuma from '../../../TablaSubsuma';
import Table from '../../../Table';
import CollapsableItem from '../../../CollapsableItem.jsx';
import { Typography } from '@material-ui/core';
import { useColumns } from '../../../../hooks';
import { useSelector } from 'react-redux';
import { ViewContainer } from 'components/reusable';
import { Bomba } from '../../../Bomba';

function SimelTables() {
  const { simel: data } = useSelector((state) => state.hook);
  const {
    columns: columnsSimelOrdenie,
    onColumnsViewChange: onColumnsViewChangeSimelOrdenie,
  } = useColumns('columnsSimelOrdenie');
  const {
    columns: columnsSimelDescargas,
    onColumnsViewChange: onColumnsViewChangeSimelDescargas,
  } = useColumns('columnsSimelDescargas');
  const {
    columns: columnsSimelHistorico,
    onColumnsViewChange: onColumnsViewChangeSimelHistorico,
  } = useColumns('columnsSimelHistorico');

  const dataSimelOrdenie = useMemo(
    () => parserFunctions.parseDataSimelOrdenie(data),
    [data],
  );
  const dataSimelDescargas = useMemo(
    () => parserFunctions.parseDataSimelDescarga(data),
    [data],
  );
  const dataSimelHistorico = useMemo(
    () => parserFunctions.parseDataSimelHistorico(data),
    [data],
  );
  const ultimaCadena = useMemo(
    () =>
      data.length > 0
        ? data
            .slice()
            .sort(
              (element1, element2) =>
                (element1.fecha < element2.fecha && -1) || 1,
            )
            .slice(-1)[0]
        : undefined,
    [data],
  );

  const { estado, numRodeo, litrosTotal } = ultimaCadena || {};
  const procesoEnEjecucion =
    estado === 'Play'
      ? numRodeo === 'DescInd' ||
        numRodeo === 'Descarga' ||
        numRodeo === 'descAuto'
        ? 'Descarga'
        : 'Ordeñe'
      : 'Ninguno';
  const litrosNivel = parseInt(litrosTotal);
  const litrosNivelPorcentual = Math.round((litrosNivel / 8000) * 100);

  const customToolbar = () => null;

  return (
    <ViewContainer>
      {ultimaCadena ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginBottom: 20,
          }}
        >
          <div style={{ margin: 10, marginTop: 0, marginRight: 20 }}>
            <Bomba
              nivel={litrosNivel}
              nivelPorcentual={litrosNivelPorcentual}
              labelsOff
            />
          </div>
          <div
            style={{
              flexGrow: 1,
              flexDirection: 'column',
              borderLeft: '#d1d1d1 1px solid',
              paddingLeft: 10,
            }}
          >
            <Typography variant="h6">
              Litros actuales: {litrosNivel} ({litrosNivelPorcentual}%)
            </Typography>
            <Typography variant="h6">Proceso: {procesoEnEjecucion}</Typography>
          </div>
        </div>
      ) : null}
      {(dataSimelOrdenie.length > 0 || dataSimelDescargas.length > 0) && (
        <>
          <CollapsableItem
            title="SIMEL Ordeñe"
            defaultExpanded={true}
            disableContentPadding={true}
            bigMargin
          >
            <TablaSubsuma
              title=""
              data={dataSimelOrdenie}
              columnas={columnsSimelOrdenie}
              attributesToSum={tableConstants.attributesSimelOrdenie}
              newRow={tableConstants.newRowSimelOrdenie}
              hideSubsuma={true}
              onColumnsViewChange={onColumnsViewChangeSimelOrdenie}
            />
          </CollapsableItem>
          <CollapsableItem
            title="SIMEL Descargas"
            defaultExpanded={true}
            disableContentPadding={true}
            bigMargin
          >
            <TablaSubsuma
              title=""
              data={dataSimelDescargas}
              columnas={columnsSimelDescargas}
              attributesToSum={tableConstants.attributesSimelDescargas}
              newRow={tableConstants.newRowSimelDescargas}
              hideSubsuma={true}
              onColumnsViewChange={onColumnsViewChangeSimelDescargas}
            />
          </CollapsableItem>
        </>
      )}
      <CollapsableItem
        title="SIMEL datos históricos"
        defaultExpanded={false}
        disableContentPadding={true}
        bigMargin
      >
        <Table
          title=""
          data={dataSimelHistorico}
          columns={columnsSimelHistorico}
          options={{ pagination: false, customToolbar }}
          onColumnViewChange={onColumnsViewChangeSimelHistorico}
        />
      </CollapsableItem>
    </ViewContainer>
  );
}

export default SimelTables;
