import React, { useEffect, useCallback, useMemo } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom';
import 'styles/Home.css';
import 'styles/HomePage.css';
import 'styles/EquiposTabla.css';
import 'styles/Historico.css';
import 'styles/Hook.css';
import 'styles/Helpers.css';
import moment from 'moment';
import LoaderComponent from '../components/LoaderComponent';
import { Equipos, Historico, Alarmas } from 'components/medycon';
import { SelectEquipo, SelectFecha } from '../components/Selectors';
import { useDispatch, useSelector } from 'react-redux';
import ResponsiveDrawer from '../components/ResponsiveDrawer';
import {
  getEquiposAsignados,
  getDatosEquiposBeforeFechaLimited,
  getDataEquipo,
  setEquipoSelected,
  setFechaSelected,
} from 'redux/slices/medycon.slice';

function HomePageMedycon({ onLogout }) {
  const dispatch = useDispatch();
  const { location } = useHistory();
  const { pathname } = location || {};
  const { isLoading } = useSelector((state) => state.request);
  const { equipos, equipoSelected, fechaSelected } = useSelector(
    (state) => state.medycon,
  );
  const { equipo: equipoTipo, NSerie } = equipoSelected || {};
  const menuItems = useMemo(() => {
    let items = [
      [
        { label: 'Equipos', link: '/equipos' },
        { label: 'Alarmas', link: '/alarmas' },
      ],
    ];
    if (equipoTipo !== 'SAP01')
      items.push([
        {
          label: 'Datos históricos',
          link: '/historico',
        },
      ]);
    return items;
  }, [equipoTipo]);
  const NSeries = useMemo(() => equipos.map((equipo) => equipo.NSerie), [
    equipos,
  ]);

  useEffect(() => {
    if (NSeries.length > 0)
      dispatch(
        getDatosEquiposBeforeFechaLimited({
          NSeries,
          fecha: moment().toISOString(),
        }),
      );
  }, [NSeries, dispatch]);

  useEffect(() => {
    if ((NSerie && pathname === '/historico') || pathname === '/alarmas')
      dispatch(
        getDataEquipo({
          NSerie,
          fecha: moment(fechaSelected).toISOString(),
        }),
      );
  }, [NSerie, dispatch, fechaSelected, pathname]);

  useEffect(() => {
    dispatch(getEquiposAsignados());
  }, [dispatch]);

  const onSelectEquipo = useCallback(
    (equipo) => dispatch(setEquipoSelected(equipo)),
    [dispatch],
  );
  const onSelectFecha = useCallback(
    (fecha) => dispatch(setFechaSelected(fecha)),
    [dispatch],
  );

  const customMenuItems: any[] = useMemo(() => {
    const items: any[] = [];
    if (pathname === '/historico' || pathname === '/alarmas') {
      items.push(
        <SelectFecha onSelect={onSelectFecha} fechaSelected={fechaSelected} />,
      );
      if (equipos.length > 0 && equipoSelected && equipoSelected != null)
        items.push(
          <SelectEquipo
            onSelect={onSelectEquipo}
            equipos={equipos}
            equipoSelected={equipoSelected}
          />,
        );
    }
    return items;
  }, [
    equipoSelected,
    equipos,
    fechaSelected,
    onSelectEquipo,
    onSelectFecha,
    pathname,
  ]);

  return (
    <Router>
      <div className="home-container homepage-container">
        <ResponsiveDrawer
          onLogout={onLogout}
          menuItems={menuItems}
          selectorItems={customMenuItems}
        />
        <div className="main grid scrollable">
          <Switch>
            <Route path="/equipos">
              <Equipos />
            </Route>
            <Route path="/historico">
              <Historico />
            </Route>
            <Route path="/alarmas">
              <Alarmas />
            </Route>
            <Route path="/">
              <Redirect exact from="/" to="/equipos" />
            </Route>
          </Switch>
        </div>
        {isLoading && <LoaderComponent />}
      </div>
    </Router>
  );
}

export default HomePageMedycon;
