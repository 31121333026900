import React from 'react';
import { Chip, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';

const columnsPesadas = [
  {
    name: 'selected',
    label: 'Sumar',
    options: { filter: false, sort: false, viewColumns: false },
  },
  {
    name: 'fecha',
    label: 'Fecha',
    options: { filter: false, sort: false, viewColumns: false },
  },
  {
    name: 'ingredientes',
    label: 'Ingrediente',
    options: { filter: true, sort: false },
  },
  {
    name: 'kgTeorico',
    label: 'Kg Teorico',
    options: { filter: false, sort: false },
  },
  {
    name: 'kgCargados',
    label: 'Kg Cargados',
    options: { filter: false, sort: false },
  },
  {
    name: 'kgTeoricoMS',
    label: 'Kg Teorico MS',
    options: { filter: false, sort: false },
  },
  {
    name: 'kgCargadosMS',
    label: 'Kg Cargados MS',
    options: { filter: false, sort: false },
  },
  { name: 'dieta', label: 'Dieta', options: { filter: true, sort: false } },
  {
    name: 'diferenciaKg',
    label: 'Diferencia Kg',
    options: { filter: false, sort: false },
  },
  {
    name: 'precio',
    label: 'Precio ($)',
    options: { filter: false, sort: false },
  },
  {
    name: 'porcentajePrecio',
    label: 'Dif. en $',
    options: { filter: false, sort: false },
  },
  {
    name: 'porcentajeKg',
    label: '%Kg',
    options: { filter: false, sort: false },
  },
  {
    name: 'porcentajeMateriaSeca',
    label: '%MS',
    options: { filter: false, sort: false },
  },
];

const columnsMezclado = [
  { name: 'fecha', label: 'Fecha', options: { filter: false, sort: true } },
  {
    name: 'tiempoMezclado',
    label: 'Tiempo Mezclado',
    options: { filter: true, sort: true, filterType: 'multiselect' },
  },
  {
    name: 'faltoMezclar',
    label: 'Falto Mezclar',
    options: { filter: false, sort: true },
  },
  {
    name: 'tiempoNoMezclado',
    label: 'Tiempo NO Mezclado',
    options: { filter: false, sort: true },
  },
  {
    name: 'desplazamientoMezclado',
    label: 'Desplazamiento durante el mezclado',
    options: { filter: true, sort: true },
  },
  // { name: "coordenadasInicioMezclado", label: "Coordenadas Incio Mezclado", options: { filter: false, sort: true } },
  // { name: "coordenadasFinMezclado", label: "Coordenadas Fin Mezclado", options: { filter: false, sort: true} },
];

const columnsDescargas = [
  {
    name: 'selected',
    label: 'Sumar',
    options: { filter: false, sort: false, viewColumns: false },
  },
  {
    name: 'fecha',
    label: 'Fecha',
    options: { filter: false, sort: false, viewColumns: false },
  },
  { name: 'lote', label: 'Lote', options: { filter: true, sort: false } },
  {
    name: 'kgTeorico',
    label: 'Kg Teorico',
    options: { filter: false, sort: false },
  },
  {
    name: 'kgCargados',
    label: 'Kg Descargados',
    options: { filter: false, sort: false },
  },
  { name: 'dieta', label: 'Guía', options: { filter: true, sort: false } },
  {
    name: 'diferenciaKg',
    label: 'Diferencia Kg',
    options: { filter: false, sort: false },
  },
  {
    name: 'porcentajeKg',
    label: '%Kg',
    options: { filter: false, sort: false },
  },
];

const columnsStock = [
  {
    name: 'id',
    label: 'Balanza',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) =>
        value ? <CheckIcon style={{ color: '#4caf50' }} size="small" /> : null,
    },
  },
  {
    name: 'ingrediente',
    label: 'Ingrediente',
    options: { filter: false, sort: true, sortDirection: 'desc' },
  },
  {
    name: 'existencia',
    label: 'Existencias (kg)',
    options: { filter: false, sort: true },
  },
  {
    name: 'precio',
    label: 'Precio Kg',
    options: { filter: false, sort: true },
  },
  {
    name: 'porcentajeMateriaSeca',
    label: '%MS',
    options: { filter: false, sort: true },
  },
  // { name: "ingresoAjuste", label: "Ingreso/Ajuste", options: { filter: false, sort: true } },
];

const columnsStockHistorico = [
  // { name: "fecha", label: "Fecha", options: { filter: false, sort: false } },
  {
    name: 'ingrediente',
    label: 'Ingrediente',
    options: { filter: true, sort: true, sortDirection: 'desc' },
  },
  {
    name: 'aCargar',
    label: 'A cargar (kg)',
    options: { filter: false, sort: true },
  },
  {
    name: 'cargado',
    label: 'Cargado (kg)',
    options: { filter: false, sort: true },
  },
  {
    name: 'totalPrecioTeorico',
    label: 'Total $ a Cargar',
    options: { filter: false, sort: true },
  },
  {
    name: 'totalPrecioCargados',
    label: 'Total $ Cargado',
    options: { filter: false, sort: true },
  },
  {
    name: 'diferenciaKg',
    label: 'Diferencia Kg',
    options: { filter: false, sort: true },
  },
  {
    name: 'diferenciaPrecio',
    label: 'Diferencia $',
    options: { filter: false, sort: true },
  },
  {
    name: 'porcentajeError',
    label: '% Error',
    options: { filter: false, sort: true },
  },
  {
    name: 'precio',
    label: 'Precio Kg ($)',
    options: { filter: false, sort: true },
  },
  // { name: "ingresoAjuste", label: "Ingreso/Ajuste", options: { filter: false, sort: true } },
];

const columnsSimelOrdenie = [
  {
    name: 'selected',
    label: 'Sumar',
    options: { filter: false, sort: false, viewColumns: false },
  },
  {
    name: 'fecha',
    label: 'Fecha',
    options: { filter: false, sort: false, viewColumns: false },
  },
  { name: 'rodeo', label: 'Rodeo', options: { filter: true, sort: false } },
  { name: 'litros', label: 'Litros', options: { filter: false, sort: true } },
];

const columnsSimelDescargas = [
  {
    name: 'selected',
    label: 'Sumar',
    options: { filter: false, sort: false, viewColumns: false },
  },
  {
    name: 'fecha',
    label: 'Fecha',
    options: { filter: false, sort: false, viewColumns: false },
  },
  { name: 'rodeo', label: 'Rodeo', options: { filter: true, sort: false } },
  { name: 'litros', label: 'Litros', options: { filter: false, sort: true } },
];

const columnsSimelHistorico = [
  { name: 'fecha', label: 'Fecha', options: { filter: false, sort: false } },
  { name: 'estado', label: 'Estado', options: { filter: true, sort: false } },
  {
    name: 'keepAlive',
    label: 'Keep Alive',
    options: { filter: true, sort: false },
  },
  { name: 'numRodeo', label: 'Rodeo', options: { filter: true, sort: false } },
  {
    name: 'litrosRodeo',
    label: 'Litros',
    options: { filter: false, sort: false },
  },
  {
    name: 'litrosTotal',
    label: 'Litros Total',
    options: { filter: false, sort: false },
  },
  {
    name: 'NSerie',
    label: 'Nro Serie',
    options: { filter: false, sort: false },
  },
  { name: 'equipo', label: 'Equipo', options: { filter: false, sort: false } },
  { name: 'ver', label: 'Versión', options: { filter: false, sort: false } },
];

const columnsHomasHistorico = [
  { name: 'fecha', label: 'Fecha', options: { filter: false, sort: false } },
  {
    name: 'ingrediente',
    label: 'Ingrediente',
    options: { filter: true, sort: false },
  },
  {
    name: 'MS',
    label: '%MS',
    options: { filter: false, sort: false },
  },
  {
    name: 'tempSecado',
    label: 'Temperatura secado (°C)',
    options: { filter: false, sort: false },
  },
  {
    name: 'tiempoSecado',
    label: 'Tiempo secado (min)',
    options: { filter: false, sort: false },
  },
  {
    name: 'porcentajeVal',
    label: '%Val',
    options: { filter: false, sort: false },
  },
  {
    name: 'numCiclos',
    label: 'N° Ciclos',
    options: { filter: false, sort: false },
  },
];

const columnsTablaReceta = [
  {
    name: 'nombre',
    label: 'Alimento',
    options: { filter: true, sort: false },
  },
  { name: 'kgTC', label: 'KgTC/Cab', options: { filter: false, sort: false } },
  { name: 'kgMS', label: 'KgMS/Cab', options: { filter: false, sort: false } },
  {
    name: 'porcentajeMateriaSeca',
    label: '%MS',
    options: { filter: false, sort: false },
  },
  {
    name: 'porcentajeTC',
    label: '%TC',
    options: { filter: false, sort: false },
  },
  {
    name: 'kgTC100',
    label: 'KgTC/100Cab',
    options: { filter: false, sort: false },
  },
  {
    name: 'materiaSecaIngrediente',
    label: 'MS',
    options: { filter: false, sort: false },
  },
];

const columnsHistoricoDietas = [
  { name: 'fecha', label: 'Fecha', options: { filter: false, sort: false } },
  {
    name: 'nombreReceta',
    label: 'Dieta',
    options: { filter: true, sort: true },
  },
  {
    name: 'eficiencia',
    label: 'Eficiencia %',
    options: { filter: false, sort: false },
  },
  {
    name: 'cantidadDeCabezas',
    label: 'Cabezas/Kg',
    options: { filter: false, sort: false },
  },
  {
    name: 'aCargar',
    label: 'Kg Teórico',
    options: { filter: false, sort: false },
  },
  {
    name: 'cargado',
    label: 'Kg Cargados',
    options: { filter: false, sort: false },
  },
  {
    name: 'precioACargar',
    label: '$ Dieta Teórica',
    options: { filter: false, sort: false },
  },
  {
    name: 'precioCargado',
    label: '$ Dieta Cargada',
    options: { filter: false, sort: false },
  },
  {
    name: 'numeroDeIngredientes',
    label: 'N° Ingredientes',
    options: { filter: false, sort: false },
  },
];

const columnsTablaAlimentos = [
  {
    name: 'ingrediente',
    label: 'Alimento',
    options: { filter: false, sort: false },
  },
  {
    name: 'porcentajeMateriaSeca',
    label: '%MS',
    options: { filter: false, sort: false },
  },
];

const nivelThresholdBomba = 20;
const columnsMedyconHistorico = [
  { name: 'fecha', label: 'Fecha', options: { filter: false, sort: false } },
  {
    name: 'nivelBomba',
    label: 'Nivel (%)',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <div style={{ display: 'flex' }}>
          {value < nivelThresholdBomba / 2 ? (
            <WarningIcon
              style={{ color: '#f44336', marginRight: 5 }}
              size="small"
            />
          ) : value < nivelThresholdBomba ? (
            <WarningIcon
              style={{ color: '#ffc107', marginRight: 5 }}
              size="small"
            />
          ) : null}
          <Typography variant="body1" style={{ marginTop: 3 }}>
            {value}%
          </Typography>
        </div>
      ),
    },
  },
  {
    name: 'Nivel',
    label: 'Nivel (cm)',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => Number(value),
    },
  },
  {
    name: 'EstR1',
    label: 'Bomba 1',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) =>
        value === true ? (
          <Chip label="Prendida" variant="default" color="primary" />
        ) : (
          <Chip label="Apagada" variant="outlined" color="secondary" />
        ),
    },
  },
  {
    name: 'EstR2',
    label: 'Bomba 2',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) =>
        value === true ? (
          <Chip label="Prendida" variant="default" color="primary" />
        ) : (
          <Chip label="Apagada" variant="outlined" color="secondary" />
        ),
    },
  },
];

const columnsMedyconHistoricoCITH = [
  { name: 'fecha', label: 'Fecha', options: { filter: false, sort: false } },
  {
    name: 'tempT1',
    label: 'Temperatura 1 (˚C)',
    options: { filter: false, sort: false },
  },
  {
    name: 'humH1',
    label: 'Humedad 1 (%)',
    options: { filter: false, sort: false },
  },
  {
    name: 'ITH1',
    label: 'ITH1',
    options: { filter: false, sort: false },
  },
  {
    name: 'Vent1',
    label: 'Ventilador 1',
    options: { filter: false, sort: false },
  },
  {
    name: 'tempT2',
    label: 'Temperatura 2 (˚C)',
    options: { filter: false, sort: false },
  },
  {
    name: 'humH2',
    label: 'Humedad 2 (%)',
    options: { filter: false, sort: false },
  },
  {
    name: 'ITH2',
    label: 'ITH2',
    options: { filter: false, sort: false },
  },
  {
    name: 'Vent2',
    label: 'Ventilador 2',
    options: { filter: false, sort: false },
  },
  {
    name: 'keepAlive',
    label: 'Keep Alive',
    options: { filter: true, sort: false },
  },
];

const columnsMedyconHistoricoAlfa01 = [
  { name: 'fecha', label: 'Fecha', options: { filter: false, sort: false } },
  {
    name: 'TAumr0',
    label: 'Temperatura A (˚C)',
    options: { filter: false, sort: true },
  },
  {
    name: 'T1umr0',
    label: 'Temperatura 1 (˚C)',
    options: { filter: false, sort: true },
  },
  {
    name: 'T2umr0',
    label: 'Temperatura 2 (˚C)',
    options: { filter: false, sort: true },
  },
  {
    name: 'T3umr0',
    label: 'Temperatura 3 (˚C)',
    options: { filter: false, sort: true },
  },
  {
    name: 'T4umr0',
    label: 'Temperatura 4 (˚C)',
    options: { filter: false, sort: true },
  },
];

const columnsMedyconHistoricoAlarmas = [
  { name: 'fecha', label: 'Fecha', options: { filter: false, sort: false } },
  {
    name: 'Alarmas',
    label: 'Alarmas',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) =>
        value.map((alarma, index) => (
          <span key={`alarma${index}`}>
            {alarma}
            <br />
          </span>
        )),
    },
  },
];

const newRowPesadas = () => {
  return {
    fecha: '',
    ingredientes: '',
    kgTeorico: '',
    kgCargados: '',
    dieta: '',
    diferenciaKg: '',
    porcentajePrecio: '',
    porcentajeKg: '',
  };
};

const newRowDescargas = () => {
  return {
    fecha: '',
    lote: '',
    kgTeorico: '',
    kgCargados: '',
    dieta: '',
    diferenciaKg: '',
    porcentajeKg: '',
  };
};

const newRowSimelOrdenie = () => {
  return { fecha: '', rodeo: '', litros: '' };
};

const newRowSimelDescargas = () => {
  return { fecha: '', litros: '' };
};

const newRowReceta = () => {
  return {
    alimento: '',
    kmcab: '',
    porcentajeMateriaSeca: '',
    kgtc: '',
    porcentajeTC: '',
    materiaSeca: '',
  };
};

const attributesPesadas = [
  'kgTeorico',
  'kgCargados',
  'diferenciaKg',
  'porcentajePrecio',
];

const attributesDescargas = ['kgTeorico', 'kgCargados', 'diferenciaKg'];

const attributesSimelOrdenie = ['litros'];

const attributesSimelDescargas = ['litros'];

const attributesReceta = [
  'kmcab',
  'porcentajeMateriaSeca',
  'kgtc',
  'porcentajeTC',
  'materiaSeca',
];

function addExtraAttributesSubTotalDescarga(newRows) {
  let extraAttributes = [
    { label: 'Eficiencia carga', valor: 100 },
    { label: 'Total Dieta Téorica ($)', valor: 0 },
    { label: 'Total Dieta Cargada ($)', valor: 0 },
  ];
  if (Array.isArray(newRows) && newRows.length > 0) {
    let difKgPositiva = 0;
    let difKgNegativa = 0;
    let totalKgTeoricos = 0;
    let precioTotalTeorico = 0;
    let precioTotalCargado = 0;
    newRows.map((newRow) => {
      if (newRow.isSelected) {
        const { kgTeorico = 0, kgCargados = 0, diferenciaKg = 0, precio = 0 } =
          newRow || {};
        if (diferenciaKg > 0) difKgPositiva += diferenciaKg;
        else difKgNegativa += diferenciaKg;
        totalKgTeoricos += kgTeorico;
        precioTotalTeorico += kgTeorico * precio;
        precioTotalCargado += kgCargados * precio;
      }
    });
    const eficiencia =
      100 - ((difKgPositiva - difKgNegativa) * 100) / totalKgTeoricos;
    extraAttributes[0].valor = Math.round(Number(eficiencia) * 100) / 100 + '%';
    extraAttributes[1].valor =
      Math.round(Number(precioTotalTeorico) * 100) / 100;
    extraAttributes[2].valor =
      Math.round(Number(precioTotalCargado) * 100) / 100;
  }
  return extraAttributes;
}

function tableOptions() {
  return {
    filterType: 'checkbox',
    selectableRows: 'none',
    rowsPerPage: 20,
    responsive: 'scrollFullHeight',
    fixedHeaderOptions: { xAxis: false, yAxis: false },
    textLabels: {
      body: {
        noMatch: 'No se encontraron datos.',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Filas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todo',
        title: 'FILTROS',
        reset: 'RESETEAR',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/ocultar columnas',
      },
      selectedRows: {
        text: 'fila(s) seleccionadas',
        delete: 'Eliminar',
        deleteAria: 'Eliminar filas seleccionadas',
      },
    },
  };
}

export const tableConstants = {
  tableOptions,
  columnsDescargas,
  columnsMezclado,
  columnsPesadas,
  columnsSimelDescargas,
  columnsSimelOrdenie,
  columnsStock,
  columnsHomasHistorico,
  columnsStockHistorico,
  columnsTablaAlimentos,
  columnsTablaReceta,
  columnsHistoricoDietas,
  newRowPesadas,
  newRowDescargas,
  newRowSimelOrdenie,
  newRowSimelDescargas,
  newRowReceta,
  attributesPesadas,
  attributesDescargas,
  attributesSimelOrdenie,
  attributesSimelDescargas,
  attributesReceta,
  columnsSimelHistorico,
  columnsMedyconHistorico,
  columnsMedyconHistoricoCITH,
  columnsMedyconHistoricoAlfa01,
  columnsMedyconHistoricoAlarmas,

  addExtraAttributesSubTotalDescarga,
};

export const tableColumns = {
  hook: {
    columnsDescargas,
    columnsMezclado,
    columnsPesadas,
    columnsSimelDescargas,
    columnsSimelOrdenie,
    columnsSimelHistorico,
    columnsStock,
    columnsHomasHistorico,
    columnsStockHistorico,
    columnsTablaAlimentos,
    columnsTablaReceta,
    columnsHistoricoDietas,
  },
  medycon: {
    columnsMedyconHistorico,
    columnsMedyconHistoricoCITH,
    columnsMedyconHistoricoAlfa01,
    columnsMedyconHistoricoAlarmas,
  },
};
