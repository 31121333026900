import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

type AlertMessageType = {
  message: String;
  severity: 'error' | 'success' | 'info' | 'warning';
};

export function AlertMessage({
  message = '',
  severity = 'error',
}: AlertMessageType) {
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
}
