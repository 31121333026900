import { postToServer, getFromServer } from './helpers';

export const hookService = {
  getEquiposAsignados,
  getDataEquipo,
  getDataEquipoHomas,
  getDataEquipoHomasHistorico,
  getStock,
  saveStock,
  getStockHistorico,
  onToggleRowPesadas,
  associateSimelToNap,
  getSimel,
  saveConsumos,
  userWantToReceiveReports,
  setEmailToUser,
  setReportsPeriodNAP,
  getTareas,
};

async function getEquiposAsignados() {
  return await getFromServer('/api/v1/getEquiposByUsername');
}

async function getDataEquipo({ NSerie, fecha }) {
  return await getFromServer(
    '/api/v1/getDataOfEquipoByDay?NSerie=' + NSerie + '&fecha=' + fecha,
  );
}

async function getDataEquipoHomas({ NSerie, fecha }) {
  return await getFromServer(
    '/api/v1/getDatosEquiposBeforeFechaLimited?numerosSerieEquipo=' +
      NSerie +
      '&fecha=' +
      fecha,
  );
}

async function getDataEquipoHomasHistorico({ NSerie, fecha }) {
  return await getDataEquipo({ NSerie, fecha });
}

async function getStock({ stock }) {
  return await getFromServer('/api/v1/getStockOfEquipo?name=' + stock);
}

async function saveStock({ NSerie, stock }) {
  return await postToServer('/api/v1/updateStockNAP', { NSerie, stock });
}

async function getStockHistorico({ stock, fechaInicial, fechaFinal }) {
  return await getFromServer(
    '/api/v1/getNapStockReports?stock=' +
      stock +
      '&fechaInicial=' +
      fechaInicial +
      '&fechaFinal=' +
      fechaFinal,
  );
}

async function onToggleRowPesadas({ NSerie, fecha }) {
  return await postToServer('/api/v1/toggleSumaNAP', { NSerie, fecha });
}

async function associateSimelToNap({ NSerieNap, NSerieSimel }) {
  return await postToServer('/api/v1/setSimelToNap', {
    NSerieNap,
    NSerieSimel,
  });
}

async function getSimel({ NSerie, fecha }) {
  return await getFromServer(
    '/api/v1/getDataOfEquipoByDay?NSerie=' + NSerie + '&fecha=' + fecha,
  );
}

async function saveConsumos({ NSerie, precioCombustible, consumoPromedio }) {
  return await postToServer('/api/v1/updateDatosCombustibleEquipo', {
    NSerie,
    costoCombustibleLitro: precioCombustible,
    consumoPromedioKilometroLitro: consumoPromedio,
  });
}

async function userWantToReceiveReports({ state }) {
  return await postToServer('/api/v1/userWantToReceiveReports', { state });
}

async function setEmailToUser({ email }) {
  return await postToServer('/api/v1/setEmailToUser', { email });
}

async function setReportsPeriodNAP({ numberOfDays }) {
  return await postToServer('/api/v1/setReportsPeriodNAP', { numberOfDays });
}

async function getTareas({ NSerie }) {
  // return [{ tarea: '1' }];
  return await getFromServer('/api/v1/getTareas?NSerie=' + NSerie);
}
