import { postToServer, getFromServer } from './helpers'

export const adminService = {
    getEquiposAsignados,
    getUsuariosAsociados,
    newUsuarioAsociado,
    addEquipoToUser,
    removeEquipoFromUser,
    addEquipo,
    removeEquipo,
    removeDatosEquipo,
    deleteUser,
    setSimelToNap,
    removeSimelOfNap,
    updateAliasOfEquipo,
    createStockNap,
    addStockToNap,
    removeStockFromNap,
    getAllStocksNames
};

export async function getEquiposAsignados() {
     return await getFromServer('/api/v1/equipos');
}

export async function getUsuariosAsociados() {
   return await getFromServer('/api/v1/users');
}

export async function newUsuarioAsociado({ username, password }) {
    return await postToServer('/api/v1/register', { username, password });
}

export async function addEquipoToUser({ NSerie, username }) {
    return await postToServer('/api/v1/addEquipoToUser', { NSerie, username });
}

export async function removeEquipoFromUser({ NSerie, username }) {
    return await postToServer('/api/v1/removeEquipoFromUser', { NSerie, username });
}

export async function addEquipo({ NSerie, equipo, ver }){
    return await postToServer('/api/v1/createEquipo', { NSerie, equipo, ver });    
}

export async function removeEquipo({ NSerie }) {
    return await postToServer('/api/v1/removeEquipo', { NSerie });
}

export async function removeDatosEquipo({ NSerie }) {
    return await postToServer('/api/v1/removeDatosEquipo', { NSerie });
}

export async function deleteUser({  username }) {
    return await postToServer('/api/v1/deleteUser', { username });
}

export async function setSimelToNap({ NSerieSimel, NSerieNap }) {
    return await postToServer('/api/v1/setSimelToNap', { NSerieSimel, NSerieNap });
}

export async function removeSimelOfNap({ NSerieNap }) {
    return await postToServer('/api/v1/removeSimelOfNap', { NSerieNap });
}

export async function updateAliasOfEquipo({ alias, NSerie }) {
    return await postToServer('/api/v1/updateAliasOfEquipo', { alias, NSerie });
}

export async function createStockNap({ name }) {
    return await postToServer('/api/v1/createStockNap', { name });
}

export async function addStockToNap({ NSerie, stock }) {
    return await postToServer('/api/v1/addStockToNap', { NSerie, stock });
}

export async function removeStockFromNap({ NSerie }) {
    return await postToServer('/api/v1/removeStockFromNap', { NSerie });
}

export async function getAllStocksNames() {
    return await getFromServer('/api/v1/getAllStocksNames');
}