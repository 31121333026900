import React, { Fragment, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  ListItemText,
  CheckboxProps,
  makeStyles,
} from '@material-ui/core';
import 'styles/Helpers.css';
import 'styles/Hook.css';
import BottomInfo from './BottomInfoComponent';
import MapComponent from './MapComponent';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

function MapaUbicaciones({
  children,
  data,
  onSave = () => {},
}: {
  children: any;
  data: any;
  onSave: () => void;
}) {
  const classes = useStyles();
  const [ingredientesShown, setIngredientesShown] = useState<string[]>([]);
  const [lotesShown, setLotesShown] = useState<string[]>([]);
  const [showGeneral, setShowGeneral] = useState<boolean>(true);

  const {
    ubicacionesGeneral = [],
    ubicacionesLote = [],
    ubicacionesIngrediente = [],
    consumoPromedioKM = 0,
    costoCombustibleKM = 0,
    velocidadPromedio = 0,
  } = data || {};

  const {
    ubicacionesGeneralLinea,
    ubicacionesGeneralDistanciaTotal,
  } = React.useMemo(() => {
    let ubicacionesGeneralLinea: any[] = [],
      ubicacionesGeneralDistanciaTotal = 0;
    if (ubicacionesGeneral && Array.isArray(ubicacionesGeneral)) {
      let lastDesplazamientoCoordinates;
      ubicacionesGeneral.forEach((punto) => {
        const { lat = 0, lng = 0 } = lastDesplazamientoCoordinates || {};
        const { coordenadas } = punto || {};
        const { lat: puntoLat = 0, lng: puntoLng = 0 } = coordenadas || {};
        const distancia = lastDesplazamientoCoordinates
          ? distanciaCoordenadas(lat, lng, puntoLat, puntoLng)
          : 0;
        lastDesplazamientoCoordinates = coordenadas;
        ubicacionesGeneralDistanciaTotal += distancia;
        ubicacionesGeneralLinea.push(coordenadas);
      });
    }
    return { ubicacionesGeneralLinea, ubicacionesGeneralDistanciaTotal };
  }, [ubicacionesGeneral]);

  const ubicacionesGeneralPuntos = React.useMemo(() => {
    let ubicacionesGeneralPuntos: any[] = [],
      lastDesplazamientoCoordinates;
    if (ubicacionesGeneral && Array.isArray(ubicacionesGeneral)) {
      ubicacionesGeneral.forEach((punto, indexPunto) => {
        const distancia = lastDesplazamientoCoordinates
          ? distanciaCoordenadas(
              lastDesplazamientoCoordinates.lat,
              lastDesplazamientoCoordinates.lng,
              punto.coordenadas.lat,
              punto.coordenadas.lng,
            )
          : 0;
        // Dibujo si la distancia entre los puntos es mayor a 500 METROS
        const shouldDibujar =
          punto.coordenadas &&
          (!lastDesplazamientoCoordinates ||
            indexPunto === ubicacionesGeneral.length - 1 ||
            distancia > 0.5);
        if (shouldDibujar) {
          lastDesplazamientoCoordinates = punto.coordenadas;
          ubicacionesGeneralPuntos.push(punto);
        }
      });
    }
    return ubicacionesGeneralPuntos;
  }, [ubicacionesGeneral]);

  const INGREDIENTES = React.useMemo(
    () => ubicacionesIngrediente.map((evento) => evento.name).sort(),
    [ubicacionesIngrediente],
  );
  const ubicacionesIngredienteFiltered = React.useMemo(
    () =>
      ubicacionesIngrediente.filter((evento) =>
        ingredientesShown.includes(evento.name),
      ),
    [ingredientesShown, ubicacionesIngrediente],
  );

  const LOTES = React.useMemo(
    () =>
      ubicacionesLote
        .map((evento) => evento.name)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort(),
    [ubicacionesLote],
  );
  const ubicacionesLoteFiltered = React.useMemo(
    () => ubicacionesLote.filter((evento) => lotesShown.includes(evento.name)),
    [lotesShown, ubicacionesLote],
  );

  useEffect(() => setIngredientesShown(INGREDIENTES), [INGREDIENTES]);
  useEffect(() => setLotesShown(LOTES), [LOTES]);

  return (
    <Fragment>
      <div className="grid-mapa-container">
        <div style={{ height: '100%', width: '100%', position: 'relative' }}>
          <MapComponent
            ubicacionesGeneralLinea={ubicacionesGeneralLinea}
            ubicacionesGeneralPuntos={ubicacionesGeneralPuntos}
            ubicacionesLote={ubicacionesLoteFiltered}
            ubicacionesIngrediente={ubicacionesIngredienteFiltered}
            onMarkerSelect={() => null}
            showGeneral={showGeneral}
          />
        </div>
      </div>
      <div className="mapa-bottom-bar">
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <FormControlLabel
            control={
              <BlackCheckbox
                checked={showGeneral}
                onChange={() => setShowGeneral(!showGeneral)}
              />
            }
            label="Desplazamiento"
            className={classes.checkbox}
          />
          <FormControl className={classes.formControl}>
            <InputLabel>Ingredientes</InputLabel>
            <Select
              multiple
              value={ingredientesShown}
              onChange={(event) =>
                setIngredientesShown(event.target.value as string[])
              }
              input={<Input />}
              renderValue={(selected) => (selected as any[]).join(', ')}
              // MenuProps={MenuProps}
            >
              {INGREDIENTES.map((ingrediente) => (
                <MenuItem key={ingrediente} value={ingrediente}>
                  <Checkbox
                    checked={ingredientesShown.indexOf(ingrediente) > -1}
                  />
                  <ListItemText primary={ingrediente} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Lotes</InputLabel>
            <Select
              multiple
              value={lotesShown}
              onChange={(event) =>
                setLotesShown(event.target.value as string[])
              }
              input={<Input />}
              renderValue={(selected) => (selected as any[]).join(', ')}
              // MenuProps={MenuProps}
            >
              {LOTES.map((lote) => (
                <MenuItem key={lote} value={lote}>
                  <Checkbox checked={lotesShown.indexOf(lote) > -1} />
                  <ListItemText primary={lote} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ width: '100%', display: 'flex' }}>{children}</div>
      </div>
      <BottomInfo
        ubicacionesGeneralDistanciaTotal={ubicacionesGeneralDistanciaTotal}
        consumoPromedioKilometroLitro={consumoPromedioKM}
        costoCombustibleKilometro={costoCombustibleKM}
        velocidadPromedio={Math.round(Number(velocidadPromedio) * 100) / 100}
        onSave={onSave}
      />
    </Fragment>
  );
}

export default MapaUbicaciones;

//This function takes in latitude and longitude of two location and returns the distance between them (EN KM)
function distanciaCoordenadas(lat1, lon1, lat2, lon2) {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var lat1Rad = toRad(lat1);
  var lat2Rad = toRad(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(lat1Rad) *
      Math.cos(lat2Rad);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

// Converts numeric degrees to radians
function toRad(Value) {
  return (Value * Math.PI) / 180;
}

const BlackCheckbox = withStyles({
  root: {
    color: '#2b2b2b',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
