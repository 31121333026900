import React, { useMemo, SyntheticEvent, ChangeEvent } from 'react';
import 'styles/HomePage.css';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import moment from 'moment';
import { CallbackFunction } from 'redux/types/common.types';
import { EquipoMedyconInfo } from 'redux/types';
const hoy = moment().format('YYYY-MM-DD');

export function SelectEquipo({
  onSelect,
  equipos,
  equipoSelected,
}: {
  onSelect: CallbackFunction;
  equipos: EquipoMedyconInfo[];
  equipoSelected: EquipoMedyconInfo;
}) {
  const equiposSelectables = useMemo(
    () =>
      equipos.filter(
        (equipo: EquipoMedyconInfo) =>
          equipo.equipo === 'SAP01' ||
          equipo.equipo === 'NAP' ||
          equipo.equipo === 'NUS' ||
          equipo.equipo === 'NSUM' ||
          equipo.equipo === 'N-RAD' ||
          equipo.equipo === 'HoMaS' ||
          equipo.equipo === 'CITH' ||
          equipo.equipo === 'Alfa01',
      ),
    [equipos],
  );
  const onEquipoChanged = (event) => {
    const newEquipoSelected = equiposSelectables.find(
      (equipo) => equipo.NSerie === event.target.value,
    );
    onSelect(newEquipoSelected);
  };
  return (
    <FormControl className="topnav-selector">
      <InputLabel id="equipo" style={{ color: '#fff' }}>
        Equipo
      </InputLabel>
      <Select
        labelId="equipo"
        id="equipo-checkbox"
        value={equipoSelected.NSerie}
        onChange={onEquipoChanged}
        style={{
          color: '#fff',
          borderBottom: '1px solid rgba(255,255,255,0.5)',
        }}
      >
        {equiposSelectables.map((equipo, index) => (
          <MenuItem value={equipo.NSerie} key={'equipo' + index}>
            {equipo.alias
              ? `${equipo.alias} (${equipo.NSerie})`
              : `${equipo.NSerie}`}{' '}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function SelectFecha({
  onSelect,
  fechaSelected,
  color,
}: {
  onSelect: CallbackFunction;
  fechaSelected: string;
  color?: string;
}) {
  const onFechaChanged = (event: SyntheticEvent) => {
    let { value } = event.target as HTMLInputElement;
    onSelect(value);
  };
  return (
    <FormControl className="topnav-selector">
      <TextField
        id="date"
        label="Fecha"
        type="date"
        onChange={onFechaChanged}
        value={fechaSelected}
        InputLabelProps={{
          shrink: true,
          style: { color: color || '#fff' },
        }}
        inputProps={{
          max: hoy,
          style: {
            color: color || '#fff',
            borderBottom: '1px solid rgba(255,255,255,1)',
          },
        }}
      />
    </FormControl>
  );
}

export function SelectBetweenFechas({
  onSelectFechaInicio,
  onSelectFechaFin,
  fechaInicio,
  fechaFin,
  maxFechaFin,
  color,
}: {
  onSelectFechaInicio: CallbackFunction;
  onSelectFechaFin: CallbackFunction;
  fechaInicio: string;
  fechaFin: string;
  maxFechaFin?: string;
  color?: string;
}) {
  const onSelectFechaInicioWrapper = (event: SyntheticEvent) => {
    let { value } = event.target as HTMLInputElement;
    if (value <= fechaFin) onSelectFechaInicio(value);
  };
  const onSelectFechaFinWrapper = (event: SyntheticEvent) => {
    let { value } = event.target as HTMLInputElement;
    if (!maxFechaFin || value <= maxFechaFin) onSelectFechaFin(value);
    if (value < fechaInicio) onSelectFechaInicio(value);
  };
  return (
    <div
      style={{
        margin: '10px 0',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <TextField
        id="date"
        label="Fecha inicio"
        type="date"
        onChange={onSelectFechaInicioWrapper}
        value={fechaInicio}
        InputLabelProps={{
          shrink: true,
          style: { color: color || '#fff' },
        }}
        inputProps={{
          max: fechaFin,
          style: {
            color: color || '#fff',
            borderBottom: '1px solid rgba(255,255,255,1)',
          },
        }}
        style={{ marginRight: 8, flex: 1 }}
      />
      <TextField
        id="date"
        label="Fecha fin"
        type="date"
        onChange={onSelectFechaFinWrapper}
        value={fechaFin}
        InputLabelProps={{
          shrink: true,
          style: { color: color || '#fff' },
        }}
        inputProps={{
          max: hoy,
          style: {
            color: color || '#fff',
            borderBottom: '1px solid rgba(255,255,255,1)',
          },
        }}
        style={{ marginLeft: 8, flex: 1 }}
      />
    </div>
  );
}
