import React, { useMemo } from 'react';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { ViewTitle } from 'components/reusable';

const LineChartAlfa01 = ({ datosHistoricos = [] }) => {
  const nivelesLineChart = useMemo(
    () => ({
      labels: [...datosHistoricos].reverse().map((dato) => {
        return moment(dato.fecha, 'HH:mm DD-MM-YYYY').format('HH:mm');
      }),
      datasets: [
        {
          label: 'Temp Ambiente',
          data: [...datosHistoricos].reverse().map((dato) => dato.TAumr0),
          fill: false,
          borderColor: '#2196f3',
        },
        {
          label: 'Temp 1',
          data: [...datosHistoricos].reverse().map((dato) => dato.T1umr0),
          fill: false,
          borderColor: '#f44336',
        },
        {
          label: 'Temp 2',
          data: [...datosHistoricos].reverse().map((dato) => dato.T2umr0),
          fill: false,
          borderColor: '#ffc107',
        },
        {
          label: 'Temp 3',
          data: [...datosHistoricos].reverse().map((dato) => dato.T3umr0),
          fill: false,
          borderColor: '#3f51b5',
        },
        {
          label: 'Temp 4',
          data: [...datosHistoricos].reverse().map((dato) => dato.T4umr0),
          fill: false,
          borderColor: '#e91e63',
        },
      ],
    }),
    [datosHistoricos],
  );

  return (
    <>
      <ViewTitle title={'Temperaturas (˚C)'} />
      <Line data={nivelesLineChart} options={chartOptions} />
    </>
  );
};

export default LineChartAlfa01;

const chartOptions = {
  responsive: true,
  title: {
    display: false,
    text: 'Temperaturas',
  },
  tooltips: {
    mode: 'label',
  },
  hover: {
    mode: 'dataset',
  },
  legend: {
    display: true,
  },
  scales: {
    xAxes: [
      {
        display: true,
        offset: true,
        scaleLabel: {
          show: false,
          labelString: 'Fecha',
        },
        ticks: {
          maxTicksLimit: 5,
        },
      },
    ],
    yAxes: [
      {
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'T˚C',
        },
        ticks: {
          max: 50,
          min: 0,
          stepSize: 5,
        },
      },
    ],
  },
};
