import { makeStyles } from '@material-ui/core/styles';

export const formStyles = makeStyles(theme => ({
  textField: {
    alignSelf: 'stretch',
    height: '50px',
    lineHeight: '50px',
    padding: '5px 10px !important',
    marginTop: '8px !important',
    marginBottom: '8px !important',
  },
  input: {
    transform: 'translate(14px, 25px) scale(1) !important',
    "&.shrink": {
      transform: 'translate(23px, 0) scale(0.75)  !important',
    }
  },
  inputAutofill: {
   '&:-webkit-autofill':{
      backgroundColor: 'transparent !important',
      WebkitTransitionDelay: '9999999s'
    },
    '&:-internal-autofill-selected': {
      backgroundColor: 'transparent !important',
      WebkitTransitionDelay: '9999999s'
    }
  },
  loginButton: {
    backgroundColor: '#1b0088',
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  endAdornmentIcon: {
    padding: '12px',
    color: 'rgba(0, 0, 0, 0.54)'
  }
}));


export const markerConoStyles = makeStyles(theme => ({
  markerContainer: {
    width: '120px',
    height: '120px',
    position: 'relative'
  },
  marker: {
    position: 'absolute',
    top: '30px',
    left: '30px',
    width: '60px',
    height: '60px',
    backgroundColor: "#fff",
    borderRadius: '30px',
    border: '5px solid',
    borderColor: '#1b0088',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  markerImg: {
    width: '35px',
    height: 'auto',
    marginLeft: '2px'
  },
  markerCono: {
    position: 'absolute',
    bottom: '20px',
    left: '40.5px',
    width: '30px',
    height: '20px',
    boxSizing: 'border-box',
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderTop: '20px solid',
    borderTopColor: '#1b0088'
  },
  markerTopContainer: {
    position: 'absolute',
    top: '2px',
    left: '10px',
    width: '100px',
    height: '22px',
    backgroundColor: "rgba(255,255,255,0.9)",
    borderRadius: '3px',
    border: '1px solid rgba(0,0,0,0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // boxShadow:'0 0 2px 0 rgba(0,0,0,0.15)',
  },
  markerTopText: {
    fontSize: '15px',
    color: 'black'
  },
  markerPasajeroOrden: {
    position: 'absolute',
    top: '4px',
    right: '9px',
    fontSize: '18px',
    color: '#fff'
  },
  markerPasajeroNotificado: {
    position: 'absolute',
    bottom: '3px',
    right: '4px',
    fontSize: '18px',
    color: '#fff'
  },
  markerShadowContainer: {
    position: 'absolute',
    top: '95px',
    left: '20px',
    opacity: 0.1
  },
  markerContainerPolyline: {
    position: 'relative',
    width: '120px',
    height: '40px'
  },
  markerPolyline: {
    position: 'absolute',
    width: '10px',
    height: '10px',
    bottom: '0px',
    left: '55px',
    boxSizing: 'border-box',
    borderRadius: '50%',
    border: '2px solid',
    borderColor: '#fff',
    backgroundColor: '#2b2b2b',
    boxShadow: '0 0 5px 0 rgba(0,0,0,0.5)',
  },
}))

export const mapaStyles = makeStyles(theme => ({
  mapInfoWindow: {
    width: '200px',
    maxHeight: '75%',
    position: 'absolute',
    top: '20%',
    left: '5px',
    backgroundColor: 'rgba(255,255,255,0.9)',
    boxShadow: '0 0 5px 0 rgba(0,0,0,0.3)',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    borderRadius: '5px',
    overflowY: 'auto',
    transition: 'all 0.5s ease-in-out'
  },
  mapInfoWindowTitulo: {
    fontWeight: '500 !important'
  }
}))

export const colores = [
  // son los colores de material palette
  '#2196f3', //azul
  '#e91e63', //rosa
  '#00bcd4', //cyan
  '#673ab7', //violeta oscuro
  '#4caf50', //verde
  '#ffc107', //amber
  '#cddc39', //lima
  '#ff5722', //naranja oscuro
  '#9e9e9e', //gris
  '#f44336', //rojo,
  '#3f51b5', //indigo
  '#9c27b0', //violeta
  '#03a9f4', // celeste
  '#8bc34a', //verde claro
  '#009688', //teal
  '#ffeb3b', //amarillo
  '#795548', //marron
  '#ff9800', //naranja
  '#607d8b', //azul grisaceo,   
]