import React, { useEffect, useMemo, useState } from 'react';
import Table from 'components/Table';
import moment from 'moment';
import { useColumns } from 'hooks';
import { useSelector } from 'react-redux';
import { ViewContainerVertical } from 'components/reusable';
import { SelectBetweenFechas } from 'components/Selectors';
import { parserFunctions } from 'helpers';
import { getReportesRecetas } from 'redux/slices/recetas.slice';
import { useDispatch } from 'react-redux';

const nowMenosUnDia = moment()
  .subtract(1, 'day')
  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  .format('YYYY-MM-DD');

const now = moment()
  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  .format('YYYY-MM-DD');

function HistoricoDietas() {
  const dispatch = useDispatch();
  const { columns } = useColumns('columnsHistoricoDietas');
  const { equipoSelected } = useSelector((state) => state.hook);
  const { NSerie = '', stock: stockName } = equipoSelected || {};
  const { dietasHistorico } = useSelector((state) => state.recetas);
  const [fechaInicial, setFechaInicial] = useState(nowMenosUnDia);
  const [fechaFinal, setFechaFinal] = useState(now);
  const tableDataHistoricoDietas = useMemo(
    () => parserFunctions.parseDataHistoricoDietas(dietasHistorico), // TOOD
    [dietasHistorico],
  );

  useEffect(() => {
    if (stockName && fechaInicial && fechaFinal)
      dispatch(
        getReportesRecetas({
          NSerie,
          fechaInicial,
          fechaFinal,
        }),
      );
  }, [NSerie, dispatch, fechaFinal, fechaInicial, stockName]);

  return (
    <ViewContainerVertical>
      <SelectBetweenFechas
        fechaInicio={fechaInicial}
        fechaFin={fechaFinal}
        onSelectFechaInicio={setFechaInicial}
        onSelectFechaFin={setFechaFinal}
        color="#2b2b2b"
        maxFechaFin={now}
      />
      <Table
        title="Histórico Dietas"
        data={tableDataHistoricoDietas}
        columns={columns}
        options={{
          pagination: false,
          setRowProps: (row, dataIndex, rowIndex) =>
            dataIndex === tableDataHistoricoDietas.length - 1 && {
              style: {
                backgroundColor: 'rgba(255, 152, 0, 0.3)',
                borderLeft: '7px solid rgba(255, 152, 0, 1)',
              },
            },
        }}
      />
    </ViewContainerVertical>
  );
}

export default HistoricoDietas;
