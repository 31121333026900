import { getFromServer } from './helpers';

export const medyconService = {
  getEquiposAsignados,
  getDataEquipo,
  getDatosEquiposBeforeFechaLimited,
};

export async function getEquiposAsignados() {
  return await getFromServer('/api/v1/getEquiposByUsername');
}

export async function getDataEquipo({ NSerie, fecha }) {
  return await getFromServer(
    '/api/v1/getDataOfEquipoByDay?NSerie=' + NSerie + '&fecha=' + fecha,
  );
}

export async function getDatosEquiposBeforeFechaLimited({ NSeries, fecha }) {
  return await getFromServer(
    '/api/v1/getDatosEquiposBeforeFechaLimited?numerosSerieEquipo=' +
      NSeries.join(',') +
      '&fecha=' +
      fecha,
  );
}
