import React, { useState, useEffect, Fragment } from 'react';
import { Typography, Slider } from '@material-ui/core';
import { useFilter } from '../hooks/useFilter.hooks';

export default function FilterBar({ cadenas, setCadenasFiltradas }) {
  const [rangoHorario, setRangoHorario] = useState([0, 24]);
  const cadenasFiltradas = useFilter(cadenas);

  useEffect(() => {
    cadenasFiltradas.setCadenas(cadenas);
  }, [cadenasFiltradas, cadenas]);

  useEffect(() => {
    setCadenasFiltradas(cadenasFiltradas.cadenasFiltradas);
  }, [cadenasFiltradas.cadenasFiltradas, setCadenasFiltradas]);

  // Aplicar filtros de rango horario
  useEffect(() => {
    cadenasFiltradas.filterRangoHorario(rangoHorario);
  }, [cadenasFiltradas, rangoHorario]);

  function onSetRangoHorario(newValue) {
    if (newValue[0] !== newValue[1]) {
      setRangoHorario(newValue);
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '10px 0',
      }}
    >
      <RangoHorario onSetRangoHorario={onSetRangoHorario} />
    </div>
  );
}

function RangoHorario({ onSetRangoHorario }) {
  const [rangoHorario, setRangoHorario] = useState([0, 24]);

  const handleChangeHoraInicio = (event, newValue) => {
    if (newValue[0] !== newValue[1]) {
      setRangoHorario(newValue);
      onSetRangoHorario(newValue);
    }
  };

  function valuetext(value) {
    return `${value}:00`;
  }

  const marks = [
    {
      value: 3,
      label: '3:00',
    },
    {
      value: 9,
      label: '9:00',
    },
    {
      value: 15,
      label: '15:00',
    },
    {
      value: 21,
      label: '21:00',
    },
  ];

  return (
    <Fragment>
      <Typography variant="body1">Rango horario</Typography>
      <div style={{ padding: '0 10px' }}>
        <Slider
          value={rangoHorario}
          onChange={handleChangeHoraInicio}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
          valueLabelFormat={valuetext}
          step={1}
          marks={marks}
          min={0}
          max={24}
        />
      </div>
    </Fragment>
  );
}
