import { getFromServer, postToServer } from './helpers';

export const recetasService = {
  saveReceta: async (receta) =>
    await postToServer('/api/v1/addTareaUpdateRecetaNap', { ...receta }),

  addTareaRemoveRecetaNap: async ({ NSerie, id }) =>
    await postToServer('/api/v1/addTareaRemoveRecetaNap', { NSerie, id }),

  getHistoricBetweenDates: async ({ stock, fechaInicial, fechaFinal }) =>
    await getFromServer(
      '/api/v1/getHistoricBetweenDates?stock=' +
        stock +
        '&fechaInicial=' +
        fechaInicial +
        '&fechaFinal=' +
        fechaFinal,
    ),

  getReportesRecetas: async ({ NSerie, fechaInicial, fechaFinal }) =>
    await getFromServer(
      '/api/v1/getReportesRecetas?NSerie=' +
        NSerie +
        '&desde=' +
        fechaInicial +
        '&hasta=' +
        fechaFinal,
    ),
};
