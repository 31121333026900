import React from 'react';
import 'styles/Admin.css'

export function Bomba({ nivel, nivelPorcentual, nivelThreshold = 20, labelsOff = false }) {
    const backgroundColor = nivelPorcentual < nivelThreshold / 2 ? '#f44336' : nivelPorcentual < nivelThreshold ? "#ffc107" : "#1e3462"
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
           {!labelsOff && <div style={{ width: '100%', display: 'flex', marginBottom: 5 }}>
                <div className={"equipo-card-characteristic"}>Nivel: {nivel}cm ({nivelPorcentual}%)</div>
            </div>}
            <div style={{ width: '100px', height: "150px", borderRadius: 5, overflow: 'hidden', position: 'relative', display: 'flex', alignItems: 'flex-end', boxShadow: '0 0 5px 0 rgba(0,0,0,0.2)', marginRight: 5 }}>
                <div style={{ width: '100%', height: nivelPorcentual + '%', backgroundColor, opacity: 0.9 }} />
            </div>
        </div>
    )
}