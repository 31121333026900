import React from 'react';
import { useSelector } from 'react-redux';
import { tableConstants, parserMedyconFunctions } from '../../../helpers';
import Table from 'components/Table';
import { ViewContainerVertical } from 'components/reusable';
import moment from 'moment';
import { Alert } from '@material-ui/lab';

const Alarmas = () => {
  const { equipoSelected: equipo, dataEquipoSelected: data } = useSelector(
    (state) => state.medycon,
  );
  const { equipo: equipoTipo } = equipo || {};
  const datosHistoricos = React.useMemo(
    () =>
      data != null && data.length
        ? equipoTipo === 'SAP01' || equipoTipo === 'CITH'
          ? translateAlarmsSAP(
              data.filter((dato) => dato.alarmas && dato.alarmas.trim() !== ''),
            )
          : translateAlarmsN(
              parserMedyconFunctions
                .parseDataNUSNSUMHistorico(equipo, data)
                .filter((dato) => dato.Alarmas && dato.Alarmas.trim() !== ''),
            )
        : [],
    [data, equipo, equipoTipo],
  );

  return (
    <ViewContainerVertical>
      <Table
        title="Alarmas"
        data={datosHistoricos}
        columns={tableConstants.columnsMedyconHistoricoAlarmas}
        options={{ pagination: false, filter: false }}
      />
    </ViewContainerVertical>
  );
};

export default Alarmas;

const translation = (cadena) => {
  switch (cadena) {
    case 'aTempPastAs1':
      return 'No se pudo llegar a la temperatura seteada en el Aspersor 1 en el tiempo seteado, estando el equipo Pasteurizando';
    case 'aTempPastAs2':
      return 'No se pudo llegar a la temperatura seteada en el Aspersor 2 en el tiempo seteado, estando el equipo Pasteurizando';
    case 'aTempPastAs3':
      return 'No se pudo llegar a la temperatura seteada en el Aspersor 3 en el tiempo seteado, estando el equipo Pasteurizando';
    case 'aTempRegTa1':
      return 'No se pudo llegar a la temperatura seteada en el Tanque 1 en el tiempo seteado estando el equipo en régimen';
    case 'aTempRegTa2':
      return 'No se pudo llegar a la temperatura seteada en el Tanque 2 en el tiempo seteado estando el equipo en régimen';
    case 'aTempRegTa3':
      return 'No se pudo llegar a la temperatura seteada en el Tanque 3 en el tiempo seteado estando el equipo en régimen';
    case 'aETA1':
      return 'Falla Bomba Tanque 1';
    case 'aETA2':
      return 'Falla Bomba Tanque 2';
    case 'aETA3':
      return 'Falla Bomba Tanque 3';
    case 'aEAS1':
      return 'Falla Bomba Aspersor 1';
    case 'aEAS2':
      return 'Falla Bomba Aspersor 2';
    case 'aEAS3':
      return 'Falla Bomba Aspersor 3';
    case 'aEVV':
      return 'Falla Variador de Velocidad (Cinta Transportadora)';
    case 'aEEV1':
      return 'Falla Electroválcula 1';
    case 'aEEV2':
      return 'Falla Electroválcula 2';
    case 'aEEV3':
      return 'Falla Electroválcula 3';
    default:
      return cadena;
  }
};

function translateAlarmsSAP(cadenas) {
  return cadenas.map((cadena) => {
    const alarmasSplit = cadena.alarmas
      .trim()
      .split('-')
      .filter((alarma) => alarma !== '');
    return {
      ...cadena,
      fecha: moment(cadena.fecha).format('HH:mm DD-MM-YYYY'),
      Alarmas: alarmasSplit.map((alarma) => translation(alarma)), //.join(", ")
    };
  });
}

function translateAlarmsN(cadenas) {
  return cadenas.map((cadena) => {
    const alarmasSplit = cadena.Alarmas.trim()
      .split('-')
      .filter((alarma) => alarma !== '');
    return {
      ...cadena,
      Alarmas: alarmasSplit.map((alarma) => translation(alarma)), //.join(", ")
    };
  });
}
