import React, { useMemo } from 'react';
import { ViewSeparator } from 'components/reusable';
import { useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';

function TareasPendientesBanner() {
  const { tareas = [] } = useSelector((state) => state.hook);
  const hasTareasPendientes = useMemo(
    () => tareas && Array.isArray(tareas) && tareas.length > 0,
    [tareas],
  );

  return hasTareasPendientes ? (
    <>
      <Alert severity="warning">Hay tareas pendientes para este equipo.</Alert>
      <ViewSeparator />
    </>
  ) : null;
}
export default TareasPendientesBanner;
