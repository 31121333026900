import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorMessage } from '.';

const initialState: {
  isLoading: boolean;
  error: null | string;
} = {
  isLoading: false,
  error: null,
};

const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    request(state) {
      state.isLoading = true;
      state.error = null;
    },
    success(state) {
      state.isLoading = false;
      state.error = null;
    },
    failure(state, action: PayloadAction<string | null>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default requestSlice;

const { request, success, failure } = requestSlice.actions;

export const dispatchRequest = async (
  dispatch: any,
  service: any,
  serviceParams: any,
) => {
  dispatch(request());
  return service(serviceParams).then(
    (response) => {
      dispatch(success());
      return response;
    },
    (error) => {
      dispatch(failure(errorMessage));
      return false;
    },
  );
};
