import React, { useMemo } from 'react';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { ViewTitle } from 'components/reusable';

const LineChartN = ({ datosHistoricos = [] }) => {
  const nivelesLineChart = useMemo(
    () => ({
      labels: [...datosHistoricos].reverse().map((nivel) => {
        return moment(nivel.fecha, 'HH:mm DD-MM-YYYY').format('HH:mm');
      }),
      datasets: [
        {
          label: 'Nivel',
          data: [...datosHistoricos].reverse().map((nivel) => nivel.nivelBomba),
          fill: true,
          borderColor: '#3f51b5',
        },
      ],
    }),
    [datosHistoricos],
  );

  return (
    <>
      <ViewTitle title={'Nivel (%)'} />
      <Line data={nivelesLineChart} options={chartOptions} />
    </>
  );
};

export default LineChartN;

const chartOptions = {
  responsive: true,
  title: {
    display: false,
    text: 'Nivel de bomba',
  },
  tooltips: {
    mode: 'label',
  },
  hover: {
    mode: 'dataset',
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: true,
        offset: true,
        scaleLabel: {
          show: false,
          labelString: 'Fecha',
        },
        ticks: {
          maxTicksLimit: 5,
        },
      },
    ],
    yAxes: [
      {
        display: true,
        scaleLabel: {
          display: true,
          labelString: '%',
        },
        ticks: {
          max: 100,
          min: 0,
          stepSize: 25,
        },
      },
    ],
  },
};
