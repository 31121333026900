import React, { Fragment, useState, useEffect } from 'react';
import {
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
} from '@material-ui/core';
import { formStyles } from 'styles/Styles';
import 'styles/Helpers.css';

function BottomInfo({
  ubicacionesGeneralDistanciaTotal = 0,
  consumoPromedioKilometroLitro = 0,
  costoCombustibleKilometro = 0,
  velocidadPromedio = 0,
  onSave = () => {},
}: {
  ubicacionesGeneralDistanciaTotal?: number;
  consumoPromedioKilometroLitro?: number;
  costoCombustibleKilometro?: number;
  velocidadPromedio?: number;
  onSave: (newCosto: any) => void;
}) {
  const classes = formStyles();
  const [precioCombustible, setPrecioCombustible] = useState<string | number>(
    0,
  );
  const [consumoPromedio, setConsumoPromedio] = useState<string | number>(0);
  const [isEditing, setIsEditing] = useState(false);

  const distanciaTotal = Number(ubicacionesGeneralDistanciaTotal.toFixed(2));
  const costoCombustible = Number(
    distanciaTotal *
      Number(consumoPromedioKilometroLitro) *
      Number(costoCombustibleKilometro),
  ).toFixed(2);

  const onSaveCambios = () => {
    if (precioCombustible !== '' && consumoPromedio !== '')
      onSave({
        precioCombustible: Number(precioCombustible),
        consumoPromedio: Number(consumoPromedio),
      });
  };

  function cancelarCambios() {
    setIsEditing(false);
    setPrecioCombustible(costoCombustibleKilometro);
    setConsumoPromedio(consumoPromedioKilometroLitro);
  }

  useEffect(() => {
    setPrecioCombustible(costoCombustibleKilometro);
  }, [costoCombustibleKilometro]);

  useEffect(() => {
    setConsumoPromedio(consumoPromedioKilometroLitro);
  }, [consumoPromedioKilometroLitro]);

  return (
    <Fragment>
      <Typography variant="body1" style={{ margin: '5px 10px' }}>
        Distancia total: {distanciaTotal}km
      </Typography>
      <Typography variant="body1" style={{ margin: '5px 10px' }}>
        Costo combustible: ${costoCombustible}
      </Typography>
      <Typography variant="body1" style={{ margin: '5px 10px' }}>
        Velocidad promedio: {velocidadPromedio}km/h
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <FormControl
          className={classes.textField}
          variant="outlined"
          style={{ minWidth: '250px', margin: '0 10px' }}
        >
          <InputLabel
            htmlFor="input-valor"
            classes={{
              root: classes.input,
              shrink: 'shrink',
            }}
          >
            Precio combustible
          </InputLabel>
          <OutlinedInput
            id="input-valor"
            required
            label="Valor"
            type="number"
            value={precioCombustible}
            onChange={(event) => setPrecioCombustible(event.target.value)}
            startAdornment={
              <Typography variant="body1" style={{ marginRight: '5px' }}>
                $
              </Typography>
            }
            endAdornment={<Typography variant="body1">Litro</Typography>}
            labelWidth={150}
            disabled={!isEditing}
          />
        </FormControl>
        <FormControl
          className={classes.textField}
          variant="outlined"
          style={{ minWidth: '250px', margin: '0 10px' }}
        >
          <InputLabel
            htmlFor="input-consumo"
            classes={{
              root: classes.input,
              shrink: 'shrink',
            }}
          >
            Consumo promedio por km
          </InputLabel>
          <OutlinedInput
            id="input-consumo"
            required
            label="Valor"
            type="number"
            value={consumoPromedio}
            onChange={(event) => setConsumoPromedio(event.target.value)}
            endAdornment={<Typography variant="body1">Litros/km</Typography>}
            labelWidth={200}
            disabled={!isEditing}
          />
        </FormControl>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '5px 20px',
          }}
        >
          {isEditing ? (
            <Fragment>
              <Button
                onClick={cancelarCambios}
                color="secondary"
                style={{ marginRight: 5 }}
              >
                Cancelar
              </Button>
              <Button
                onClick={onSaveCambios}
                color="primary"
                variant={'contained'}
              >
                Guardar
              </Button>
            </Fragment>
          ) : (
            <Button
              onClick={() => setIsEditing(true)}
              color="primary"
              variant={'outlined'}
            >
              Editar
            </Button>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default BottomInfo;
