import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorMessage } from '.';
import { recetasService } from '../../services';
import { getEquiposAsignados } from 'redux/slices/hook.slice';
import requestSlice from './request.slice';
import { sanitizeResponseArray } from 'services/helpers';

type ingredienteType = {
  nombre: string;
  valor: number;
  deleted?: boolean;
  nuevo?: boolean;
};

type recetaType = {
  NSerie?: string;
  id?: any;
  tiempoMezcla?: any;
  tipoUnidad?: any;
  nombre?: string;
  ingredientes?: ingredienteType[];
};

const initialState: {
  recetaEditing: recetaType;
  dietasHistorico: any;
} = {
  recetaEditing: {},
  dietasHistorico: [],
};

const recetasSlice = createSlice({
  name: 'recetas',
  initialState,
  reducers: {
    resetReceta(state) {
      state.recetaEditing = {
        id: '',
        tiempoMezcla: 0,
        tipoUnidad: 1,
        nombre: '',
        ingredientes: [],
      };
    },
    editReceta(state, action: PayloadAction<any>) {
      state.recetaEditing = action.payload;
    },
    onFormChange(state, action: PayloadAction<recetaType>) {
      state.recetaEditing = action.payload;
    },
    editRecetaRow(
      state,
      action: PayloadAction<{ rowIndex: number; newValue: any }>,
    ) {
      const { rowIndex, newValue } = action.payload;
      if (
        state.recetaEditing.ingredientes &&
        state.recetaEditing.ingredientes.length > rowIndex
      ) {
        state.recetaEditing.ingredientes[rowIndex].valor = newValue * 100;
      }
    },
    toggleAlimento(state, action: PayloadAction<any>) {
      const { ingrediente: alimentoToToggle } = action.payload;
      if (!state.recetaEditing.ingredientes)
        state.recetaEditing.ingredientes = [];
      let alimentoFound = false;
      state.recetaEditing.ingredientes = state.recetaEditing.ingredientes.map(
        (alimento) => {
          if (alimento.nombre === alimentoToToggle) {
            alimentoFound = true;
            return { ...alimento, deleted: !alimento.deleted };
          } else return alimento;
        },
      );
      if (!alimentoFound)
        state.recetaEditing.ingredientes.push({
          nombre: alimentoToToggle,
          valor: 0,
          nuevo: true,
        });
    },
    getHistoricBetweenDatesSuccess(state, action: PayloadAction<any>) {
      state.dietasHistorico = action.payload;
    },
    getReportesRecetasSuccess(state, action: PayloadAction<any>) {
      state.dietasHistorico = action.payload;
    },
  },
});

export default recetasSlice;

export const resetReceta = recetasSlice.actions.resetReceta;
export const editReceta = recetasSlice.actions.editReceta;
export const onFormChange = recetasSlice.actions.onFormChange;
export const editRecetaRow = recetasSlice.actions.editRecetaRow;
export const toggleAlimento = recetasSlice.actions.toggleAlimento;

const { request, success, failure } = requestSlice.actions;
const {
  getHistoricBetweenDatesSuccess,
  getReportesRecetasSuccess,
} = recetasSlice.actions;

export const saveReceta = (receta: recetaType) => async (dispatch) => {
  dispatch(request());
  const recetaReadyToSave = {
    ...receta,
    ingredientes: receta.ingredientes
      ? receta.ingredientes
          .filter((ingrediente) => !ingrediente.deleted)
          .map((ingrediente) => ({
            nombre: ingrediente.nombre,
            valor: ingrediente.valor,
          }))
      : [],
  };
  return recetasService.saveReceta(recetaReadyToSave).then(
    (response) => {
      dispatch(editReceta({}));
      dispatch(success());
      dispatch(getEquiposAsignados());
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const eliminarReceta = (NSerie: string, receta: recetaType) => async (
  dispatch,
) => {
  dispatch(request());
  const { id } = receta || {};
  return recetasService.addTareaRemoveRecetaNap({ NSerie, id }).then(
    (response) => {
      dispatch(success());
      dispatch(getEquiposAsignados());
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const getHistoricBetweenDates = ({
  stock,
  fechaInicial,
  fechaFinal,
}: {
  stock: string;
  fechaInicial: string;
  fechaFinal: string;
}) => async (dispatch) => {
  dispatch(request());
  return recetasService
    .getHistoricBetweenDates({ stock, fechaInicial, fechaFinal })
    .then(
      (response) => {
        dispatch(success());
        dispatch(
          getHistoricBetweenDatesSuccess(sanitizeResponseArray(response)),
        );
      },
      (error) => {
        dispatch(failure(errorMessage));
      },
    );
};

export const getReportesRecetas = ({
  NSerie,
  fechaInicial,
  fechaFinal,
}: {
  NSerie: string;
  fechaInicial: string;
  fechaFinal: string;
}) => async (dispatch) => {
  dispatch(request());
  return recetasService
    .getReportesRecetas({ NSerie, fechaInicial, fechaFinal })
    .then(
      (response) => {
        dispatch(success());
        dispatch(getReportesRecetasSuccess(sanitizeResponseArray(response)));
      },
      (error) => {
        dispatch(failure(errorMessage));
      },
    );
};
