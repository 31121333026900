import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useColumns } from 'hooks';
import Table from 'components/Table';
import { parserFunctions } from 'helpers';
import { FormControlLabel, TextField } from '@material-ui/core';
import { editRecetaRow } from 'redux/slices/recetas.slice';

const TablaReceta = ({ receta, editingEnabled }) =>
  !editingEnabled ? (
    <TablaRecetaNoEditable receta={receta}  />
  ) : (
    <TablaRecetaEditable  />
  );

export default TablaReceta;

function TablaRecetaNoEditable({ receta }) {
  const { columns: columnsReceta } = useColumns('columnsTablaReceta');
  const { stock } = useSelector((state) => state.hook);
  const { ingredientes = [] } = receta || {};
  const tableData = useMemo(
    () => parserFunctions.parseDataReceta(ingredientes, stock),
    [stock, ingredientes],
  );

  return (
    <TablaRecetaBase
      data={tableData}
      columns={columnsReceta}
    />
  );
}

function TablaRecetaEditable() {
  const dispatch = useDispatch();
  const { columns: columnsReceta } = useColumns('columnsTablaReceta');
  const { stock } = useSelector((state) => state.hook);
  const { recetaEditing } = useSelector((state) => state.recetas);

  const { ingredientes = [] } = recetaEditing || {};
  const tableDataNewReceta = useMemo(
    () => parserFunctions.parseDataReceta(ingredientes, stock),
    [stock, ingredientes],
  );

  const onKgTCEdit = (tableMeta, newValue) => {
    dispatch(editRecetaRow({ rowIndex: tableMeta.rowIndex, newValue }));
  };

  columnsReceta[1].options['customBodyRender'] = (
    value,
    tableMeta,
    updateValue,
  ) => {
    return tableMeta.rowIndex < tableDataNewReceta.length - 1 ? (
      <FormControlLabel
        value={value}
        control={<TextField value={value || ''} type="number" />}
        onChange={(event) => {
          onKgTCEdit(tableMeta, Number(event.target.value));
          updateValue(event.target.value);
        }}
        disabled={tableDataNewReceta[tableMeta.rowIndex].deleted}
      />
    ) : (
      <p>{value}</p>
    );
  };

  return (
    <TablaRecetaBase
      data={tableDataNewReceta}
      columns={columnsReceta}
    />
  );
}

function TablaRecetaBase({ data, columns, tableOptions }) {
  return (
    <Table
      title={''}
      data={data}
      columns={columns}
      options={{
        pagination: false,
        setRowProps: (row, dataIndex, rowIndex) =>
          (dataIndex === data.length - 1 && {
            style: {
              backgroundColor: 'rgba(255, 152, 0, 0.3)',
              borderLeft: '7px solid rgba(255, 152, 0, 1)',
            },
          }) ||
          (data[dataIndex].deleted && {
            style: {
              backgroundColor: 'rgba(255, 0, 0, 0.3)',
              borderLeft: '7px solid rgba(255, 0, 0, 1)',
            },
          }),
      }}
    />
  );
}
