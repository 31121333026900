import requestSlice from './request.slice';
import loginSlice from './login.slice';
import hookSlice from './hook.slice';
import adminSlice from './admin.slice';
import configSlice from './config.slice';
import recetasSlice from './recetas.slice';
import stockSlice from './stock.slice';
import medyconSlice from './medycon.slice';

const reducer = {
  request: requestSlice.reducer,
  login: loginSlice.reducer,
  hook: hookSlice.reducer,
  admin: adminSlice.reducer,
  config: configSlice.reducer,
  recetas: recetasSlice.reducer,
  stock: stockSlice.reducer,
  medycon: medyconSlice.reducer,
};
export default reducer;

export const errorMessage = 'Hubo un problema al obtener datos del servidor.';
