import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import 'styles/Medycon.css';
import {
  ViewBody,
  CardWithHeader,
  ViewContainerVertical,
  ContentInfo,
  CardDivider,
  ViewSubtitle,
  Text,
} from 'components/reusable';

moment.locale('es');

function CardEquipoAlfa({ equipoBundle, onVerMas }) {
  const { equipo: infoEquipo, datos = [] } = equipoBundle || {};
  const { equipo: equipoTipo, alias = '' } = infoEquipo || {};
  const ultimaCadena = datos.length > 0 ? datos[0] : undefined;
  const ultimaCadenaConTemperatura =
    datos.length > 0
      ? datos.find(
          (dato) =>
            dato.T1umr0 ||
            dato.T2umr0 ||
            dato.T3umr0 ||
            dato.T4umr0 ||
            dato.TAumr0,
        )
      : undefined;
  const { fecha: ultimaCadenaFecha } = ultimaCadena || {};
  const { TAumr0 = 0, Bumr0 = 0 } = ultimaCadenaConTemperatura || {};

  const ultimoDatoRecibido = ultimaCadena
    ? `Último dato recibido ${moment(ultimaCadenaFecha).fromNow()}.`
    : `No se encontraron datos recientes del equipo.`;

  const datosWithAlarmas = useMemo(
    () => datos.filter((dato) => dato.Alarmas && dato.Alarmas.trim() !== ''),
    [datos],
  );
  const alarmasRecientes = [];
  datosWithAlarmas
    .map((dato) => dato.Alarmas)
    .forEach((value, index, self) => {
      if (self.indexOf(value) === index)
        alarmasRecientes.push(datosWithAlarmas[index]);
    });

  const TextTemperatura = useCallback(
    ({ temperatura = 0, index = 0 }) => {
      const diferencia = Number(temperatura) - Number(TAumr0);
      return (
        <Text>
          <span>
            Temperatura {index}: {Number(temperatura)}˚C
          </span>{' '}
          <span style={{ color: diferencia < 0 ? 'red' : 'green' }}>
            ({diferencia}˚C)
          </span>
        </Text>
      );
    },
    [TAumr0],
  );

  return (
    <CardWithHeader
      title={`${alias ? alias + ' (' : ''}${equipoTipo}${alias ? ')' : ''}`}
      menuItems={[
        <span variant="body1" onClick={onVerMas}>
          Ver más
        </span>,
      ]}
    >
      <ViewContainerVertical>
        {ultimaCadenaConTemperatura && (
          <>
            <ViewSubtitle title={'Sector 1'} style={{ margin: 0 }} />
            <ContentInfo>
              <ViewBody title={`Temperatura Ambiente: ${Number(TAumr0)}˚C`} />
              {[1, 2, 3, 4].map((temperaturaIndex, index) => (
                <TextTemperatura
                  key={`temperatura${index}`}
                  temperatura={
                    ultimaCadenaConTemperatura[`T${temperaturaIndex}umr0`]
                  }
                  index={temperaturaIndex}
                />
              ))}
            </ContentInfo>
            <CardDivider withMargin />
            <ViewBody title={`Bateria %: ${Number(Bumr0)}`} />
            <CardDivider withMargin />
            <ViewBody title={'Alarmas recientes'} />
            <ContentInfo
              contentInfoItems={
                alarmasRecientes.length > 0
                  ? alarmasRecientes.map(
                      (dato) =>
                        `${dato.Alarmas.trim()} (${moment(
                          dato.fecha,
                        ).fromNow()})`,
                    )
                  : ['No se encontraron alarmas recientes para el equipo.']
              }
            />
          </>
        )}
        <CardDivider withMargin />
        <ViewBody title={ultimoDatoRecibido} />
      </ViewContainerVertical>
    </CardWithHeader>
  );
}

export default CardEquipoAlfa;
