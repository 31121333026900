import { postToServer } from './helpers';

export const ingredientesService = {
  addHistoricChange,
  addTareaUpdateIngredienteNap,
};

async function addHistoricChange({
  stock,
  ingrediente,
  ingreso,
  precio,
  porcentajeMateriaSeca,
}) {
  return await postToServer('/api/v1/addHistoricChange', {
    stock,
    ingrediente,
    ingreso,
    precio,
    porcentajeMateriaSeca,
  });
}

async function addTareaUpdateIngredienteNap({ NSerie, id, ingrediente }) {
  return await postToServer('/api/v1/addTareaUpdateIngredienteNap', {
    NSerie,
    id,
    ingrediente,
  });
}
