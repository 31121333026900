import React, { useState } from 'react';
import { markerConoStyles } from 'styles/Styles';
import 'styles/Helpers.css';

const {
  MarkerWithLabel,
} = require('react-google-maps/lib/components/addons/MarkerWithLabel');
const IMG_SOMBRA = require('../../../../assets/medycon/marker-shadow.png');

const MarkerEvento = ({
  onClick,
  position,
  fecha,
  icon,
  borderColor,
}: {
  onClick?: () => void;
  position: any[]; // es LatLng
  fecha: string;
  icon: any;
  borderColor: string;
}) => {
  const classes = markerConoStyles();
  return (
    <MarkerWithLabel
      opacity={0}
      position={position}
      labelAnchor={new window.google.maps.Point(60, 100)}
      labelStyle={{ width: '120px', height: '120px' }}
      onClick={onClick}
    >
      <div className={classes.markerContainer}>
        <div
          className={classes.markerCono}
          style={{ borderTopColor: borderColor }}
        />
        <div className={classes.markerTopContainer}>
          <span className={classes.markerTopText}>{fecha}</span>
        </div>
        <div
          className={classes.marker}
          style={{
            backgroundColor: 'rgba(255,255,255,1)',
            overflow: 'hidden',
            borderColor: borderColor,
          }}
        >
          {/* <TimeToLeaveIcon fontSize='large' style={{ color: '#fff', }} /> */}
          <img
            src={icon}
            className={classes.markerImg}
            alt="marker-evento"
          ></img>
        </div>
        <div className={classes.markerShadowContainer}>
          <img src={IMG_SOMBRA} alt="marker-evento-sombra"></img>
        </div>
      </div>
    </MarkerWithLabel>
  );
};

const MarkerEventoPolyline = ({
  onClick,
  position,
  fecha,
  backgroundColor = '#2b2b2b',
}: {
  onClick?: () => void;
  position: any[]; // es LatLng
  fecha: string;
  backgroundColor?: string;
}) => {
  const classes = markerConoStyles();
  const [showText, setShowText] = useState(false);
  return (
    <MarkerWithLabel
      opacity={0}
      position={position}
      labelAnchor={new window.google.maps.Point(60, 35)}
      labelStyle={{ width: '120px', height: '40px' }}
      onClick={onClick}
      onMouseOver={() => setShowText(true)}
      onMouseOut={() => setShowText(false)}
    >
      <div className={classes.markerContainerPolyline}>
        {showText && (
          <div className={classes.markerTopContainer}>
            <span className={classes.markerTopText}>{fecha}</span>
          </div>
        )}
        <div
          className={classes.markerPolyline}
          style={{ backgroundColor: backgroundColor }}
        />
      </div>
    </MarkerWithLabel>
  );
};

export { MarkerEvento, MarkerEventoPolyline };
