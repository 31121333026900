import React from 'react';

export interface Icon {
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  style?: any;
}

export const IconSwitchOn: React.FC<Icon> = (props) => {
  return <Icon name="switch-on" {...props} />;
};

export const IconSwitchOff: React.FC<Icon> = (props) => {
  return <Icon name="switch-off" {...props} />;
};

export const IconTemperature: React.FC<Icon> = (props) => {
  return <Icon name="temperature" {...props} />;
};

export const GifCintaTransportadora: React.FC<Icon> = (props) => {
  return <Icon name="cinta-transportadora" {...props} />;
};

export const Icon: React.FC<Icon & { name: string }> = ({
  name = '',
  size = 'md',
  style = {},
}) => {
  const icon = requireIcon(name);

  const sizeStyle =
    size == 'xxs'
      ? styles.iconxxs
      : size == 'xs'
      ? styles.iconxs
      : size == 'sm'
      ? styles.iconsm
      : size == 'lg'
      ? styles.iconlg
      : size == 'xl'
      ? styles.iconxl
      : styles.iconmd;

  return icon ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sizeStyle,
        ...style,
      }}
    >
      <img
        src={icon}
        style={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
        alt="icon"
      />
    </div>
  ) : null;
};

function requireIcon(name: string) {
  try {
    switch (name) {
      case 'switch-on':
        return require('../assets/switch-on.png');
      case 'switch-off':
        return require('../assets/switch-off.png');
      case 'temperature':
        return require('../assets/temperature.png');
      case 'cinta-transportadora':
        return require('../assets/cinta-transportadora.gif');
      default:
        return null;
    }
  } catch (ex) {
    return null;
  }
}

const styles = {
  iconxxs: {
    height: 10,
    width: 10,
  },
  iconxs: {
    height: 15,
    width: 15,
  },
  iconsm: {
    height: 20,
    width: 20,
  },
  iconmd: {
    height: 30,
    width: 30,
  },
  iconlg: {
    height: 40,
    width: 40,
  },
  iconxl: {
    height: 50,
    width: 50,
  },
};
