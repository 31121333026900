import React, { Fragment, useState } from 'react';
import 'styles/Home.css';
import 'styles/HomePage.css';
import 'styles/EquiposTabla.css';
import 'styles/Historico.css';
import 'styles/Hook.css';
import 'styles/Helpers.css';
import { Masonry } from 'masonic'
import { Typography, Button, FormControl, OutlinedInput, InputLabel } from '@material-ui/core';
import CardEquipo from './CardEquipo'
import CardStock from './CardStock'
import AlertDialog from '../AlertDialog'

function Equipos({ equipos = [], stocks = [], usuarios, onSaveAsociaciones, onCambiarAliasEquipo, onAsociarSimel, onRemoveSimel, onAddEquipo, onRemoveEquipo, onClearDatosEquipo, onCreateStock, onAddStockToNap, onRemoveStockFromNap }) {
    const [openAddEquipo, setOpenAddEquipo] = useState(false)
    const [openAddStock, setOpenAddStock] = useState(false)

    function MasonryCard({ data }) {
        return (
            <CardEquipo equipo={data} usuarios={usuarios} stocks={stocks} onCambiarAliasEquipo={onCambiarAliasEquipo} onAsociarSimel={onAsociarSimel} onRemoveSimel={onRemoveSimel} onSaveAsociaciones={onSaveAsociaciones} onRemoveEquipo={onRemoveEquipo} onClearDatosEquipo={onClearDatosEquipo}
                onCreateStock={onCreateStock} onAddStockToNap={onAddStockToNap} onRemoveStockFromNap={onRemoveStockFromNap} />
        )
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <AgregarEquipo open={openAddEquipo} onClose={() => setOpenAddEquipo(false)} onAgregar={onAddEquipo} />
            <AgregarStock open={openAddStock} onClose={() => setOpenAddStock(false)} onAgregar={onCreateStock} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%', margin: '8px 0' }}>
                <Typography variant='h4' className='texto-title section-title' >Equipos</Typography>
                <div style={{ display: 'flex' }}>
                    <Button
                        onClick={() => setOpenAddEquipo(true)}
                        variant="contained"
                        color="primary"
                    >
                        Nuevo equipo
                </Button>
                    {/* <Button
                    onClick={() => setOpenAddStock(true)}
                    variant="contained"
                    color="primary"
                >
                    Nuevo stock
                </Button> */}
                </div>
            </div>
            {
                equipos.length > 0 && <Masonry items={equipos} render={MasonryCard} columnGutter={8} style={{
                    outlineStyle: 'none', // height: '100%', maxHeight: '100%',
                    paddingBottom: '60px'
                }} />
                || <div className="no-equipos-alert" style={{ height: '100%', maxHeight: '100%' }}  >No se encontraron equipos asociados.</div>
            }
        </div>
    )
}

export default Equipos;


function AgregarEquipo({ open, onClose, onAgregar }) {
    const [nSerie, setNSerie] = useState("")
    const [nombreEquipo, setNombreEquipo] = useState("")
    const [version, setVersion] = useState("")

    const handleAgregarEquipo = () => {
        onAgregar({ NSerie: nSerie, equipo: nombreEquipo, ver: version })
        setNSerie("")
        setNombreEquipo("")
        setVersion("")
        onClose()
    }

    return (
        <AlertDialog open={open} onClose={onClose}
            titulo="Agregar equipo"
            contenido={
                <Fragment>
                    <FormControl className='formcontrol-selector' variant="outlined" >
                        <InputLabel htmlFor="input-alias">Número de serie</InputLabel>
                        <OutlinedInput
                            id="input-alias"
                            required
                            type="text"
                            value={nSerie}
                            onChange={(event) => setNSerie(event.target.value)}
                            labelWidth={120}
                        />
                    </FormControl>
                    <div style={{ width: '100%', height: '8px' }} />
                    <FormControl className='formcontrol-selector' variant="outlined">
                        <InputLabel htmlFor="input-alias">Nombre</InputLabel>
                        <OutlinedInput
                            id="input-nombre"
                            required
                            type="text"
                            value={nombreEquipo}
                            onChange={(event) => setNombreEquipo(event.target.value)}
                            labelWidth={120}
                        />
                    </FormControl>
                    <div style={{ width: '100%', height: '8px' }} />
                    <FormControl className='formcontrol-selector' variant="outlined" >
                        <InputLabel htmlFor="input-alias">Versión</InputLabel>
                        <OutlinedInput
                            id="input-version"
                            required
                            type="text"
                            value={version}
                            onChange={(event) => setVersion(event.target.value)}
                            labelWidth={120}
                        />
                    </FormControl>
                </Fragment>
            }
            onPositiveAction={handleAgregarEquipo} positiveActionText="Agregar" />
    )
}


function AgregarStock({ open, onClose, onAgregar }) {
    const [nombre, setNombre] = useState("")

    const handleAgregarStock = () => {
        onAgregar({ nombre })
        setNombre("")
        onClose()
    }

    return (
        <AlertDialog open={open} onClose={onClose}
            titulo="Agregar stock"
            contenido={
                <FormControl className='formcontrol-selector' variant="outlined">
                    <InputLabel htmlFor="input-alias">Nombre</InputLabel>
                    <OutlinedInput
                        id="input-nombre"
                        required
                        type="text"
                        value={nombre}
                        onChange={(event) => setNombre(event.target.value)}
                        labelWidth={120}
                    />
                </FormControl>
            }
            onPositiveAction={handleAgregarStock} positiveActionText="Agregar" />
    )
}