import React, { useCallback } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  CustomButton,
  Toolbar,
  ViewContainerVertical,
  ViewSeparator,
} from 'components/reusable';
import { useModal } from 'hooks';
import AlertDialog from 'components/AlertDialog';
import CollapsableItem from 'components/CollapsableItem';
import ModalAgregarReceta from '../modal-agregar-receta';
import TablaReceta from '../tabla-receta';
import { editReceta, eliminarReceta } from 'redux/slices/recetas.slice';
import RecetaForm from '../modal-agregar-receta/receta-form';

function CollapsableReceta({ receta }) {
  const dispatch = useDispatch();
  const { equipoSelected } = useSelector((state) => state.hook);
  const { NSerie = '' } = equipoSelected || {};
  const [
    isOpenModificarReceta,
    onOpenModificarReceta,
    onCloseModificarReceta,
  ] = useModal();
  const [
    isOpenEliminarReceta,
    onOpenEliminarReceta,
    onCloseEliminarReceta,
  ] = useModal();

  const onModificarReceta = useCallback(() => {
    dispatch(editReceta(receta));
    onOpenModificarReceta();
  }, [dispatch, onOpenModificarReceta, receta]);

  const onEliminarReceta = useCallback(() => {
    dispatch(eliminarReceta(NSerie, receta));
    onCloseEliminarReceta();
  }, [NSerie, dispatch, onCloseEliminarReceta, receta]);

  return (
    <>
      <CollapsableItem
        title={receta.nombre}
        defaultExpanded={true}
        disableContentPadding={true}
        bigMargin
      >
        <ViewContainerVertical>
          <RecetaForm receta={receta} />
          <TablaReceta receta={receta} />
          <ViewSeparator />
          <Toolbar flexedEnd>
            <CustomButton
              title="Eliminar"
              buttonProps={{
                onClick: onOpenEliminarReceta,
                color: 'secondary',
              }}
            />
            <CustomButton
              title="Modificar"
              buttonProps={{
                onClick: onModificarReceta,
                variant: 'outlined',
              }}
            />
          </Toolbar>
        </ViewContainerVertical>
      </CollapsableItem>
      <ModalAgregarReceta
        isOpen={isOpenModificarReceta}
        onClose={onCloseModificarReceta}
        onSave={onCloseModificarReceta}
      />
      <AlertDialog
        open={isOpenEliminarReceta}
        onClose={onCloseEliminarReceta}
        titulo="Eliminar Receta"
        contenido="¿Está seguro que desea eliminar la receta? Esta acción no se puede deshacer."
        onPositiveAction={onEliminarReceta}
      />
    </>
  );
}
export default CollapsableReceta;
