import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveReportesConfigChanges } from 'redux/slices/hook.slice';
import {
  ViewContainerVertical,
  CustomButton,
  FormCheckbox,
  FormContainer,
  FormTextfield,
} from 'components/reusable';
import { validateEmail } from 'helpers';

const ConfigReporte = () => {
  const dispatch = useDispatch();
  const {
    email = '',
    napReportPeriod = 30,
    wantToReceiveReports = false,
  } = useSelector((state) => state.login);
  const [isEmailEnabled, setIsEmailEnabled] = useState(wantToReceiveReports);
  const [formEmail, setFormEmail] = useState(email);
  const [periodoDias, setPeriodoDias] = useState(napReportPeriod);

  const onChangeEmailEnabled = (event) => setIsEmailEnabled(!isEmailEnabled);
  const onChangeEmail = (event) => setFormEmail(event.target.value);
  const onChangePeriodo = (event) => setPeriodoDias(event.target.value);
  const isEmailValid =
    !formEmail || formEmail.length === 0 || validateEmail(formEmail);

  function onSaveFormChanges({ isEmailEnabled, email, periodoDias }) {
    dispatch(
      saveReportesConfigChanges({
        isEmailEnabled,
        email: formEmail,
        periodoDias,
      }),
    );
  }

  useEffect(() => {
    setIsEmailEnabled(wantToReceiveReports);
    setFormEmail(email);
    setPeriodoDias(napReportPeriod);
  }, [email, napReportPeriod, wantToReceiveReports]);

  return (
    <ViewContainerVertical>
      <FormCheckbox
        label="Quiero recibir reportes"
        checkboxProps={{
          onChange: onChangeEmailEnabled,
          checked: isEmailEnabled,
        }}
      />
      <FormContainer>
        <FormTextfield
          texfieldProps={{
            id: 'stock-historico-config-email',
            label: 'E-mail',
            value: formEmail,
            onChange: onChangeEmail,
            disabled: !isEmailEnabled,
            error: !isEmailValid,
            required: true,
          }}
        />
        <FormTextfield
          texfieldProps={{
            id: 'stock-historico-config-periodo',
            label: 'Período entre reportes (días)',
            value: periodoDias,
            onChange: onChangePeriodo,
            type: 'number',
            disabled: !isEmailEnabled,
            required: true,
            InputProps: {
              inputProps: {
                min: 1,
              },
            },
          }}
        />
      </FormContainer>
      <CustomButton
        title="Guardar cambios"
        buttonProps={{ onClick: onSaveFormChanges }}
      />
    </ViewContainerVertical>
  );
};

export default ConfigReporte;
