import React from 'react';
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiDialog: {
        container: {
          width: '100% !important',
        },
      },
    },
  });

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogComponent({
  children,
  open,
  onClose,
  titulo,
  actions = [],
  isFullScreen = false,
  fullWidth = true,
}) {
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <Dialog
        onClose={onClose}
        aria-labelledby="dialog-title"
        open={open}
        scroll={isFullScreen ? 'paper' : 'body'}
        fullWidth={fullWidth}
        maxWidth={'md'}
        fullScreen={isFullScreen}
        TransitionComponent={Transition}
      >
        <DialogTitle id="dialog-title" onClose={onClose}>
          {titulo}
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        {actions.length > 0 && (
          <DialogActions>
            {actions.map((action, indexAction) => (
              <Button
                autoFocus={action.autoFocus || false}
                onClick={action.onClick}
                color={action.color || 'primary'}
                key={'dialog-action' + indexAction}
                variant={action.variant || 'contained'}
                disabled={action.disabled || false}
              >
                {action.text}
              </Button>
            ))}
          </DialogActions>
        )}
      </Dialog>
    </MuiThemeProvider>
  );
}
