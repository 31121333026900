import React from 'react';
import 'styles/Hook.css';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import CustomMenu from 'components/CustomMenu'; // TODO: ponerlo en este archivo

const hookStyles = makeStyles((theme) => ({
  customButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    // alignSelf: 'stretch',
    height: '50px',
    lineHeight: '50px',
    padding: '5px !important',
    marginTop: '8px !important',
    marginBottom: '8px !important',
    flexGrow: 1,
  },
  textFieldWithHelperText: {
    marginBottom: '24px !important',
  },
}));

export function ViewContainer({ children, style = {} }) {
  return (
    <div className="view-container" style={{ ...style }}>
      {children}
    </div>
  );
}

export function ViewContainerVertical({ children, style = {} }) {
  return (
    <div className="view-container-vertical" style={{ ...style }}>
      {children}
    </div>
  );
}

export function ViewTitle({ title = '', style = {} }) {
  return (
    <Typography variant="h5" style={{ margin: 5, ...style }}>
      {title}
    </Typography>
  );
}

export function ViewSubtitle({ title = '', style = {} }) {
  return (
    <Typography
      variant="body1"
      style={{ marginLeft: 5, marginBottom: 5, ...style }}
    >
      {title}
    </Typography>
  );
}

export function ViewBody({ title = '', style = {} }) {
  return (
    <Typography variant="body2" style={{ ...style }}>
      {title}
    </Typography>
  );
}

export function Text({ children, style = {} }) {
  return (
    <Typography variant="body2" style={{ ...style }}>
      {children}
    </Typography>
  );
}

export function SubView({ children, maxHeight = 300 }) {
  return (
    <div className="subview-container" style={{ maxHeight }}>
      {children}
    </div>
  );
}

export function Toolbar({ children, spacedBetween, flexedEnd }) {
  return (
    <div
      className={`toolbar ${
        spacedBetween
          ? 'toolbar-spaced-between'
          : flexedEnd
          ? 'toolbar-flex-end'
          : ''
      }`}
    >
      {children}
    </div>
  );
}

export function CustomButton({ title, buttonProps }) {
  const classes = hookStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      className={classes.customButton}
      {...buttonProps}
    >
      {title}
    </Button>
  );
}

export function ViewSeparator() {
  return <div className="separator" />;
}

export function FormContainer({ children }) {
  return <div className="form-content-container">{children}</div>;
}

export function FormTextfield({ texfieldProps, helperText }) {
  const classes = hookStyles();
  return (
    <FormControl
      className={`${classes.textField} ${
        helperText ? classes.textFieldWithHelperText : ''
      }`}
      variant="outlined"
    >
      <TextField variant="outlined" {...texfieldProps} />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export function FormSelect({
  title = '',
  selectOptions = [],
  selectProps = {},
}) {
  const classes = hookStyles();
  return (
    <FormControl className={classes.textField} variant="outlined">
      <InputLabel>{title}</InputLabel>
      <Select {...selectProps}>
        {selectOptions.map((option, index) => (
          <MenuItem
            value={option.value}
            key={'option' + index}
            disabled={option.disabled}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function FormEditingEnablingTextfield({
  texfieldProps,
  editButtonProps,
}) {
  const classes = hookStyles();
  return (
    <FormControl className={classes.textField} variant="outlined">
      <TextField
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle editing" {...editButtonProps}>
                {' '}
                <Edit />{' '}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...texfieldProps}
      />
    </FormControl>
  );
}

export function FormCheckbox({ label = '', checkboxProps }) {
  return (
    <FormControlLabel
      label={label}
      control={<Checkbox color="primary" {...checkboxProps} />}
    />
  );
}

export function CardWithHeader({ title = '', menuItems = [], children }) {
  return (
    <div className="card-container">
      <div className="card-header-container">
        <CardTitle title={title} />
        {menuItems.length > 0 && <CustomMenu customMenuItems={menuItems} />}
      </div>
      <CardDivider />
      {children}
    </div>
  );
}

export function CardTitle({ title = '', style = {} }) {
  return (
    <Typography variant="h6" style={{ ...style }}>
      {title}
    </Typography>
  );
}

export function ContentInfo({ contentInfoItems = [], children }) {
  return (
    <div className={'equipo-characteristics-container'}>
      {contentInfoItems.map((item, itemIndex) => (
        <ViewBody title={item} key={itemIndex} />
      ))}
      {children}
    </div>
  );
}

export function CardDivider({ withMargin }) {
  return (
    <div className={withMargin ? 'card-divider-with-margin' : 'card-divider'} />
  );
}
