import React, { useState, useMemo } from 'react';
import { parserFunctions } from '../../../helpers';
import MapaUbicaciones from '../mapa-ubicaciones';
import FilterBar from '../../FilterBar';
import { useDispatch, useSelector } from 'react-redux';
import { saveConsumos } from 'redux/slices/hook.slice';
import { ViewContainerVertical, ViewTitle } from 'components/reusable';

function Ubicacion() {
  const dispatch = useDispatch();
  const { dataEquipo: data, equipoSelected } = useSelector(
    (state) => state.hook,
  );
  const [cadenasFiltradas, setCadenasFiltradas] = useState([]);
  const equiposUbicaciones = useMemo(
    () => parserFunctions.parseDataUbicacion(cadenasFiltradas),
    [cadenasFiltradas],
  );

  function onSave({ precioCombustible, consumoPromedio }) {
    const { NSerie } = equipoSelected || {};
    if (NSerie && precioCombustible && consumoPromedio)
      dispatch(saveConsumos({ NSerie, precioCombustible, consumoPromedio }));
  }

  return (
    <ViewContainerVertical>
      <ViewTitle title="Resumen diario" />
      <MapaUbicaciones data={equiposUbicaciones} onSave={onSave}>
        <FilterBar cadenas={data} setCadenasFiltradas={setCadenasFiltradas} />
      </MapaUbicaciones>
    </ViewContainerVertical>
  );
}

export default Ubicacion;
