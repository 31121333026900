import React from 'react';
import { ViewContainer } from 'components/reusable';
import Simel from './simel-tables';
import SimelStats from './simel-stats';

function SimelView() {
  return (
    <ViewContainer>
      <Simel />
      <SimelStats />
    </ViewContainer>
  );
}

export default SimelView;
