import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function CollapsableItem({
  title = '',
  defaultExpanded = false,
  disableContentPadding = false,
  bigMargin,
  children,
}) {
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      style={{
        width: '100%',
        margin: 0,
        marginBottom: bigMargin ? 50 : 0, //, boxShadow:  'none'
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography noWrap variant="h5">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{ padding: disableContentPadding ? 0 : undefined }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
