import React, { Fragment, useState, useEffect } from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polyline,
} from 'react-google-maps';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import 'styles/Helpers.css';
import { MarkerEvento, MarkerEventoPolyline } from './marker';

const ICON_GRANO = require('../../../assets/medycon/grain-icon.png');
const ICON_HOOK = require('../../../assets/medycon/favicon-hook.png');

const MapComponent = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyCMFpi3reTxyZtrQXSu6atHlale2gyDEjM&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: (
      <div
        style={{
          height: '100%',
          width: '100%',
          backgroundColor: 'rgba(255,255,255,0.5)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {' '}
        <CircularProgress />{' '}
      </div>
    ),
    containerElement: <div style={{ height: '100%', width: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(
  ({
    onMarkerSelect,
    ubicacionesGeneralLinea,
    ubicacionesGeneralPuntos,
    ubicacionesLote,
    ubicacionesIngrediente,
    showGeneral,
  }) => {
    const [mapRef, setMapRef] = useState<GoogleMap | null>(null);
    const [bounds, setBounds] = useState(new window.google.maps.LatLngBounds());

    const onSelectOut = () => {
      onMarkerSelect('');
    };

    const onSelectMarker = (id) => {
      onMarkerSelect(id);
    };

    useEffect(() => {
      if (
        ubicacionesGeneralPuntos ||
        ubicacionesLote ||
        ubicacionesIngrediente
      ) {
        const newBounds = new window.google.maps.LatLngBounds();
        showGeneral &&
          ubicacionesGeneralPuntos &&
          ubicacionesGeneralPuntos.forEach((evento) => {
            const { lat, lng } = evento.coordenadas;
            const latLng = new window.google.maps.LatLng(lat, lng);
            newBounds.extend(latLng);
          });
        ubicacionesLote &&
          ubicacionesLote.forEach((evento) => {
            const { lat, lng } = evento.coordenadas;
            const latLng = new window.google.maps.LatLng(lat, lng);
            newBounds.extend(latLng);
          });
        ubicacionesIngrediente &&
          ubicacionesIngrediente.forEach((evento) => {
            const { lat, lng } = evento.coordenadas;
            const latLng = new window.google.maps.LatLng(lat, lng);
            newBounds.extend(latLng);
          });
        setBounds(newBounds);
      }
    }, [
      ubicacionesGeneralPuntos,
      ubicacionesLote,
      ubicacionesIngrediente,
      showGeneral,
    ]);

    useEffect(() => {
      if (mapRef) mapRef.fitBounds(bounds);
    }, [bounds, mapRef]);

    return (
      <GoogleMap
        defaultZoom={14}
        defaultCenter={{ lat: -31.667488, lng: -63.091393 }}
        onClick={onSelectOut}
        ref={(ref) => setMapRef(ref)}
      >
        {ubicacionesIngrediente &&
          ubicacionesIngrediente.map((evento, indexEvento) => {
            const fecha = evento.fecha
              ? moment(evento.fecha, 'DD-MM-YYYY HH:mm:ss').format('HH:mm:ss')
              : '';
            return (
              <MarkerEvento
                key={indexEvento}
                position={evento.coordenadas}
                fecha={fecha}
                onClick={() => onSelectMarker(evento.id)}
                icon={ICON_GRANO}
                borderColor={'#ffc107'} // amber
              />
            );
          })}
        {ubicacionesLote &&
          ubicacionesLote.map((evento, indexEvento) => {
            const fecha = evento.fecha
              ? moment(evento.fecha, 'DD-MM-YYYY HH:mm:ss').format('HH:mm:ss')
              : '';
            return (
              <MarkerEvento
                key={indexEvento}
                position={evento.coordenadas}
                fecha={fecha}
                onClick={() => onSelectMarker(evento.id)}
                icon={ICON_HOOK}
                borderColor={'#3f51b5'} //indigo
              />
            );
          })}
        {showGeneral && ubicacionesGeneralLinea && ubicacionesGeneralPuntos && (
          <Fragment>
            {ubicacionesGeneralPuntos &&
              ubicacionesGeneralPuntos.map((evento, indexEvento) => {
                const fecha = evento.fecha
                  ? moment(evento.fecha, 'DD-MM-YYYY HH:mm:ss').format(
                      'HH:mm:ss',
                    )
                  : '';
                return (
                  <MarkerEventoPolyline
                    key={indexEvento}
                    position={evento.coordenadas}
                    fecha={fecha}
                    onClick={() => onSelectMarker(evento.id)}
                    backgroundColor={
                      indexEvento === 0
                        ? '#f44336'
                        : indexEvento === ubicacionesGeneralPuntos.length - 1
                        ? '#4caf50'
                        : '#2b2b2b'
                    }
                  />
                );
              })}
            <Polyline
              path={ubicacionesGeneralLinea}
              // geodesic={true}
              options={{
                strokeColor: '#1b0088',
                strokeOpacity: 0.75,
                strokeWeight: 2,
              }}
            />
          </Fragment>
        )}
      </GoogleMap>
    );
  },
);
export default MapComponent;
