import React, { useContext, useEffect } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Cookies from 'js-cookie';
import HomePageAdmin from './HomePageAdmin';
import HomePageHook from './HomePageHook';
import HomePageMedycon from './HomePageMedycon';
import Login from './Login';
import { MedyconContext } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCookies,
  logout,
  updateLoggedUser,
} from 'redux/slices/login.slice';
import { getUserColumns } from 'redux/slices/config.slice';
import LoaderComponent from 'components/LoaderComponent';

function Home() {
  const dispatch = useDispatch();
  const { role, username, isLoading } = useSelector((state) => state.login);
  const { isHook } = useContext(MedyconContext);
  const history = createBrowserHistory();
  // const history = useHistory();

  const updateStoredCookies = () => {
    const updatedRole = Cookies.get('role');
    const updatedUsername = Cookies.get('username');
    dispatch(updateCookies({ role: updatedRole, username: updatedUsername }));
  };

  const onLogout = async () => {
    dispatch(logout());
    Cookies.remove('role');
    Cookies.remove('username');
    history.push('/');
  };

  useEffect(() => {
    updateStoredCookies();
  }, []);

  useEffect(() => {
    if (username && username !== '') {
      Cookies.set('username', username);
      updateStoredCookies();
      dispatch(updateLoggedUser());
      if (isHook) dispatch(getUserColumns({ username }));
      // dispatch(setUserColumns({ username, columns: [] }));
    }
  }, [username]);

  return (
    <Router history={history}>
      {isLoading && <LoaderComponent hideBackground />}
      {role === 'cliente' ? (
        isHook ? (
          <HomePageHook onLogout={onLogout} />
        ) : (
          <HomePageMedycon onLogout={onLogout} username={username} />
        )
      ) : role === 'admin' ? (
        <HomePageAdmin onLogout={onLogout} username={username} />
      ) : (
        <Login />
      )}
    </Router>
  );
}

export default Home;
