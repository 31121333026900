import React from 'react';
import 'styles/Helpers.css';
import { CircularProgress } from '@material-ui/core';

const LoaderComponent = ({ hideBackground = false }) => (
  <div
    className="loader-container"
    style={{ backgroundColor: hideBackground ? 'white' : undefined }}
  >
    <CircularProgress />
  </div>
);
export default LoaderComponent;
