import React, { useMemo } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { IconButton } from '@material-ui/core';
import 'styles/Admin.css';
import 'styles/EquipoSAP.css';
import {
  IconSwitchOn,
  IconSwitchOff,
  IconTemperature,
  GifCintaTransportadora,
} from 'components/Icons';
import { useModal } from 'hooks';
import DialogComponent from 'components/DialogComponent';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CustomMenu from 'components/CustomMenu';

moment.locale('es');

function CardEquipoSAP({ equipoBundle, onVerMas }) {
  const { equipo: infoEquipo, datos = [] } = equipoBundle || {};
  if (infoEquipo) {
    const { equipo: equipoTipo, alias = '' } = infoEquipo || {};
    const ultimaCadena = datos.length > 0 ? datos[0] : undefined;
    const { fecha: ultimaCadenaFecha, estado, ciclos, horas, sVV } =
      ultimaCadena || {};

    const isEquipoInactivo =
      !ultimaCadena ||
      !ultimaCadenaFecha ||
      moment(ultimaCadenaFecha) < moment().subtract(10, 'minutes');

    const labelEstado = isEquipoInactivo
      ? 'Apagado o sin conexión.'
      : estado === 'Play'
      ? 'Sistema activo (pasteurizando).'
      : estado === 'Regimen'
      ? 'Puesta en régimen.'
      : estado === 'Stop'
      ? 'Equipo detenido.'
      : '';

    return (
      <div className="equipo-card-container" style={{ cursor: 'pointer' }}>
        <div className="equipo-card-content-container">
          <div className="equipo-card-name">
            <span>
              {alias ? alias + ' (' : ''}
              {equipoTipo}
              {alias ? ')' : ''}
            </span>
            <CustomMenu
              customMenuItems={[
                <span variant="body1" onClick={onVerMas}>
                  Ver más
                </span>,
              ]}
            />
          </div>
          <div className="equipo-card-divider" />
          <div
            className={
              'equipo-characteristics-container' +
              (isEquipoInactivo
                ? ' equipo-sap-estado-inactivo'
                : estado === 'Play'
                ? ' equipo-sap-estado-play'
                : estado === 'Regimen'
                ? ' equipo-sap-estado-warning'
                : estado === 'Stop'
                ? ' equipo-sap-estado-danger'
                : ' equipo-sap-estado-inactivo')
            }
          >
            <div
              className={
                'equipo-card-characteristic equipo-sap-etapa-element-estado-label'
              }
            >
              {labelEstado}
            </div>
          </div>
          {!isEquipoInactivo && ultimaCadena && (
            <>
              <div className="equipo-card-divider" />
              <div className="equipo-sap-etapas-container">
                <EtapaRender cadena={ultimaCadena} etapaNumber={1} />
                <EtapaRender cadena={ultimaCadena} etapaNumber={2} />
                <EtapaRender cadena={ultimaCadena} etapaNumber={3} />
              </div>
              <div className="equipo-card-divider" />
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                  }}
                >
                  <div className={'equipo-characteristics-container'}>
                    <div className={'equipo-card-characteristic'}>
                      T. total de uso: {Number(horas)}
                    </div>
                  </div>
                  <div className={'equipo-characteristics-container'}>
                    <div className={'equipo-card-characteristic'}>
                      Ciclos de uso: {Number(ciclos)}
                    </div>
                  </div>
                  {estado === 'Play' && (
                    <div className={'equipo-characteristics-container'}>
                      <div className={'equipo-card-characteristic'}>
                        <EtapaSwitch
                          estado={sVV === 'ON'}
                          label={'Cinta transportadora'}
                          isHorizontal={true}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    padding: 5,
                  }}
                >
                  {estado === 'Play' && (
                    <GifCintaTransportadora
                      style={{ height: 50, width: 100 }}
                    />
                  )}
                </div>
              </div>
              {estado === 'Stop' && (
                <>
                  <div className="equipo-card-divider" />
                  <AlarmasRecientes datos={datos} />
                </>
              )}
            </>
          )}
          <div className={'equipo-characteristics-container'}>
            {ultimaCadena ? (
              <div
                className={'equipo-card-characteristic'}
                style={{ fontSize: '0.9rem' }}
              >
                Último dato recibido {moment(ultimaCadenaFecha).fromNow()}
              </div>
            ) : (
              <div className={'equipo-card-characteristic'}>
                No se encontraron datos recientes del equipo.
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return null;
}

export default CardEquipoSAP;

const EtapaRender = ({ cadena, etapaNumber }) => {
  const { estado } = cadena || {};

  const temperaturaTanque = cadena[`tempT${etapaNumber}`];
  const estadoBombaTanque = cadena[`sTA${etapaNumber}`] === 'ON';
  const estadoValvula = cadena[`sEV${etapaNumber}`] === 'ON';
  const temperaturaAspersor = cadena[`tempAS${etapaNumber}`];
  const estadoBombaAspersor = cadena[`sAS${etapaNumber}`];

  return (
    <div className={'equipo-sap-etapa-container'}>
      <div
        className={'equipo-sap-etapa-element equipo-sap-etapa-element-id'}
      >{`Etapa ${etapaNumber}`}</div>
      {estado !== 'Play' && (
        <div className={'equipo-sap-etapa-element'}>
          <EtapaTemperatura temperatura={temperaturaTanque} label={'TANQUE'} />
        </div>
      )}
      {estado !== 'Play' && (
        <div className={'equipo-sap-etapa-element'}>
          <EtapaSwitch estado={estadoBombaTanque} label={'BOMBA TAN.'} />
        </div>
      )}
      <div className={'equipo-sap-etapa-element'}>
        <EtapaSwitch estado={estadoValvula} label={'E. VÁLVULA'} />
      </div>
      {estado !== 'Regimen' && (
        <div className={'equipo-sap-etapa-element'}>
          <EtapaTemperatura
            temperatura={temperaturaAspersor}
            label={'ASPERSOR'}
          />
        </div>
      )}
      {estado !== 'Play' && estado !== 'Regimen' && (
        <div className={'equipo-sap-etapa-element'}>
          <EtapaSwitch estado={estadoBombaAspersor} label={'BOMBA ASP.'} />
        </div>
      )}
    </div>
  );
};

const EtapaSwitch = ({ estado, label, isHorizontal }) => {
  return (
    <div
      className={
        'equipo-sap-etapa-element' +
        (isHorizontal ? ' equipo-sap-etapa-element-horizontal' : '')
      }
    >
      <span style={{ marginRight: isHorizontal ? 10 : 0 }}>{label}</span>
      {estado === true ? (
        <IconSwitchOn size="lg" />
      ) : (
        <IconSwitchOff size="lg" />
      )}
    </div>
  );
};

const EtapaTemperatura = ({ temperatura, label }) => {
  return (
    <div className={'equipo-sap-etapa-element'}>
      <span>{label}</span>
      <div
        style={{
          width: '100%',
          border: '1px solid rgba(255,0,0,0.5)',
          borderRadius: 5,
          padding: '5px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <IconTemperature size="sm" />
        <span style={{ marginLeft: 5 }}>{temperatura}°C</span>
      </div>
    </div>
  );
};

const AlarmasRecientes = React.memo(({ datos }) => {
  const [
    isOpenAlarmasReferencia,
    onOpenAlarmasReferencia,
    onCloseAlarmasReferencia,
  ] = useModal();
  const alarmas = useMemo(
    () => datos.filter((dato) => dato.alarmas && dato.alarmas.trim() !== ''),
    [datos],
  );
  const alarmasRecientes = [];
  alarmas
    .map((alarma) => alarma.alarmas)
    .forEach((value, index, self) => {
      if (self.indexOf(value) === index) alarmasRecientes.push(alarmas[index]);
    });

  return (
    <>
      <DialogComponent
        open={isOpenAlarmasReferencia}
        onClose={onCloseAlarmasReferencia}
        titulo={'Referencia de alarmas'}
      >
        <div className="equipo-characteristics-container">
          <div className={'equipo-card-characteristic'}>
            aTempPastAs1: No se pudo llegar a la temperatura seteada en el
            Aspersor 1 en el tiempo seteado, estando el equipo Pasteurizando.
          </div>
          <div className={'equipo-card-characteristic'}>
            aTempPastAs2: No se pudo llegar a la temperatura seteada en el
            Aspersor 2 en el tiempo seteado, estando el equipo Pasteurizando.
          </div>
          <div className={'equipo-card-characteristic'}>
            aTempPastAs3: No se pudo llegar a la temperatura seteada en el
            Aspersor 3 en el tiempo seteado, estando el equipo Pasteurizando.
          </div>
          <br />
          <div className={'equipo-card-characteristic'}>
            aTempRegTa1: No se pudo llegar a la temperatura seteada en el Tanque
            1 en el tiempo seteado estando el equipo en régimen.
          </div>
          <div className={'equipo-card-characteristic'}>
            aTempRegTa2: No se pudo llegar a la temperatura seteada en el Tanque
            2 en el tiempo seteado estando el equipo en régimen.
          </div>
          <div className={'equipo-card-characteristic'}>
            aTempRegTa3: No se pudo llegar a la temperatura seteada en el Tanque
            3 en el tiempo seteado estando el equipo en régimen.
          </div>
          <br />
          <div className={'equipo-card-characteristic'}>
            aETA1: Falla Bomba Tanque 1.
          </div>
          <div className={'equipo-card-characteristic'}>
            aEAS1: Falla Bomba Aspersor 1.
          </div>
          <div className={'equipo-card-characteristic'}>
            aEEV1: Falla Electroválvula 1.
          </div>
          <div className={'equipo-card-characteristic'}>
            aETA2: Falla Bomba Tanque 2.
          </div>
          <div className={'equipo-card-characteristic'}>
            aEAS2: Falla Bomba Aspersor 2.
          </div>
          <div className={'equipo-card-characteristic'}>
            aEEV2: Falla Electroválvula 2.
          </div>
          <div className={'equipo-card-characteristic'}>
            aETA3: Falla Bomba Tanque 3.
          </div>
          <div className={'equipo-card-characteristic'}>
            aEAS3: Falla Bomba Aspersor 3.
          </div>
          <div className={'equipo-card-characteristic'}>
            aEEV3: Falla Electroválvula 3.
          </div>
          <br />
          <div className={'equipo-card-characteristic'}>
            aEVV: Falla Variador de Velocidad (Cinta Transportadora).
          </div>
        </div>
      </DialogComponent>
      <div className={'equipo-characteristics-container'}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div className={'equipo-card-characteristic'}>Alarmas recientes:</div>
          <IconButton aria-label="close" onClick={onOpenAlarmasReferencia}>
            <HelpOutlineIcon />
          </IconButton>
        </div>
        {alarmas.length > 0 ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '0 10px',
            }}
          >
            {alarmasRecientes.map((alarma, indexalarma) => (
              <div
                className={'equipo-card-characteristic'}
                key={'alarma' + indexalarma}
              >
                {alarma.alarmas.trim()} ({moment(alarma.fecha).fromNow()})
              </div>
            ))}
          </div>
        ) : (
          <div className={'equipo-card-characteristic'}>
            No hay alarmas recientes para mostrar.
          </div>
        )}
      </div>
    </>
  );
});
