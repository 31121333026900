import React from 'react';
import {
  createMuiTheme,
  responsiveFontSizes,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import { Home } from './containers';
import { MedyconContext, intialContext } from './helpers';

const isHook = false;

const THEME = createMuiTheme({
  typography: {
    fontFamily: '"Roboto", "Montserrat", sans-serif',
    fontSize: 14,
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 300,
  },
});

function App() {
  return (
    <MuiThemeProvider theme={responsiveFontSizes(THEME)}>
      <MedyconContext.Provider value={{ ...intialContext, isHook }}>
        <Home />
      </MedyconContext.Provider>
    </MuiThemeProvider>
  );
}

export default App;
