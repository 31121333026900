import React, { useMemo } from 'react';
import { parserFunctions, tableConstants } from 'helpers';
import TablaSubsuma from 'components/TablaSubsuma';
import { useColumns } from 'hooks';
import { useSelector } from 'react-redux';
import { ViewContainer } from 'components/reusable';

function Descargas() {
  const { dataEquipo: data } = useSelector((state) => state.hook);
  const { columns, onColumnsViewChange } = useColumns('columnsDescargas');
  const dataDescargas = useMemo(
    () => parserFunctions.parseDataDescargas(data),
    [data],
  );

  return (
    <ViewContainer>
      <TablaSubsuma
        title={'Descargas'}
        data={dataDescargas}
        columnas={columns}
        attributesToSum={tableConstants.attributesDescargas}
        newRow={tableConstants.newRowDescargas}
        onColumnsViewChange={onColumnsViewChange}
      />
    </ViewContainer>
  );
}
export default Descargas;
