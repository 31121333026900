import React, { useMemo } from 'react';
import 'styles/Helpers.css';
import MUIDataTable from 'mui-datatables';
import { tableConstants } from '../helpers';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiPaper: {
        root: {
          overflow: 'auto',
        },
      },
      MuiTableCell: {
        head: {
          whiteSpace: 'nowrap',
          textAlign: 'left',
        },
      },
      MuiGridList: {
        root: {
          width: '100% !important',
        },
      },
      MuiGridListTile: {
        root: {
          width: '100% !important',
        },
      },
    },
  });

export default function Table({
  title = '',
  data,
  columns,
  options = {},
  rowsSelected = [],
  onColumnViewChange,
}: {
  title: string;
  data: any[];
  columns: any[];
  rowsSelected?: any[];
  options?: any;
  onColumnViewChange?: (columnas) => void;
}) {
  let tableOptions = useMemo(() => {
    let opt = {
      ...tableConstants.tableOptions(),
      ...options,
      rowsSelected,
      onColumnViewChange: (changedColumn, action) => {
        const newColumnasVisibles = columns.map((columna) => {
          const newCol = { ...columna, options: { ...columna.options } };
          if (columna.name === changedColumn)
            newCol.options.display = action === 'add';
          return newCol;
        });
        if (onColumnViewChange) onColumnViewChange(newColumnasVisibles);
      },
    };
    return opt;
  }, [columns, onColumnViewChange, options, rowsSelected]);

  // actualizando la key se puede rerenderizar la tabla, cambiando las options no se actualiza
  const key = `datatable-${title}-${
    Array.isArray(rowsSelected) ? rowsSelected.length : 1
  }`;

  return (
    <div className="table-container">
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          key={key}
          title={title}
          data={data}
          columns={columns}
          options={tableOptions}
        />
      </MuiThemeProvider>
    </div>
  );
}
