import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorMessage } from '.';
import { adminService } from '../../services';
import { sanitizeResponseArray } from '../../services/helpers';

const initialState: {
  isLoading: boolean;
  error: null | string;
  equipos: any[];
  usuarios: any[];
  stocks: any[];
  shouldReload: boolean;
} = {
  isLoading: false,
  error: null,
  equipos: [],
  usuarios: [],
  stocks: [],
  shouldReload: false,
};

const adminSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    request(state) {
      state.isLoading = true;
      state.error = null;
      state.shouldReload = false;
    },
    success(state) {
      state.isLoading = false;
      state.error = null;
      state.shouldReload = false;
    },
    failure(state, action: PayloadAction<string | null>) {
      state.isLoading = false;
      state.error = action.payload;
      state.shouldReload = false;
    },
    successAndReload(state) {
      state.isLoading = false;
      state.error = null;
      state.shouldReload = true;
    },
    getEquiposAsignadosSuccess(state, action: PayloadAction<any[]>) {
      state.equipos = action.payload;
    },
    getUsuariosAsociadosSuccess(state, action: PayloadAction<any[]>) {
      state.usuarios = action.payload;
    },
    getAllStocksNamesSuccess(state, action: PayloadAction<any[]>) {
      state.stocks = action.payload;
    },
  },
});

export default adminSlice;

const {
  request,
  success,
  failure,
  successAndReload,
  getEquiposAsignadosSuccess,
  getUsuariosAsociadosSuccess,
  getAllStocksNamesSuccess,
} = adminSlice.actions;

export const getEquiposAsignados = () => async (dispatch) => {
  dispatch(request());
  return adminService.getEquiposAsignados().then(
    (response) => {
      dispatch(success());
      dispatch(getEquiposAsignadosSuccess(sanitizeResponseArray(response)));
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const getUsuariosAsociados = () => async (dispatch) => {
  dispatch(request());
  return adminService.getUsuariosAsociados().then(
    (response) => {
      dispatch(success());
      dispatch(getUsuariosAsociadosSuccess(sanitizeResponseArray(response)));
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const newUsuarioAsociado = ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => async (dispatch) => {
  dispatch(request());
  return adminService.newUsuarioAsociado({ username, password }).then(
    (response) => {
      dispatch(successAndReload());
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const addEquipoToUser = ({
  NSerie,
  username,
}: {
  NSerie: string;
  username: string;
}) => async (dispatch) => {
  dispatch(request());
  return adminService.addEquipoToUser({ NSerie, username }).then(
    (response) => {
      dispatch(successAndReload());
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const removeEquipoFromUser = ({
  NSerie,
  username,
}: {
  NSerie: string;
  username: string;
}) => async (dispatch) => {
  dispatch(request());
  return adminService.removeEquipoFromUser({ NSerie, username }).then(
    (response) => {
      dispatch(successAndReload());
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const addEquipo = ({
  NSerie,
  equipo,
  ver,
}: {
  NSerie: string;
  equipo: string;
  ver: string;
}) => async (dispatch) => {
  dispatch(request());
  return adminService.addEquipo({ NSerie, equipo, ver }).then(
    (response) => {
      dispatch(successAndReload());
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const removeEquipo = ({ NSerie }: { NSerie: string }) => async (
  dispatch,
) => {
  dispatch(request());
  return adminService.removeEquipo({ NSerie }).then(
    (response) => {
      dispatch(successAndReload());
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const removeDatosEquipo = ({ NSerie }: { NSerie: string }) => async (
  dispatch,
) => {
  dispatch(request());
  return adminService.removeDatosEquipo({ NSerie }).then(
    (response) => {
      dispatch(successAndReload());
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const setSimelToNap = ({
  NSerieSimel,
  NSerieNap,
}: {
  NSerieSimel: string;
  NSerieNap: string;
}) => async (dispatch) => {
  dispatch(request());
  return adminService.setSimelToNap({ NSerieSimel, NSerieNap }).then(
    (response) => {
      dispatch(successAndReload());
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const removeSimelOfNap = ({
  NSerieNap,
}: {
  NSerieNap: string;
}) => async (dispatch) => {
  dispatch(request());
  return adminService.removeSimelOfNap({ NSerieNap }).then(
    (response) => {
      dispatch(successAndReload());
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const deleteUser = ({ username }: { username: string }) => async (
  dispatch,
) => {
  dispatch(request());
  return adminService.deleteUser({ username }).then(
    (response) => {
      dispatch(successAndReload());
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const updateAliasOfEquipo = ({
  alias,
  NSerie,
}: {
  alias: string;
  NSerie: string;
}) => async (dispatch) => {
  dispatch(request());
  return adminService.updateAliasOfEquipo({ alias, NSerie }).then(
    (response) => {
      dispatch(successAndReload());
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const createStockNap = ({ name }: { name: string }) => async (
  dispatch,
) => {
  dispatch(request());
  return adminService.createStockNap({ name }).then(
    (response) => {
      dispatch(successAndReload());
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const addStockToNap = ({
  NSerie,
  stock,
}: {
  NSerie: string;
  stock: string;
}) => async (dispatch) => {
  dispatch(request());
  return adminService.addStockToNap({ NSerie, stock }).then(
    (response) => {
      dispatch(successAndReload());
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const removeStockFromNap = ({ NSerie }: { NSerie: string }) => async (
  dispatch,
) => {
  dispatch(request());
  return adminService.removeStockFromNap({ NSerie }).then(
    (response) => {
      dispatch(successAndReload());
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};

export const getAllStocksNames = () => async (dispatch) => {
  dispatch(request());
  return adminService.getAllStocksNames().then(
    (response) => {
      dispatch(success());
      dispatch(getAllStocksNamesSuccess(sanitizeResponseArray(response)));
    },
    (error) => {
      dispatch(failure(errorMessage));
    },
  );
};