import React, { useState, Fragment } from 'react';
import 'styles/Home.css';
import 'styles/HomePage.css';
import 'styles/EquiposTabla.css';
import 'styles/UsuariosCard.css';
import 'styles/Helpers.css';
import { formStyles } from 'styles/Styles'
import { Button, Typography, MenuItem, FormControl, InputLabel, Grow, OutlinedInput, InputAdornment, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import PersonPinIcon from '@material-ui/icons/PersonPin';
import AlertDialog from '../AlertDialog'

function Usuarios({ usuarios, handleSignUp, handleDeleteUser }) {
    const [openAlertDialogEliminarUsuario, setOpenAlertDialogEliminarUsuario] = useState(false)
    const [usuarioAEliminar, setUsuarioAEliminar] = useState("")

    const handleEliminarUsuario = (username) => {
        setOpenAlertDialogEliminarUsuario(true)
        setUsuarioAEliminar(username)
    }

    const onPositiveEliminarUsuario = () => {
        setOpenAlertDialogEliminarUsuario(false)
        handleDeleteUser(usuarioAEliminar)
        setUsuarioAEliminar("")
    }

    return (
        <Fragment>
            <AlertDialog open={openAlertDialogEliminarUsuario}
                onClose={() => setOpenAlertDialogEliminarUsuario(false)}
                titulo="Borrar usuario"
                contenido="¿Está seguro que desea borrar el usuario? Esta acción no se puede deshacer."
                onPositiveAction={onPositiveEliminarUsuario} />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' }}>
                <Typography variant='h4' className='texto-title section-title' >Usuarios</Typography>
                <div className='usuarios-card'>
                    <div className='half-container usuarios-list-container scrollable'>
                        {
                            usuarios.map((user, indexUser) => {
                                const { username, role, equipos } = user || {}
                                return (
                                    <div className="usuario-list-item" key={'usuario' + indexUser}>
                                        <Typography variant='body1' className="texto" >{username}</Typography>
                                        {role !== "admin" && <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={() => handleEliminarUsuario(username)} size="small" className="icon-button">
                                            <DeleteIcon style={{ color: "#f44336" }} />
                                        </IconButton>}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="half-container-divider" />
                    <div className='half-container usuarios-signup-container'>
                        <RegisterForm handleSignUp={handleSignUp} />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Usuarios;

function RegisterForm({ handleSignUp }) {
    const classes = formStyles();
    const [username, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [passwordRepeat, setPasswordRepeat] = useState("")
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [isPasswordRepeatVisible, setIsPasswordRepeatVisible] = useState(false)

    const canSignUp = username !== "" && password !== "" && password === passwordRepeat

    const onSignUp = () => {
        handleSignUp({ username, password })
    }

    return (
        <div className='usuarios-signup-form-container'>
            <Grow in={true} timeout={{ enter: 500, exit: 500 }}>
                <Typography variant='h6' className={'form-title'} >Nuevo usuario</Typography>
            </Grow>
            <Grow in={true} timeout={{ enter: 1000, exit: 500 }}>
                <FormControl className={classes.textField} variant="outlined">
                    <InputLabel htmlFor="input-password" classes={{
                        root: classes.input,
                        shrink: "shrink"
                    }}>Usuario</InputLabel>
                    <OutlinedInput
                        id="input-password"
                        required
                        label="Usuario"
                        type="text"
                        value={username}
                        onChange={(event) => setUserName(event.target.value)}
                        endAdornment={
                            <PersonPinIcon className={classes.endAdornmentIcon} />
                        }
                        labelWidth={70}
                    />
                </FormControl>
            </Grow>
            <Grow in={true} unmountOnExit mountOnEnter timeout={{ enter: 1500, exit: 500 }}>
                <FormControl className={classes.textField} variant="outlined">
                    <InputLabel htmlFor="input-password" classes={{
                        root: classes.input,
                        shrink: "shrink"
                    }}>Contraseña</InputLabel>
                    <OutlinedInput
                        id="input-password"
                        required
                        label="Contraseña"
                        type={isPasswordVisible ? "text" : "password"}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="mostrar contraseña"
                                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                >
                                    {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={90}
                    />
                </FormControl>
            </Grow>
            <Grow in={true} unmountOnExit mountOnEnter timeout={{ enter: 1500, exit: 500 }}>
                <FormControl className={classes.textField} variant="outlined">
                    <InputLabel htmlFor="input-password" classes={{
                        root: classes.input,
                        shrink: "shrink"
                    }}>Repetir Contraseña</InputLabel>
                    <OutlinedInput
                        id="input-password"
                        required
                        label="Contraseña"
                        type={isPasswordRepeatVisible ? "text" : "password"}
                        value={passwordRepeat}
                        onChange={(event) => setPasswordRepeat(event.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="mostrar contraseña"
                                    onClick={() => setIsPasswordRepeatVisible(!isPasswordRepeatVisible)}
                                >
                                    {isPasswordRepeatVisible ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={150}
                    />
                </FormControl>
            </Grow>
            <Grow in={true} timeout={{ enter: 2000, exit: 500 }}>
                <Button variant="contained" color="primary" className={classes.loginButton} disabled={!canSignUp} onClick={onSignUp}>
                    <Typography variant='body1' className={'button-text'} >Registrar</Typography>
                </Button>
            </Grow>
        </div>
    )
}
