import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorMessage } from '.';
import { ingredientesService } from '../../services';
import { getStock } from 'redux/slices/hook.slice';
import requestSlice from './request.slice';

type ingredienteType = {
  id?: string;
  ingrediente?: string;
  existencia?: number;
  precio?: number;
  porcentajeMateriaSeca?: any;
  ingreso?: number;
};

const initialState: {
  ingredienteEditing: ingredienteType;
} = {
  ingredienteEditing: {},
};

const stockSlice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    editIngrediente(state, action: PayloadAction<ingredienteType>) {
      state.ingredienteEditing = action.payload;
    },
  },
});

export default stockSlice;

export const editIngrediente = stockSlice.actions.editIngrediente;

const { request, success, failure } = requestSlice.actions;

export const addHistoricChange = ({
  NSerie,
  ingrediente,
  ingreso,
  precio,
  porcentajeMateriaSeca,
}: {
  NSerie: string;
  ingrediente: string;
  ingreso: any;
  precio: any;
  porcentajeMateriaSeca: string;
}) => async (dispatch) => {
  dispatch(request());
  return ingredientesService
    .addHistoricChange({
      stock: NSerie,
      ingrediente,
      ingreso,
      precio,
      porcentajeMateriaSeca,
    })
    .then(
      (response) => {
        dispatch(success());
        dispatch(getStock({ stock: NSerie }));
      },
      (error) => {
        dispatch(failure(errorMessage));
      },
    );
};

export const addTareaUpdateIngredienteNap = ({
  NSerie,
  ingredienteToSave,
}: {
  NSerie: string;
  ingredienteToSave: any;
}) => async (dispatch) => {
  dispatch(request());
  const { id, ingrediente } = ingredienteToSave || {};
  return ingredientesService
    .addTareaUpdateIngredienteNap({ NSerie, id, ingrediente })
    .then(
      (response) => {
        dispatch(success());
        dispatch(addHistoricChange({ NSerie, ...ingredienteToSave }));
      },
      (error) => {
        dispatch(failure(errorMessage));
      },
    );
};
