import React, { useContext, useState } from 'react';
import moment from 'moment';
import 'styles/Home.css';
import {
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import LoaderComponent from '../components/LoaderComponent';
import { useDispatch, useSelector } from 'react-redux';
import { formStyles } from 'styles/Styles';
import { MedyconContext } from '../helpers';
import { AlertMessage } from '../components/AlertMessage';
import { tryLogin } from 'redux/slices/login.slice';

function LoginForm() {
  const classes = formStyles();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const onUsernameChange = (e) => setUsername(e.target.value);
  const onPasswordChange = (e) => setPassword(e.target.value);
  const onTogglePasswordShow = () => setIsPasswordVisible(!isPasswordVisible);

  const dispatch = useDispatch();
  const { message, isLoading } = useSelector((state) => state.login);
  const canLogin = username !== '' && password !== '';

  const onLogin = async (event) => {
    event.preventDefault();
    dispatch(
      tryLogin({
        username: username.trim(),
        password,
      }),
    );
  };

  return (
    <form
      className="login-form-container"
      autoComplete="on"
      onSubmit={(event) => onLogin(event)}
    >
      {message !== '' && <AlertMessage message={message} severity={'error'} />}
      <Typography variant="h4" className={'form-title'}>
        Iniciar sesión
      </Typography>
      <TextField
        label="Usuario"
        type="text"
        value={username}
        onChange={onUsernameChange}
        required
        classes={{ root: classes.textField }}
        variant="outlined"
        InputProps={{
          endAdornment: <PersonPinIcon className={classes.endAdornmentIcon} />,
        }}
        InputLabelProps={{
          classes: {
            root: classes.input,
            shrink: 'shrink',
          },
        }}
      />
      <TextField
        label="Contraseña"
        type={isPasswordVisible ? 'text' : 'password'}
        value={password}
        onChange={onPasswordChange}
        required
        classes={{ root: classes.textField }}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="mostrar contraseña"
                onClick={onTogglePasswordShow}
              >
                {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: {
            root: classes.input,
            shrink: 'shrink',
          },
        }}
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        className={classes.loginButton}
        disabled={!canLogin}
      >
        <Typography variant="body1" className={'button-text'}>
          Iniciar sesión
        </Typography>
      </Button>
      {isLoading && <LoaderComponent />}
    </form>
  );
}

function Login() {
  const { isHook } = useContext(MedyconContext);

  const randomBackground = (moment().day() % 2) + 1;

  return (
    <div className="home-container">
      <div className={'home-header-left-container'}>
        <div className="home-background">
          {isHook ? (
            <img
              src={require('../assets/backgrounds/hook-login-background-' +
                randomBackground +
                '.jpg')}
              className="img"
              alt="hook-background"
            />
          ) : (
            <img
              src={require('../assets/backgrounds/medycon-login-background-' +
                randomBackground +
                '.jpg')}
              className="img"
              alt="medycon-background"
            ></img>
          )}
          <a
            href="http://www.freepik.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              position: 'absolute',
              bottom: '5px',
              left: '5px',
              color: '#fff',
              width: '100%',
              zIndex: 10000,
            }}
          >
            Designed by Freepik
          </a>
        </div>
        <div className={'home-header-title'}>
          <div className={'home-header-title-img-container'}>
            {isHook ? (
              <img
                src={require('../assets/medycon/favicon-hook.png')}
                className="home-header-title-img"
                alt="hook-logo"
              ></img>
            ) : (
              <img
                src={require('../assets/backgrounds/LOGO MEDYCON- BLANCO SOMBRA.png')}
                className="home-header-title-img"
                alt="medycon-logo"
              ></img>
            )}
          </div>
        </div>
      </div>
      <div className={'home-header-right-container'}>
        <LoginForm />
      </div>
    </div>
  );
}

export default Login;
