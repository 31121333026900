import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormContainer, FormSelect, FormTextfield } from 'components/reusable';
import { onFormChange } from 'redux/slices/recetas.slice';

const RecetaForm = ({ receta, editingEnabled }) =>
  editingEnabled ? <RecetaFormEditable /> : <RecetaFormBase receta={receta} />;
export default RecetaForm;

function RecetaFormEditable() {
  const { recetaEditing } = useSelector((state) => state.recetas);
  return <RecetaFormBase receta={recetaEditing} editingEnabled />;
}

function RecetaFormBase({ receta, editingEnabled }) {
  const { equipoSelected } = useSelector((state) => state.hook);
  const { recetas = [] } = equipoSelected || {};
  const dispatch = useDispatch();
  const { id = '', nombre = '', tiempoMezcla = 0, tipoUnidad = 1 } =
    receta || {};

  const onNombreChange = (event) => {
    dispatch(onFormChange({ ...receta, nombre: event.target.value }));
  };
  const onIDChange = useCallback(
    (event) => {
      dispatch(onFormChange({ ...receta, id: event.target.value }));
    },
    [dispatch, receta],
  );
  const onTiempoMezclaChange = (event) => {
    dispatch(onFormChange({ ...receta, tiempoMezcla: event.target.value }));
  };
  const onTipoUnidadChange = (event) => {
    dispatch(onFormChange({ ...receta, tipoUnidad: event.target.value }));
  };

  const IDSRecetas = useMemo(
    () => recetas.map((recetaElement) => recetaElement.id),
    [recetas],
  );
  const SELECTIDS = useMemo(
    () =>
      [...Array(99).keys()]
        .map((i) => i + 1)
        .map((i) => ({
          name: String(i),
          value: String(i),
          disabled: IDSRecetas.includes(String(i)),
        })),
    [IDSRecetas],
  );
  const isNew = useMemo(() => IDSRecetas.includes(id), [IDSRecetas, id]);

  return (
    <FormContainer>
      <FormTextfield
        texfieldProps={{
          id: 'receta-form-nombre',
          label: 'Nombre',
          value: nombre,
          onChange: onNombreChange,
          disabled: !editingEnabled,
        }}
      />
      <FormSelect
        title="ID"
        selectOptions={SELECTIDS}
        selectProps={{
          value: id,
          onChange: onIDChange,
          disabled: !editingEnabled || isNew,
        }}
      />
      <FormTextfield
        texfieldProps={{
          id: 'receta-form-tiempo-mezcla',
          label: 'Tiempo de Mezcla',
          value: tiempoMezcla,
          type: 'number',
          disabled: !editingEnabled,
          InputProps: {
            inputProps: {
              max: 99,
              min: 0,
            },
          },
          onChange: onTiempoMezclaChange,
        }}
      />
      <FormSelect
        title="Tipo Unidad"
        selectOptions={[
          { name: 'Cabezas', value: 1 },
          { name: 'Kg', value: 0 },
        ]}
        selectProps={{
          value: tipoUnidad,
          onChange: onTipoUnidadChange,
          disabled: !editingEnabled,
        }}
      />
    </FormContainer>
  );
}
